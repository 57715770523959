/*Sliders*/

.petmark-slick-slider.grid-column-slider {
    .slick-slide {
        &.slick-active {
            div+div {
                .single-slide {
                    &:after {
                        height: 0;
                    }
                }
            }
        }
        &.slick-current {
            .single-slide {
                &:before {
                    width: 0;
                    opacity: 0;
                }
            }
        }
        .single-slide {
            padding-right: 10px;
            padding-left: 10px;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
            &:before {
                content: "";
                width: 1px;
                position: absolute;
                background: $border-color;
                left: 0;
                top: 20px;
                bottom: 5px;
            }
            &:after {
                content: "";
                height: 1px;
                position: absolute;
                background: $border-color;
                left: 10px;
                right: 10px;
                bottom: 0px;
            }
        }
    }
}

.petmark-slick-slider {
    .pm-product {
        padding: 0;
    }
}

/* Petmark Products Slider*/

.one-column-slider.two-row {
    .slick-active>div {
        @extend %border-bottom;
    }
    .slick-slide>div {
        padding: 13px 0;
        @include large-desktops {
            padding: 11.5px 0 24.5px;
        }
    }
    .product-type-list {
        @include mobile {
            display: flex;
        }
        .image {
            @include mobile {
                width: 225px;
            }
            @include desktops {
                width: 194.5px;
            }
            @include large-desktops {
                width: 225px;
            }
            img {
                width: 100%;
            }
        }
        .content {
            overflow: unset;
            padding-top: 20px;
            @include mx-mobile-xs {
                padding-left: 20px;
            }
            .btn-block {
                margin-top: 20px;
                position: relative;
                transform: translateY(0);
                height: auto;
            }
            &:before {
                z-index: -1 !important;
            }
        }
    }
}

.one-column-slider.three-row {
    .slick-active>div {
        @extend %border-bottom;
    }
    .slick-slide>div {
        padding: 13px 0;
        @include large-desktops {
            padding: 11.5px 0 24.5px;
        }
    }
    .product-type-list {
        @include mobile {
            display: flex;
        }
        .image {
            @include mobile {
                width: 225px;
            }
            @include desktops {
                width: 194.5px;
            }
            @include large-desktops {
                width: 40%;
                padding: 0 10px;
            }
            img {
                width: 100%;
            }
        }
        .content {
            overflow: unset;
            padding-top: 20px;
            @include mx-mobile-xs {
                padding-left: 20px;
            } // .btn-block{
            //     margin-top: 20px;
            //     position: relative;
            //     transform: translateY(0);
            //     height: auto;
            // }
            &:before {
                z-index: -1 !important;
            }
        }
    }
}

.normal-slider {
    .slick-slide {
        padding: 11.5px;
        @extend %border-left;
    }
    .product-type-list {
        @include mobile {
            display: flex;
        }
        .image {
            @include mobile {
                width: 225px;
            }
            @include desktops {
                width: 145px;
            }
            @include large-desktops {
                width: 145px;
            }
            img {
                width: 100%;
            }
        }
        .content {
            overflow: unset;
            padding-top: 20px;
            @include mx-mobile-xs {
                padding-left: 20px;
            }
            .btn-block {
                margin-top: 20px;
                position: relative;
                transform: translateY(0);
                height: auto;
            }
            &:before {
                z-index: -1 !important;
            }
        }
    }
    &.grid-border-none {
        .slick-slide {
            &:before {
                opacity: 0;
            }
        }
    }
}

.normal-two-column-slider {
    .product-type-list {
        @include mobile {
            display: flex;
        }
        .image {
            @include mobile {
                width: 50%;
            }
            @include tablet {
                width: 225px;
            }
            img {
                width: 100%;
            }
        }
        .content {
            overflow: unset;
            padding-bottom: 25px;
            @include mx-mobile-xs {
                padding-left: 20px;
            }
            @include mobile {
                padding-top: 25px;
                width: 50%;
                padding-right: 15px;
            }
            @include tablet {
                padding-right: 25px;
            }
            @include desktops {
                width: 50%;
            }
            .btn-block {
                text-align: left;
                margin-top: 20px;
                position: relative;
                transform: translateY(0);
                height: auto;
                a {
                    margin-right: auto;
                }
            }
            &:before {
                z-index: -1 !important;
            }
        }
    }
}

.petmark-slick-slider {
    button.slick-arrow {
        position: absolute;
        top: -38px;
        font-size: 0;
        right: 5px;
        height: 30px;
        line-height: 2px;
        width: 30px;
        &:before {
            position: absolute;
            content: '\f3cf';
            font-family: 'Ionicons';
            font-size: 18px;
            color: #696969;
            width: 100%;
            left: 0;
            height: 100%;
            display: flex;
            align-content: center;
            justify-content: center;
        }
        &.slick-next {
            right: -15px;
            &::before {
                content: "\f3d1";
            }
        }
        &:hover {
            &:before {
                color: $primary-color;
            }
        }
    }
    &.arrow-type-two {
        button.slick-arrow {
            top: 50%;
            transform: translateY(-50%);
            padding: 10px;
            z-index: 99;
            margin-right: -20px;
            right: 100%;
            @include desktops {
                margin-right: 0;
            }
            &:before {
                height: 100%;
                top: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.slick-next {
                right: 10px;
                @include desktops {
                    margin-right: -30px;
                    margin-right: -42px;
                }
            }
        }
    }
    &--wrapper-2 {
        button.slick-arrow {
            position: absolute;
            top: -61px;
        }
    }
}

.brand-slider {
    padding: 10px 0px;
    @include tablet {
        padding: 10px 30px;
    }
    @include desktops {
        padding: 20px 50px;
    }
    .brand-image {
        img {
            margin: 0 auto;
        }
    }
    button.slick-arrow {
        position: absolute;
        top: 0;
        font-size: 0;
        left: 0;
        height: 100%;
        width: 14px;
        background: #fff;
        z-index: 2;
        @include tablet {
            width: 35px;
        }
        &::before {
            font-size: 23px;
            @include mobile-lg {
                font-size: 28px;
            }
        }
        &.slick-next {
            left: auto;
            right: 1px;
            margin-right: 0px;
        }
    }
}

.slider-side-block {
    .single-slide-block {
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.slider-main-block {
    .single-main-block {
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.home-slider {
    ul.slick-dots {
        font-size: 0;
        display: flex;
        position: absolute;
        bottom: 0;
        margin-bottom: 20px;
        left: 0;
        margin-left: 60px;
        button {
            height: 8px;
            width: 8px;
            background: #ffff;
            border-radius: 500px;
            margin-right: 10px;
            /* left: 0; */
            transition: .4s;
        }
        li.slick-active,
        li:hover {
            button {
                width: 28px;
                background: $primary-color;
            }
        }
    }
    &.dot-position-1 {
        ul.slick-dots {
            transform: translate(-50%);
            left: 50%;
            margin-left: 0;
        }
    }
}

.sidebar-one-column.petmark-slick-slider {
    .pm-product.product-type-list {
        flex-wrap: wrap;
        .content {
            @include tablet {
                width: 60%;
            }
            @include desktops {
                width: 100%;
                margin-left: 20px;
                padding-top: 5px;
            }
            @include large-desktops {
                width: 60%;
                margin-left: 0px;
                padding-top: 20px;
            }
        }
    }
}