/* Category Section */

.category-block-single {
    text-align: center;
    position: relative;
    padding-right: 2px;
    padding-left: 2px;
    &:before {
        content: "";
        width: 0%;
        position: absolute;
        background: $border-color;
        left: 0;
        @include desktops {
            top: 10px;
            width: 1px;
            bottom: 0;
        }
    }
    .icon {
        overflow: hidden;
        display: block;
        width: 100%;
        img {
            transform: scale(1);
            transition: .4s;
        }
    }
    h3 {
        color: #1a1f2b;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        a {
            transition: .4s;
        }
        &:hover {
            a {
                color: $primary-color;
            }
        }
    }
    &:hover {
        .icon {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.category-block {
    border: 1px solid $border-color;
    [class*="col-"]:nth-child(1)>.category-block-single,
    [class*="col-"]:nth-child(2)>.category-block-single,
    [class*="col-"]:nth-child(3)>.category-block-single {
        &::before {
            @include mx-tablet {
                width: 100%;
                height: 1px;
                bottom: 0
            }
        }
    }
    [class*="col-"]:nth-child(1)>.category-block-single {
        &::before {
            @include desktops {
                width: 0;
            }
        }
    }
    .category-block-single {
        margin-top: 30px;
        margin-bottom: 30px;
        @include mx-tablet {
            padding-bottom: 40px;
            margin-bottom: 0;
        }
    }
}