/* Product Section */

.pm-product {
    padding: 20px 0;
    .image {
        position: relative;
        margin-bottom: 10px;
        a {
            display: block;
            img {
                display: block;
                width: 100%;
            }
        }
    }
    .content {
        position: relative;
        overflow: hidden;
        h3 {
            color: #1a1f2b;
            font-size: 15px;
            font-weight: normal;
            line-height: 1.35;
            margin-bottom: 8px;
            a {
                transition: .4s;
                &:hover {
                    color: $primary-color;
                }
            }
        }
        .price {
            font-weight: 500;
            .old {
                text-decoration: line-through;
                color: #999;
                margin-right: 5px;
            }
        }
        .btn-block {
            margin-top: 12px;
            display: block;
            width: 100%;
            position: absolute;
            left: 0; // background: #fff;
            height: 100%;
            display: flex;
            align-items: center;
            bottom: 0;
            transform: translateY(110%);
            transition: .4s;
            z-index: 5;
            text-align: center;
            justify-content: center;
        }
        &:before {
            position: absolute;
            content: "";
            background: #fff;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
    .hover-conents {
        position: absolute;
        right: 15px;
        top: 0%;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
        .product-btns {
            a {
                font-size: 24px;
                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
    &:hover {
        .content {
            .btn-block {
                transform: translateY(0);
            }
            &:before {
                z-index: 1;
            }
        }
        .hover-conents {
            opacity: 1;
            top: 5px;
            visibility: visible;
        }
    }
    .onsale-badge {
        background: #56a700;
        color: #ffffff;
        display: block;
        font-size: 11px;
        font-weight: normal;
        height: 20px;
        line-height: 20px;
        min-height: 0;
        min-width: 0;
        padding: 0 10px;
        pointer-events: none;
        position: absolute;
        right: auto;
        text-align: center;
        left: 0px;
        top: 0px;
        z-index: 3;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    &.product-type-list {
        padding: 0 !important;
        @include mobile {
            display: flex;
        }
        .onsale-badge {
            left: 15px;
            top: 15px;
        }
        .image {
            padding-right: 15px;
            padding-bottom: 15px;
            padding-left: 15px;
            padding-top: 15px;
            @include mobile {
                width: 50%;
                padding-bottom: 0px;
            }
            @include tablet {
                width: 260px;
            }
            img {
                width: 100%;
            }
        }
        .content {
            overflow: unset;
            padding-bottom: 25px;
            @include mx-mobile-xs {
                padding-left: 20px;
            }
            @include mobile {
                padding-top: 25px;
                width: 50%;
                padding-right: 15px;
            }
            @include tablet {
                padding-right: 20px;
            }
            @include desktops {
                width: 50%;
            }
            .btn-block {
                text-align: left;
                margin-top: 20px;
                position: relative;
                transform: translateY(0);
                height: auto;
                a {
                    margin-right: auto;
                }
            }
            &:before {
                z-index: -1 !important;
            }
        }
    }
    &.sidebar-product {
        .image {
            width: 81px;
            margin-right: 5px!important;
            padding-right: 5px!important;
            padding-bottom: 15px;
            padding-left: 5px!important;
            padding-top: 5px!important;
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
        .content {
            padding-right: 0;
            padding-top: 10px;
            padding-bottom: 0;
            h3 {
                color: $black-color-2;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.3;
                margin: 0;
                text-transform: none;
            }
            .rating-widget .single-rating {
                font-size: 10px;
            }
        }
    }
}

/* Product Count Down*/

.product-countdown {
    display: flex;
    .single-countdown {
        margin-right: 10px;
        text-align: center;
        .single-countdown__time {
            display: flex;
            height: 45px;
            width: 45px;
            background: #f0f0f0;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
        }
        .single-countdown__text {
            text-transform: uppercase;
            font-size: 10px;
        }
    }
}

/*Promo Products*/

.promo-image {
    margin-bottom: 30px;
    @include mx-tablet {
        margin-bottom: 17px;
    }
    img {
        width: 100%;
    }
}

.overflow-image {
    overflow: hidden;
    display: block;
    img {
        transform: scale(1);
        transition: .4s;
    }
    &:hover {
        img {
            transform: scale(1.1)
        }
    }
}