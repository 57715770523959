/*
====> Reset Styles
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import "helpers/media-query";
body {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    color: #555555;
    font-size: 14px;
    line-height: 1.65;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

a,
span {
    display: inline-block;
}

a {
    transition: 0.4s;
    color: inherit;
    &:focus {
        outline: none !important;
    }
}

// a:link {
//   color: $primary-color;
// }
a:hover {
    text-decoration: none;
}

a:visited,
a:active {
    outline: none;
    border: none;
    text-decoration: none;
}

ul {
    list-style: none;
    margin: 0;
}

img {
    max-width: 100%;
}

p {
    // margin: 15px 0;
    line-height: 20px;
}

.btn:focus,
.btn:active {
    box-shadow: none;
    border-color: transparent;
}

button {
    cursor: pointer;
    background: transparent;
    border: none;
    &:focus {
        outline: none;
    }
}

.icon {
    font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

.card {
    border: none;
    border-radius: 0;
}

/* Slick Slider Resets */

.slick-slide {
    &:focus {
        outline: none;
        a {
            &:focus {
                outline: none;
            }
        }
    }
}

// input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
// input[type="number"] {
//   -moz-appearance: textfield;
// }
// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }
.container {
    @include large-desktops {
        max-width: 1200px;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.site-wrapper {
    overflow: hidden;
}