/* Breadcrumb Section */

.breadcrumb-wrapper {
    background: #e9ecef;
    .breadcrumb {
        background: transparent;
        padding: 15px 0;
        margin-bottom: 0;
    }
}

.breadcrumb-item {
    color: $black-color-2;
    &.active,
    &:hover a {
        color: $primary-color;
    }
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 15px;
    &::before {
        padding-right: 15px;
        content: $breadcrumb-divider;
        color: $primary-color;
    }
}