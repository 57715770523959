/* Contact Page */

#googleMap {
    height: 550px;
    width: 100%;
}

.ct-single-side {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-right: 40px;
    padding-left: 40px;
    @include desktops {
        padding-right: 60px;
        padding-left: 60px;
    }
    @include large-desktops {
        padding-right: 100px;
        padding-left: 100px;
    }
}

.ct-description {
    color: #666;
}

.ct-article {
    padding-bottom: 30px;
    p {
        margin-bottom: 10px;
    }
}

.contact-list {
    max-width: 380px;
    li {
        border-top: 1px solid #e4e4e4;
        list-style: none;
        padding: 10px 0;
        i {
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 40px;
        }
    }
}

.working-hour {
    h3 {
        font-weight: 500;
        margin-bottom: 5px;
        color: $black-color-2;
        font-size: 24px;
    }
}

/* Contact page - 2*/

.ct-feature-section {
    padding: 40px 0 30px;
    @include mobile {
        padding: 60px 0 50px;
    }
    @include desktops {
        padding: 90px 0 80px;
    }
}

.ct-section-title {
    margin-bottom: 35px;
    h2 {
        font-size: 23px;
        color: $black-color-2;
        @include mobile-lg {
            font-size: 28px;
        }
        @include tablet {
            font-size: 30px;
        }
    }
    &-2 {
        h2 {
            font-weight: 900;
            text-transform: uppercase;
            color: $black-color-2;
            font-size: 23px;
            @include mobile-lg {
                font-size: 28px;
            }
            @include tablet {
                font-size: 30px;
            }
        }
        p {
            font-size: 14px;
        }
    }
}

.ct-feature {
    text-align: center;
    .icon {
        border: 0;
        border-radius: 100%;
        border: 3px solid $black-color-2;
        color: #fff;
        height: 70px;
        width: 70px;
        margin: 0 auto 20px;
        text-align: center;
        transition: color .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $black-color-2;
        @include tablet {
            height: 95px;
            width: 95px;
            margin: 0 auto 35px;
        }
        i {
            font-size: 34.4px;
        }
        &:hover {
            background: $primary-color;
            color: $white-color;
            border-color: $primary-color;
        }
    }
    h3 {
        color: $black-color-2;
        line-height: 1.35;
        margin-bottom: 5px;
    }
    p {
        line-height: 1.65;
    }
}

.contact-page-section.section-padding {
    section-padding: 80px 0;
}