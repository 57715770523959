/* Cart Page */

.cart_area {
    .page-section-title {
        margin-bottom: 40px;
        h1 {
            font-size: 22px;
            @include mobile {
                font-size: 28px;
            }
            @include desktops {
                font-size: 34px;
            }
        }
    }
}

/*-- Cart Table --*/

.cart-table {
    color: #d4d4d4;
    & .table {
        // border-bottom: 8px solid #14191e;
        margin: 0; // Responsive
        border: 1px solid rgba(0, 0, 0, .1);
        @media #{$large-mobile} {
            border-top: 8px solid #14191e;
        } // Head
        & thead {
            background-color: transparent; // Responsive
            @media #{$large-mobile} {
                display: none;
            }
            & tr {
                border-bottom: 1px solid #ececec;
                & th {
                    text-align: center;
                    border: none;
                    text-transform: uppercase;
                    font-weight: 500;
                    color: $black-color-2;
                    border-right: 1px solid #ececec;
                    padding: 20px 10px;
                    font-family: Rubik, Arial, Helvetica, sans-serif;
                    font-size: 15px;
                }
            }
        } // Body
        & tbody {
            color: $black-color-2;
            font-weight: 500;
            & tr {
                transition: .4s;
                &:not(:last-child) {
                    &:hover {
                        background: #f6f6f6;
                    }
                }
                & td {
                    text-align: center;
                    border: none;
                    padding: 20px 10px;
                    vertical-align: middle;
                    border-top: 1px solid #ececec; // Responsive
                    border-right: 1px solid #ececec;
                    @media #{$large-mobile} {
                        display: block;
                        width: 100%;
                        max-width: none;
                        padding: 15px;
                        text-align: left;
                    }
                    .coupon-block {
                        display: flex;
                        border: 1px solid #ececec;
                        margin: 0 0 20px;
                        padding: 25px !important;
                        flex-wrap: wrap;
                        .coupon-text {
                            flex: 100%;
                            @include mobile-lg {
                                flex: 50%;
                                text-align: left;
                            }
                           
                            input {
                                border: 1px solid #ececec;
                                height: 50px;
                                line-height: 48px;
                                padding: 6px 6px 5px;
                                background: #f4f4f4;
                                font-weight: 500;
                                width: 100%;
                                @include mobile-lg {
                                    width: auto;
                                }
                            }
                            input:focus {
                                outline: none;
                            }
                        }
                        .coupon-btn {
                            flex: 100%;
                            margin-top: 10px;
                            @include mobile-lg {
                                flex: 50%;
                                margin-top: 0px;
                                text-align: right;
                            }
                            
                        }
                        label {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.cart-table {
    & th.pro-thumbnail,
    td.pro-thumbnail {
        max-width: 160px;
        min-width: 32px;
        width: 160px; // Responsive
        @media #{$large-mobile} {
            & a {
                width: 160px;
            }
        }
    }
    & th.pro-title,
    td.pro-title {
        min-width: 200px;
    }
    & td.pro-thumbnail {
        & a {
            display: block; // width: 120px;
            // height:  105px;
            & img {
                width: 70px;
                background-color: #f6f7f8;
            }
        }
    }
    & td.pro-title {
        & a {
            font-size: 16px; // font-weight: 500;
            color: $black-color-2;
            &:hover {
                color: $primary-color;
            }
        }
    }
    & td.pro-price {
        & span {
            display: block;
            font-size: 15px; // font-weight: 600;
            // color: $body-color;
        }
    }
    & td.pro-quantity {
        & .pro-qty {
            width: 50px;
            display: block;
            margin: auto;
            & input {
                padding-right: 0;
                font-size: 15px;
                border-radius: 0;
                background: transparent;
                border: 1px solid #ececec;
                color: $black-color-2;
            } // Responsive
            @media #{$large-mobile} {
                margin: 0;
            }
        }
    }
    & td.pro-subtotal {
        & span {
            display: block;
            font-size: 15px; // font-weight: 600;
            // color: $body-color;
        }
    }
    & td.pro-addtocart {
        & button {
            width: 140px;
            border-radius: 50px;
            height: 36px;
            border: 1px solid $primary-color;
            line-height: 24px;
            padding: 5px 20px;
            font-weight: 700;
            text-transform: capitalize;
            color: #fff;
            background-color: $primary-color;
        }
    }
    & td.pro-remove {
        & a {
            display: block;
            font-weight: 600; // color: $body-color;
            & i {
                font-size: 15px;
            }
            &:hover {
                color: $primary-color;
            } // Responsive
            @media #{$large-mobile} {
                width: 60px;
                text-align: center;
            }
        }
    }
}

/*-- Calculate Shipping --*/

.calculate-shipping {
    margin-bottom: 23px;
    & h4 {
        font-size: 20px;
        line-height: 23px; // text-decoration: underline;
        text-transform: capitalize;
        font-weight: 700;
        margin-bottom: 30px;
        color: #d4d4d4;
        @extend .title-font;
    }
    & form {
        & .nice-select {
            width: 100%;
            border-radius: 50px;
            height: 36px;
            border: 1px solid #565f69;
            line-height: 24px;
            padding: 5px 20px;
            background-color: transparent;
            &::after {
                border-color: #565f69;
            }
            & .current {
                display: block;
                line-height: 24px;
                font-size: 14px; // color: $body-color;
                color: #d4d4d4;
            }
        }
        & input {
            width: 100%;
            border-radius: 50px;
            height: 36px;
            border: 1px solid #565f69;
            line-height: 24px;
            padding: 5px 20px;
            color: #d4d4d4; // color: $body-color;
            background-color: transparent;
            @include placeholder {
                color: #d4d4d4;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &[type="submit"] {
                font-weight: 700;
                text-transform: uppercase;
                color: #fff;
                background-color: $primary-color;
                border-color: $primary-color;
                width: 140px;
                cursor: pointer;
            }
        }
    }
}

/*-- Discount Coupon --*/

.discount-coupon {
    & h4 {
        font-size: 20px;
        line-height: 23px; // text-decoration: underline;
        text-transform: capitalize;
        font-weight: 700;
        margin-bottom: 30px;
        @extend .title-font;
        color: #d4d4d4;
    }
    & form {
        & input {
            width: 100%;
            border-radius: 50px;
            height: 36px;
            border: 1px solid #565f69;
            line-height: 24px;
            padding: 5px 20px; // color: $body-color;
            color: #d4d4d4;
            background-color: transparent;
            @include placeholder {
                color: #d4d4d4;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &[type="submit"] {
                font-weight: 700;
                text-transform: uppercase;
                color: #fff;
                background-color: $primary-color;
                border-color: $primary-color;
                width: 140px;
                line-height: 27px;
                cursor: pointer;
            }
        }
    }
}

/*-- Cart Summary --*/

.cart-summary {
    float: right;
    @include desktops {
        // max-width: 410px;
        padding-left: 30px;
    }
    width: 100%;
    margin-left: auto; // Responsive
    @media #{$sm-layout} {
        margin-left: 0;
    }
    @media #{$large-mobile} {
        margin-left: 0;
    }
    & .cart-summary-wrap {
        background-color: $white-color;
        ;
        padding: 30px 30px;
        margin-bottom: 20px; // Responsive
        border: 1px solid #ececec;
        color: $black-color-2;
        @media #{$small-mobile} {
            padding: 25px 30px;
        }
        & h4 {
            font-size: 20px;
            line-height: 23px; // text-decoration: underline;
            text-transform: capitalize;
            font-weight: 700;
            margin-bottom: 30px; // color: #d4d4d4;
            display: block;
            font-size: 20px;
            font-weight: 500;
            margin: 0 0 20px;
            text-align: right;
        }
        & p {
            font-size: 14px;
            font-weight: 500;
            line-height: 23px; // color: #b2b2b2;
            & span {
                float: right;
            }
        }
        & h2 {
            border-top: 1px solid #ececec;
            padding-top: 9px;
            font-size: 18px;
            line-height: 23px;
            font-weight: 500; // color: #d4d4d4;
            margin: 0;
            & span {
                float: right;
            }
        }
    }
    & .cart-summary-button {
        overflow: hidden;
        width: 100%; // Responsive
        @media #{$sm-layout} {
            display: flex;
            justify-content: flex-start;
        }
        @media #{$large-mobile} {
            display: flex;
            justify-content: flex-start;
        }
        & .c-btn {
            margin-top: 10px;
            width: 140px;
            border-radius: 50px;
            height: 36px;
            border: 1px solid $black-color-2;
            line-height: initial;
            padding: 5px 20px; // color: $body-color;
            background-color: transparent;
            margin-left: 20px;
            float: right;
            color: $black-color-2;
            display: flex;
            align-items: center;
            justify-content: center;
            &:last-child {
                margin-left: 0;
            }
            &.checkout-btn {
                font-weight: 500;
                text-transform: uppercase;
                color: #fff;
                background-color: $primary-color;
                border-color: $primary-color;
            } // Responsive
            @media #{$sm-layout} {
                margin-left: 0;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
            }
            @media #{$large-mobile} {
                margin-left: 0;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
            @media #{$small-mobile} {
                width: 130px;
            }
        }
    }
}

.cart-block-title {
    h2 {
        color: #1a1f2b;
        font-size: 24px;
        font-weight: 500;
        margin: 0 0 35px 0;
        text-align: center;
        text-transform: uppercase;
    }
}