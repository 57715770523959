/* Colors */

.text-primary {
    color: $primary-color !important;
}

.text-white {
    color: #fff;
}

.text-black {
    color: #1a1f2b;
}

.text-red {
    color: $red-color;
}

.text-orange {
    color: $orange-color !important;
}

.bg-red {
    background-color: $red-color;
}

.bg-ash {
    background-color: $ash-color;
}

.bg-gray {
    background-color: $gray-color;
}

.bg-blue {
    background-color: $blue-color;
}

.bg-blue-2 {
    background-color: $blue-color-2;
}

.bg-orange {
    background-color: $orange-color;
}

.border-bottom {
    border-bottom: 1px solid #cecece;
}

.text-gray {
    color: #999;
}

.bg-gray {
    background: #f2f2f2;
}