/* Product Details Page */

.product-details-section {
    padding-top: 60px;
    padding-bottom: 60px;
}

.pm-product-details {
    overflow: hidden;
    .image-block {
        &.left-thumbnail {
            display: flex;
            .main-image {
                width: 70%;
                order: 2;
                >div {
                    position: relative;
                }
                @include desktops {
                    width: 80%;
                }
            }
            .elevate-gallery {
                flex-direction: column;
                margin-top: 0 !important;
                position: relative;
                padding-top: 0;
                z-index: 250;
                width: 20%;
                order: 1;
                @include tablet {
                    width: 30%;
                }
                @include desktops {
                    width: 20%;
                }
                a {
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.right-thumbnail {
            display: flex;
            .main-image {
                >div {
                    position: relative;
                }
                width: 70%;
                @include desktops {
                    width: 80%;
                }
            }
            .elevate-gallery {
                flex-direction: column;
                margin-top: 0 !important;
                position: relative;
                padding-top: 0;
                z-index: 250;
                width: 20%;
                @include tablet {
                    width: 30%;
                }
                @include desktops {
                    width: 20%;
                }
                a {
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.gallery-thumb {
            .single-thumb-image {
                border: 1px solid #ececec;
                margin-bottom: 30px;
            }
        }
        &.image-list-block {
            .single-thumb-image {
                border: 1px solid #ececec;
                margin-bottom: 30px;
                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .description-block {
        .header-block {
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            h3 {
                color: #1a1f2b;
                font-size: 18px;
            }
            .navigation {
                margin-left: auto;
                a {
                    border: 1px solid #d1d1d1;
                    border-radius: 3px;
                    color: #d1d1d1;
                    font-size: 15px;
                    height: 30px;
                    line-height: 28px;
                    text-align: center;
                    width: 30px;
                    &:hover {
                        background: $primary-color;
                        color: #fff;
                        border-color: $primary-color;
                    }
                }
            }
        }
        .rating-block {
            .rating-text {
                margin-left: 5px;
                margin-bottom: 0;
                color: #999;
                &:hover {
                    a {
                        color: $primary-color;
                    }
                }
            }
        }
        .price {
            color: #c61132;
            font-family: Rubik, Arial, Helvetica, sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: normal;
            margin: 0 0 30px;
            span {
                margin-right: 7px;
            }
        }
        .status {
            color: #1a1f2b;
            font-size: .857em;
            font-weight: 500;
            margin: 0 0 2s 5px 0;
            text-transform: uppercase;
            width: 100%;
            i {
                color: $primary-color;
                margin-right: 5px;
            }
        }
        .add-to-cart {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-top: 1px solid #ececec;
            margin: 25px 0;
            padding-top: 35px;
            width: 100%;
            >div {
                margin-right: 15px;
            }
            .count-input-block {
                width: 70px;
                input {
                    background-attachment: #fff;
                    background: #fff;
                    border: 1px solid #ececec;
                    color: rgba(0, 0, 0, 0.45098039215686275);
                    &:focus {
                        border-color: #ececec;
                    }
                }
            }
            .btn-block {
                width: auto;
                .btn {
                    color: $primary-color;
                    height: 40px;
                    @include mobile {
                        width: 210px;
                    }
                    &:hover {
                        color: $white-color;
                    }
                }
            }
        }
        .count-input-block {
            width: 70px;
            input {
                background-attachment: #fff;
                background: #fff;
                border: 1px solid #ececec;
                color: rgba(0, 0, 0, 0.45098039215686275);
                border-radius: 0;
                &:focus {
                    border-color: #ececec;
                }
            }
        }
        .product-meta {
            font-size: 1em;
            font-weight: 500;
            margin-bottom: 5px;
            text-transform: uppercase;
            color: #1a1f2b;
            font-size: 14px;
            .single-meta {
                color: #a43d21;
                font-weight: 400;
                text-transform: none;
                &:hover {
                    color: $primary-color;
                }
            }
        }
        .share-block-2 {
            display: flex;
            align-items: center;
            padding: 15px 0;
            border-top: 1px solid #ececec;
            border-bottom: 1px solid #ececec;
            h4 {
                color: #1a1f2b;
                display: inline-block;
                font-size: 14px;
                font-weight: bold;
                margin-right: 15px;
                text-transform: uppercase;
            }
            .social-btns {
                li {
                    margin-right: 15px;
                }
                a {
                    font-size: 14px;
                }
            }
        }
        .grouped-product-block {
            border-top: 1px solid #ececec;
        }
        .grouped-product {
            display: flex;
            padding-top: 25px;
            .count-input-block {
                margin-right: 10px;
            }
            h3 {
                font-size: 14px;
                color: #1a1f2b;
                font-weight: 500;
                margin-bottom: 6px;
                a {
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
            .price {
                font-size: 15px;
                line-height: 20px;
                color: $black-color-2;
                font-weight: 400;
                margin-bottom: 0;
            }
        }
    }
    .product-variable-block {
        border-top: 1px solid #ececec;
        padding-top: 35px;
        width: 100%;
        h5 {
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            color: $black-color-2;
            margin-bottom: 10px;
        }
        .clear-var {
            color: #999;
            display: block;
            font-size: .9em;
            margin: 10px 0 20px;
            &:hover {
                color: $primary-color;
            }
        }
        .color-select {
            background-color: #f9f9f9;
            border: 1px solid #ececec;
            height: 30px;
            padding: 5px 5px 5px 15px;
            width: auto;
            display: inline-block;
            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
        .price {
            border-bottom: 1px solid #1a1f2b;
            border-top: 1px solid #1a1f2b;
            color: #56a700;
            font-size: 18px;
            font-weight: 400;
            padding: 12px 0;
            margin-bottom: 20px;
            display: inline-block;
        }
    }
}

.old-price {
    color: #999;
    font-weight: normal;
    text-decoration: line-through;
}

/* Share Block 1*/

.social-btns {
    display: flex;
    li {
        margin-right: 10px;
    }
    a {
        font-size: 11px;
        border-radius: 3px;
        height: 20px !important;
        padding: 0 6px;
        color: #fff;
        transition: ease .3s all;
        i {
            margin-right: 4px;
        }
        &.facebook {
            background: #4267b2;
            &:hover {
                background: #365899;
            }
        }
        &.twitter {
            background: #1b95e0;
            &:hover {
                background: #0c7abf;
            }
        }
        &.google {
            background: #fe6d4c;
            &:hover {
                background: #e75a3a;
            }
        }
    }
    &.social-btns-2 {
        i {
            margin-right: 0;
        }
        a {
            color: #1a1f2b;
            padding: 0;
            &:hover {
                color: $primary-color;
            }
        }
    }
}

/* Cart And Wishlist Button */

.btn-options {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 25px;
        transition: .4s;
        display: flex;
        align-items: center;
        i {
            margin-right: 5px;
            transition: .4s;
            transform: rotateY(0deg);
            display: inline-block;
        }
        &:hover {
            color: $primary-color;
            i {
                transform: rotateY(180deg);
                color: $primary-color;
            }
        }
    }
}

/* Product Gallery Slider*/

a.gallary-item {
    margin: 0 5px;
    border: 1px solid #ececec;
    opacity: .6;
    &:hover {
        opacity: 1;
        border-color: $primary-color;
    }
}

/*Elevate Zoom*/

.pm-product-details {
    .image-block {
        .zoomWrapper {
            margin: 0 auto;
        }
    }
    #product-view-gallery {
        margin-top: 20px;
    }
}

/* Product zoom effect Mobile Control*/

.zoomWindow {
    @include mx-mobile-xs {
        display: none !important;
        visibility: hidden !important;
    }
    @include mx-mobile {
        visibility: hidden !important;
    }
}

.elevate-gallery {
    display: flex;
}

.elevet-enable {
    .zoomContainer {
        @include desktops {
            display: block !important;
        }
    }
}

.zoomContainer {
    display: none !important;
}

.product-details-tab {
    .nav-tabs {
        justify-content: center;
        background: #f2f2f2;
        border-bottom: 1px solid #ececec;
        margin: 0 0 30px 0;
        padding: 10px 0;
        position: relative;
        text-align: center;
        width: 100%;
        .nav-item {
            .nav-link {
                background: transparent;
                border-color: transparent;
                font-size: 15px;
                font-weight: 500;
                color: #999;
            }
            .nav-link {
                &.active {
                    color: $primary-color;
                }
            }
        }
    }
    .tab-content {
        article {
            p {
                font-size: 14px;
                line-height: 2.4;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.review-wrapper {
    .title-lg {
        font-size: 17px;
        color: $black-color-2;
    }
}

.review-comment {
    display: flex;
    .avatar {
        width: 60px;
        margin-right: 10px;
    }
    .text {
        border: 1px solid #ececec;
        width: calc(100% - 70px);
        padding: 1em 1em 0;
        border-radius: 3px;
        position: relative;
        &:before {
            background: #fff;
            border-bottom: 1px solid #ececec;
            border-left: 1px solid #ececec;
            content: '';
            display: block;
            height: 10px;
            left: -6px;
            position: absolute;
            top: 10px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 10px;
        }
        .author {
            margin-bottom: 10px;
        }
        p {
            padding-bottom: 10px;
        }
    }
}

/*Dynamic Rating*/

.rating-row p {
    margin-right: 3px;
    display: inline-block;
    font-weight: 500;
}

.rating-widget-block {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.rating-widget-block input {
    display: none;
}

.rating-widget-block label {
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    margin-bottom: 0;
    display: inline-block;
    &:last-child {
        margin-right: 0;
    }
}

.rating-widget-block label:before {
    content: "\f005";
    font-family: "Font Awesome 5 Free";
    position: relative;
    display: block;
    font-size: 19px;
    color: #e3e3e3; // color: #fec701;
    // font-weight: 900;
}

.rating-widget-block label:after {
    content: "\f005";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    display: block;
    font-size: 19px;
    color: #fec701;
    font-weight: 900;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.4s;
}

.rating-widget-block label:hover~label:after,
.rating-widget-block label:hover:after,
.rating-widget-block input:checked~label:after {
    opacity: 1;
}