/* Login Register */

/*-- Login Title --*/

.login-title {
    line-height: 23px; // text-decoration: underline;
    text-transform: capitalize;
    font-weight: 500;
    margin-bottom: 30px;
    color: $black-color-2;
    @include tablet {
        font-size: 30px;
    }
}

/*-- Login Form --*/

.login-form {
    border: 1px solid #d3ced2;
    padding: 20px;
    margin: 2em 0;
    text-align: left;
    border-radius: 5px;
    @include mx-tablet {
        padding: 15px;
    }
    & label {
        display: block;
        font-size: 14px;
        margin-bottom: 12px;
        font-weight: 400;
        text-transform: capitalize;
    }
    & .nice-select {
        width: 100%;
        background-color: transparent;
        border: 1px solid #565f69;
        border-radius: 0;
        line-height: 23px;
        padding: 10px 20px;
        font-size: 14px;
        height: 45px;
        color: #d4d4d4;
        margin-bottom: 15px;
        &::after {
            width: 6px;
            height: 6px;
            border-width: 1px;
            right: 20px;
            border-color: #565f69;
        }
        & .current {
            color: $text-color;
            display: block;
            line-height: 23px;
        }
        & .list {
            width: 100%;
        }
    }
    & input {
        background: #f4f4f4;
        border: none;
        border-radius: 0;
        height: 50px;
        line-height: 50px;
        max-width: 100%;
        width: 100%;
        padding: 0 0 0 10px;
        vertical-align: middle;
        &[type="checkbox"] {
            width: auto;
        }
        &:focus {
            border-color: $primary-color;
            box-shadow: none;
            outline: none;
        }
    }
    & .check-box {
        float: left;
        margin-right: 70px;
        &:last-child {
            margin-right: 0;
        }
        & input[type="checkbox"] {
            display: none;
            &+label {
                position: relative;
                padding-left: 30px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #d4d4d4;
                margin: 0;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    display: block;
                    border: 2px solid #565f69;
                    content: "";
                    transition: all 0.3s ease 0s;
                }
                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: block;
                    content: "\f00c";
                    font-family: Fontawesome;
                    font-size: 12px;
                    line-height: 20px;
                    opacity: 0;
                    color: #d4d4d4;
                    width: 20px;
                    text-align: center;
                    transition: all 0.3s ease 0s;
                }
            }
            &:checked+label {
                &::before {
                    border: 2px solid #565f69;
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

/*-- Place Order --*/

.register-button {
    display: block;
    margin-top: 40px;
    width: 140px;
    border-radius: 0;
    height: 36px;
    border: none;
    line-height: 24px;
    padding: 6px 20px;
    float: left;
    font-weight: 400;
    text-transform: uppercase;
    color: $white;
    border-radius: 3px;
    &:hover {
        // background-color: $primary-color;
    }
}

/*=====  End of 17. Login Register  ======*/

.entry-header {
    margin-bottom: 40px;
    .entry-title {
        font-size: 30px;
        line-height: 1.35;
        margin: 0 0 5px;
        text-transform: uppercase;
        color: $black-color-2;
        @include desktops {
            font-size: 36px;
        }
    }
}