@mixin breakpoint($point) {
  @if $point==xlarge {
    @media (max-width: 120em) {
      @content ;
    }
  }
  @if $point==xlargeonly {
    @media (min-width: 75em) {
      @content ;
    }
  }
  @if $point==largeonly {
    @media (min-width: 62em) {
      @content ;
    }
  }
  @if $point==mediumtoxlarge {
    @media (min-width: 62em) {
      @content ;
    }
  }
  @if $point==smalltoxlarge {
    @media (min-width: 47.94em) {
      @content ;
    }
  }
  @else if $point==large {
    @media (max-width: 74.9375em) {
      @content ;
    }
  }
  @else if $point==medium {
    @media (max-width: 61.94em) {
      @content ;
    }
  }
  @else if $point==small {
    @media (max-width: 47.94em) {
      @content ;
    }
  }
  @else if $point==xsmall {
    @media (max-width: 35.94em) {
      @content ;
    }
  }
  @else if $point==xxsmall {
    @media (max-width: 28.125em) {
      @content ;
    }
  }
}

@mixin title-sep {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6px;
    background-repeat: no-repeat;
    @content;
  }
}

@mixin flex($justify:flex-start, $align:center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}


@mixin inline-flex($justify:flex-start, $align:center) {
  display: inline-flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-column($justify:center, $align:flex-start) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
}

@mixin title($size, $weight:400, $family:$font-rubik) {
  font-size: $size;
  font-weight: $weight !important;
  font-family: $family !important;
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
  @include optional-at-root(':-moz-placeholder') {
    @content;
  }
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

//-------------
//PlaceHolders
%border-bottom {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background: #ececec;
    left: 25px;
    height: 1px;
    bottom: -1px;
    right: 25px;
  }
}

%border-left {
  position: relative;
  &:before {
    content: "";
    width: 1px;
    position: absolute;
    background: $border-color;
    left: 0;
    top: 10px;
    bottom: 5px;
  }
}
