/*
====> theme-color-4 Styles
*/

$white-color: #fff;
.petmark-theme-4 {
    $primary-color: #128ced;
    $yellow-color: #f8d81c;
    .bg-primary {
        background-color: $primary-color !important;
    }
    .category-widget .search-submit:hover {
        background: rgba($primary-color, .95)
    }
    .btn-outlined--white:hover,
    .btn-outlined:hover,
    .pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link.active::after,
    .pm-product .onsale-badge,
    .category-widget .search-submit,
    .btn-outlined--primary:hover,
    .home-slider ul.slick-dots li.slick-active button,
    .home-slider ul.slick-dots li:hover button,
    .block-title:after,
    #scrollUp:hover {
        background-color: $primary-color;
    } // {
    //   background: rgba($yellow-color, .8)
    // }
    .btn-outlined--white:hover,
    .pm-tab-nav.nav-tabs .nav-link.active,
    a.gallary-item:hover,
    #scrollUp:hover {
        border-color: $primary-color;
    }
    .text-primary,
    .mainmenu .sub-menu>li:hover:after,
        {
        color: $primary-color !important;
    }
    .btn-outlined:hover,
    .btn-outlined--primary {
        border-color: $primary-color !important;
    }
    .cart-widget.white-color .cart-count-badge,
    .cart-widget .cart-icon .cart-count-badge {
        background-color: $yellow-color;
    }
    .cart-widget .cart-icon .cart-count-badge {
        color: #1a1f2b;
    }
    .cart-widget .cart-text .amount {
        color: $yellow-color;
    }
    .policy-block-single .icon,
    .cart-widget-wrapper .cart-product .product-details--title:hover,
    .cart-widget-wrapper .cart-product .cart-cross:hover,
    .mainmenu li.menu-item-has-children ul>li>ul>li:hover>a,
    .pm-tab-nav.nav-tabs .nav-link:hover,
    .category-block-single h3:hover a,
    .pm-product .hover-conents .product-btns a:hover,
    .petmark-slick-slider button.slick-arrow:hover:before,
    .footer-copyright a,
    .footer-list li a:hover,
    .social-icons a:hover,
    .input-box-with-icon button:hover,
    .pm-product .content h3 a:hover,
    .header-links li:hover a,
    .header-top-nav .nav-item:hover>a,
    .home-content .slider-btn,
    .sticky-header .mainmenu li:hover>a,
    .pm-tab-nav.nav-tabs .nav-link.active,
    .blog-post.card-style-list--2 .blog-date,
    .pm-product-details .description-block .status i,
    .mainmenu li.menu-item-has-children ul>li>ul>li:hover>a,
    .mainmenu li:hover>a {
        color: $primary-color;
    }
    .pm-product-details .description-block .add-to-cart .btn-block .btn {
        color: $primary-color;
        &:hover {
            color: $white-color !important;
        }
    }
    .btn .text-primary,
    .sticky-header .mainmenu li:hover:after {
        color: $primary-color !important;
    }
    .cart-widget.white-color .cart-count-badge,
    .mainmenu>li:hover>a {
        color: $white-color;
    }
}