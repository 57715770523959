/******************************

|--- Fonts
|--- Helpers
|	|
|	|___ media-query
|	|___ variables
|	|___ mixins
|
|--- Reset Styles
|--- Common Styles
|	|
|	|___ color
|	|___ spacings
|	|___ sliders
|	|___ tabs
|	|___ typography
|	|___ buttons
|	|___ modal
|	|___ images
|	|___ lists
|	|___ forms
|
|--- Sections Styles
|	|
|	|___ header
|	|___ menu
|	|___ hero-area
|	|___ bredcrumb
|	|___ policy-section
|	|___ category
|	|___ comment
|	|___ product
|	|___ sidebars
|	|___ footer
|
|--- Pages Styles
|	|
|	|___ shop
|	|___ product-details
|	|___ cart
|	|___ checkout
|	|___ compare
|	|___ contact
|	|___ blog
|	|___ cart
|	|___ my-account
|	|___ login-register
|	|___ about
|	|___ faq
|	|___ 404
|	|___ portfolio
|	|___ service
|
|--- theme-color-2 Styles
|	|
|--- theme-color-3 Styles
|	|
|--- theme-color-4 Styles


******************************/

@import "fonts";
@import "scss-helpers";
@import "reset";
@import "common";
@import "sections";
@import "pages";
/* Theme Color Variation */

@import "theme-color-2";
@import "theme-color-3";
@import "theme-color-4";