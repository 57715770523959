/*
====> Pages
*/

@import "pages/shop";
@import "pages/product-details";
@import "pages/checkout";
@import "pages/compare";
@import "pages/contact";
@import 'pages/blog';
@import 'pages/cart';
@import 'pages/my-account';
@import 'pages/login-register';
@import 'pages/about';
@import 'pages/faq';
@import 'pages/404';
@import 'pages/portfolio';
@import 'pages/service';