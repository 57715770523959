/* 404 Page */ 
.error-section {
  padding-top: 100px;
  padding-bottom: 100px;
  border-top: 1px solid #f0f0f0;
  padding-left: 30px;
  padding-right: 30px;
  @include tablet {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.error-content {
  text-align: center;
  h2 {
    color: #1a1f2b;
    text-transform: uppercase;
    font-size: 30px;
    @include tablet {
      font-size: 35px;
    }
  }
  p {
    color: #1a1f2b;
    font-weight: 300;
    margin: 20px auto 40px;
    text-align: center;
    line-height: 1.5;
    font-size: 19px;
    @include mobile-lg {
      font-size: 22px;
    }
    @include desktops {
      padding: 0 21px;
    }
  }
  .search-form {
    .btn {
      margin-top: 40px;
      width: 185px;
      height: 40px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
    }
  }
  .site-mini-search {
    margin: 0 auto;
    @include mobile-lg {
      max-width: 500px;
    }
    input {
      font-size: 1.143em;
      height: 50px;
      line-height: 50px;
    }
    button {
      width: 50px;
      font-size: 16px;
    }
  }
}