/*
====> Sections Styles
*/

@import "sections/header";
@import "sections/menu";
@import "sections/hero-area";
@import "sections/bredcrumb";
@import "sections/policy-section";
@import "sections/category";
@import "sections/comment";
@import "sections/product";
@import "sections/sidebars";
@import "sections/footer";