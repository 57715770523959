$primary-color: #56a700;
$green-color: $primary-color;
$primary-color-2:#e02c2c;
$primary-color-3:#5fbd74;
$white-color:#ffff;
$white:$white-color;
$black:#000;
$ash-color:#f2f2f2;
$gray-color:#f6f6f6;
$black-color-2:#1a1f2b;
$black-color-3:#10151f;
;
$black-color:$black;
$blue-color:#1d62b2;
$blue-color-2:#128ced;
$orange-color:#ff7c00;
$red-color:#c61132;
$text-color:#d4d4d4;
$border-color: #ececec;
// $primary-color-gradient: linear-gradient( -90deg, rgb(189,247,103) 0%, rgb(146,217,78) 43%, rgb(102,186,52) 100%);
/*Font Families*/

$font-rubik:'Rubik',
sans-serif;
$font-prata:'Prata',
serif;
$font-shadow-cursive:'Shadows Into Light',
cursive;
$font-arvo:'Arvo',
serif;
/*Bootstrap Variables*/

$breadcrumb-divider: quote(">");
/* Media Queries */ 
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';