/* Buttons */ 
.btn {
  border-radius: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 14px;
  width: 150px;
  border-radius: 3px;
  &-outlined {
    background: transparent;
    border: 2px solid #ececec !important;
    &:hover {
      background: $primary-color;
      border-color: $primary-color !important;
      color: $white-color;
    }
    &--primary {
      background: transparent;
      border: 2px solid $primary-color !important;
      &:hover {
        background: $primary-color;
        border-color: $primary-color !important;
        color: $white-color;
      }
    }
    &--white {
      background: transparent;
      border: 2px solid $white-color;
      color: $white-color;
      &:hover {
        background: $primary-color;
        border-color: $primary-color;
        color: $white-color;
      }
    }
    &--black {
      background: transparent;
      border: 1px solid $black-color-2;
      color: $black-color-2;
      &:hover {
        background: $primary-color;
        border-color: $primary-color;
        color: $white-color;
      }
    }
    &--orange {
      background: transparent;
      border: 2px solid $orange-color;
      color: $orange-color;
      &:hover {
        background: $orange-color;
        border-color: $orange-color;
        color: $white-color;
      }
    }
  }
  &-rounded {
    border-radius: 500px;
  }
  &-mid {
    height: 40px;
    width: 195px;
  }
  &--primary{
    background: $primary-color;
    color: #fff;
    &:hover{
      background: $black-color-2;
      color:#fff;
    }
  }
}

.btn-black {
  height: 50px;
  padding: 0 30px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  background: $black-color-2;
  font-size: 13px;
  font-weight: 500;
  font-family: Rubik, Arial, Helvetica, sans-serif;
  box-shadow: none;
  border-radius: 3px;
  border: none;
  outline: none;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  &:hover {
    background: $primary-color;
    color: $white-color;
  }
}