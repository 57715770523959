 /* Service Page */

 .service-wrapper {
     .service-section-1 {
         padding: 60px 0 25px;
     }
     .service-section-2 {
         padding-top: 60px;
         padding-bottom: 35px;
     }
     .service-section-4 {
         padding-top: 60px;
         padding-bottom: 30px;
     }
     &-2 {
         .service-section-1 {
             padding-top: 60px;
             padding-bottom: 30px;
         }
         .service-section-3 {
             padding-top: 60px;
             padding-bottom: 30px;
         }
         .service-about-section {
             padding-top: 90px;
             padding-bottom: 60px;
             @include desktops {
                 padding-top: 90px;
                 padding-bottom: 185px;
             }
         }
     }
 } //  padding-top: 90px;
 //  padding-bottom: 55px; 
 // background-image: url(../image/others/services/service-about-bg.jpg);
 //  background-position: center left;
 //  background-size: cover;
 //  position: absolute;
 //  height: 85%;
 //  width: 60%;
 //  left: 0%;
 //  top: 30px;
 .service-about-bg {
     @include desktops {
         background-image: url(../image/others/services/service-about-bg.jpg);
         background-position-x: left;
         background-size: cover;
         background-position-y: 62%;
     }
 }

 .service-cta {
     .cta-content {
         text-align: center;
         padding-top: 100px;
         padding-bottom: 100px;
         color: #ffffff;
         h3 {
             font-size: 30px;
             text-align: center;
             font-family: Raleway;
             font-weight: 900;
             margin-bottom: 30px
         }
         p {
             font-style: italic;
             font-weight: 400;
         }
     }
 }

 .service-wrapper {
     .section-title {
         color: $white-color;
         text-align: center;
         margin-bottom: 60px;
         h2 {
             font-size: 30px;
             text-align: center;
             font-family: Raleway;
             font-weight: 900;
             font-style: normal;
             margin-bottom: 20px;
         }
         p {
             color: #c6c6c6;
         }
     }
 }

 .service-about-section {
     .section-about-title {
         margin-bottom: 70px;
         h3 {
             font-size: 30px;
             color: #444444;
             text-align: left;
             font-family: Raleway;
             font-weight: 900;
             font-style: normal;
         }
     }
 }

 .service-card {
     .image {
         margin-bottom: 35px;
         img {
             width: 100%;
         }
     }
     h4 {
         font-size: 14px;
         color: #444444;
         text-align: left;
         font-family: 'Raleway', sans-serif;
         font-weight: 900;
         text-transform: uppercase;
         margin-bottom: 20px;
     }
     p {
         line-height: 1.65;
         padding-bottom: 10px;
     }
 }

 .service-widget {
     display: flex;
     .icon {
         color: #4fc1f0;
         font-size: 34.4px;
         max-width: 64px;
         width: 100%;
         text-align: center;
         height: 64px;
     }
     .content {
         h5 {
             font-size: 13px;
             color: #c6c6c6;
             text-align: left;
             font-family: 'Raleway', sans-serif;
             font-weight: 700;
             font-style: normal;
             line-height: 1.35;
             margin-bottom: 5px;
         }
         p {
             color: #999;
             line-height: 1.65;
         }
     }
     &.black-color {
         .content {
             h5 {
                 color: #444;
             }
             p {
                 color: #666;
             }
         }
     }
     &-2 {
         display: flex;
         .icon {
             color: #4fc1f0;
             max-width: 64px;
             width: 100%;
             max-height: 64px;
             display: flex;
             align-items: center;
             justify-content: center;
             margin-right: 10px;
             border-radius: 500px;
             border: 2px solid #4fc1f0;
             i {
                 font-size: 30px;
             }
         }
         .content {
             h5 {
                 font-size: 14px;
                 margin-bottom: 10px;
                 color: #444444;
                 text-align: left;
                 font-family: Raleway;
                 font-weight: 700;
                 font-style: normal;
             }
             p {
                 margin-bottom: 10px;
             }
         }
     }
 }

 .service-description-area {
     padding: 80px 0;
     text-align: center;
     h2 {
         font-size: 30px;
         color: #444444;
         text-align: center;
         font-family: Raleway;
         font-weight: 900;
         font-style: normal;
         line-height: 1.35;
         margin: 0 0 5px;
     }
     article {
         padding-top: 50px;
         padding-bottom: 40px;
         padding-left: 30px;
         padding-right: 30px;
         @include tablet {
             padding-left: 50px;
             padding-right: 50px;
         }
         @include desktops {
             padding-left: 30px;
             padding-right: 30px;
         }
         @include large-desktops {
             padding-left: 50px;
             padding-right: 50px;
         }
         p {
             padding-bottom: 10px;
             line-height: 1.65;
         }
     }
 }

 .btn.btn-service {
     width: 145px;
     border: 2px solid #000;
     border-radius: 0;
     font-size: 16px;
     /* padding-top: 10px; */
     height: 50px;
     i {
         margin-left: 15px;
     }
     &:hover {
         background: $black-color-2;
         color: $white-color;
     }
 }

 .pricing-card {
     text-align: center;
     background: #fff;
     &.box-shadow {
         box-shadow: 0 1px #fff inset, 0 1px 3px rgba(34, 25, 25, 0.4);
     }
     header {
         background: #3e3e3e;
         padding: 20px;
         color: #fff;
         transition: .4s;
         h2 {
             color: #fff;
             font-size: 14px;
             font-weight: 600;
             text-transform: uppercase;
             text-align: center;
         }
     }
     .card-body {
         padding: 0 20px 40px 20px;
         .price {
             font-family: Rubik, Arial, Helvetica, sans-serif;
             font-size: 48px;
             font-weight: bold;
             margin: 0;
             padding: 50px 0;
             text-transform: uppercase;
             .month {
                 font-size: 12px;
             }
         }
         ul.pricing-list {
             padding-left: 15px;
             li {
                 border-bottom: 1px solid #ececec;
                 list-style: none;
                 padding: 15px 0;
                 &:first-child {
                     border-top: 1px solid #ececec;
                 }
             }
         }
         .card-btn {
             margin: 40px 0 0;
             .btn {
                 height: 50px;
                 font-size: 14px;
                 padding: 0 30px;
                 width: auto;
             }
         }
     }
     &:hover {
         header {
             background: $primary-color;
         }
     }
 }

 .portfolio-single {
     position: relative;
     img {
         width: 100%;
     }
     &:hover {
         .hover-content {
             opacity: 1;
         }
     }
     .hover-content {
         text-align: center;
         text-align: center;
         position: absolute;
         top: 0;
         height: 100%;
         width: 100%;
         background: #fff;
         padding-left: 20px;
         padding-right: 20px;
         opacity: -1;
         transition: .4s;
         padding-top: 30px;
         @include mobile-lg {
             padding-top: 60px;
         }
         @include desktops {
             padding-top: 40px;
         }
         @include large-desktops {
             padding-top: 60px;
         }
         .hover-item-icon {
             margin-bottom: 5px;
             i {
                 background: #1a1f2b;
                 border-radius: 100%;
                 color: #fff;
                 display: inline-block;
                 display: inline-flex;
                 align-items: center;
                 justify-content: center;
                 text-align: center;
                 font-size: 20px;
                 height: 80px;
                 width: 80px;
                 @include mobile-lg {
                     font-size: 40px;
                     height: 115px;
                     width: 115px;
                 }
                 @include desktops {
                     font-size: 30px;
                     width: 70px;
                     height: 70px;
                 }
                 @include large-desktops {
                     font-size: 40px;
                     height: 115px;
                     width: 115px;
                 }
             }
         }
         .hover-item-content {
             h4 {
                 font-weight: 500;
                 line-height: 1.35;
                 margin: 0 0 5px;
                 color: $black-color-2;
             }
             P {
                 margin-bottom: 10px;
             }
         }
     }
 }