/******************************

|--- Fonts
|--- Helpers
|	|
|	|___ media-query
|	|___ variables
|	|___ mixins
|
|--- Reset Styles
|--- Common Styles
|	|
|	|___ color
|	|___ spacings
|	|___ sliders
|	|___ tabs
|	|___ typography
|	|___ buttons
|	|___ modal
|	|___ images
|	|___ lists
|	|___ forms
|
|--- Sections Styles
|	|
|	|___ header
|	|___ menu
|	|___ hero-area
|	|___ bredcrumb
|	|___ policy-section
|	|___ category
|	|___ comment
|	|___ product
|	|___ sidebars
|	|___ footer
|
|--- Pages Styles
|	|
|	|___ shop
|	|___ product-details
|	|___ cart
|	|___ checkout
|	|___ compare
|	|___ contact
|	|___ blog
|	|___ cart
|	|___ my-account
|	|___ login-register
|	|___ about
|	|___ faq
|	|___ 404
|	|___ portfolio
|	|___ service
|
|--- theme-color-2 Styles
|	|
|--- theme-color-3 Styles
|	|
|--- theme-color-4 Styles


******************************/
/*
====> Default Styles
*/
@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,900");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800");
/*
====> Helpers
*/
/*=== MEDIA QUERY ===*/
/*=== MEDIA QUERY with max width===*/
/*Font Families*/
/*Bootstrap Variables*/
/* Media Queries */
.one-column-slider.two-row .slick-active > div, .one-column-slider.three-row .slick-active > div {
  position: relative;
}

.one-column-slider.two-row .slick-active > div:before, .one-column-slider.three-row .slick-active > div:before {
  content: "";
  position: absolute;
  background: #ececec;
  left: 25px;
  height: 1px;
  bottom: -1px;
  right: 25px;
}

.normal-slider .slick-slide {
  position: relative;
}

.normal-slider .slick-slide:before {
  content: "";
  width: 1px;
  position: absolute;
  background: #ececec;
  left: 0;
  top: 10px;
  bottom: 5px;
}

/*
====> Reset Styles
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*=== MEDIA QUERY ===*/
/*=== MEDIA QUERY with max width===*/
body {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  color: #555555;
  font-size: 14px;
  line-height: 1.65;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a,
span {
  display: inline-block;
}

a {
  transition: 0.4s;
  color: inherit;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

a:visited,
a:active {
  outline: none;
  border: none;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
}

img {
  max-width: 100%;
}

p {
  line-height: 20px;
}

.btn:focus,
.btn:active {
  box-shadow: none;
  border-color: transparent;
}

button {
  cursor: pointer;
  background: transparent;
  border: none;
}

button:focus {
  outline: none;
}

.icon {
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.card {
  border: none;
  border-radius: 0;
}

/* Slick Slider Resets */
.slick-slide:focus {
  outline: none;
}

.slick-slide:focus a:focus {
  outline: none;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.site-wrapper {
  overflow: hidden;
}

/*
====> Common Styles
*/
/* Colors */
.text-primary {
  color: #56a700 !important;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #1a1f2b;
}

.text-red {
  color: #c61132;
}

.text-orange {
  color: #ff7c00 !important;
}

.bg-red {
  background-color: #c61132;
}

.bg-ash {
  background-color: #f2f2f2;
}

.bg-gray {
  background-color: #f6f6f6;
}

.bg-blue {
  background-color: #1d62b2;
}

.bg-blue-2 {
  background-color: #128ced;
}

.bg-orange {
  background-color: #ff7c00;
}

.border-bottom {
  border-bottom: 1px solid #cecece;
}

.text-gray {
  color: #999;
}

.bg-gray {
  background: #f2f2f2;
}

/* Spacings */
.section-padding {
  padding-top: 90px;
  padding-bottom: 90px;
}

.space-db--15 {
  margin-bottom: -15px;
}

.space-db--20 {
  margin-bottom: -20px;
}

.space-db--25 {
  margin-bottom: -25px;
}

.space-db--30 {
  margin-bottom: -30px;
}

.space-db--35 {
  margin-bottom: -35px;
}

.space-db--40 {
  margin-bottom: -40px;
}

.space-db--45 {
  margin-bottom: -45px;
}

.space-db--50 {
  margin-bottom: -50px;
}

.space-db--55 {
  margin-bottom: -55px;
}

.space-db--60 {
  margin-bottom: -60px;
}

.space-db--65 {
  margin-bottom: -65px;
}

.space-db--70 {
  margin-bottom: -70px;
}

.space-db--75 {
  margin-bottom: -75px;
}

.space-db--80 {
  margin-bottom: -80px;
}

.space-db--85 {
  margin-bottom: -85px;
}

.space-db--90 {
  margin-bottom: -90px;
}

.space-db--95 {
  margin-bottom: -95px;
}

.space-db--100 {
  margin-bottom: -100px;
}

.space-db--105 {
  margin-bottom: -105px;
}

.space-db--110 {
  margin-bottom: -110px;
}

.space-db--115 {
  margin-bottom: -115px;
}

.space-db--120 {
  margin-bottom: -120px;
}

.space-db--125 {
  margin-bottom: -125px;
}

.space-db--130 {
  margin-bottom: -130px;
}

.space-db--135 {
  margin-bottom: -135px;
}

.space-db--140 {
  margin-bottom: -140px;
}

.space-db--145 {
  margin-bottom: -145px;
}

.space-db--150 {
  margin-bottom: -150px;
}

.space-dt--30 {
  margin-top: -30px;
}

.space-dt--35 {
  margin-top: -35px;
}

.space-dt--40 {
  margin-top: -40px;
}

.space-dt--45 {
  margin-top: -45px;
}

.space-dt--50 {
  margin-top: -50px;
}

.space-dt--55 {
  margin-top: -55px;
}

.space-dt--60 {
  margin-top: -60px;
}

.space-dt--65 {
  margin-top: -65px;
}

.space-dt--70 {
  margin-top: -70px;
}

.space-dt--75 {
  margin-top: -75px;
}

.space-dt--80 {
  margin-top: -80px;
}

.space-dt--85 {
  margin-top: -85px;
}

.space-dt--90 {
  margin-top: -90px;
}

.space-dt--95 {
  margin-top: -95px;
}

.space-dt--100 {
  margin-top: -100px;
}

.space-dt--105 {
  margin-top: -105px;
}

.space-dt--110 {
  margin-top: -110px;
}

.space-dt--115 {
  margin-top: -115px;
}

.space-dt--120 {
  margin-top: -120px;
}

.space-dt--125 {
  margin-top: -125px;
}

.space-dt--130 {
  margin-top: -130px;
}

.space-dt--135 {
  margin-top: -135px;
}

.space-dt--140 {
  margin-top: -140px;
}

.space-dt--145 {
  margin-top: -145px;
}

.space-dt--150 {
  margin-top: -150px;
}

.space-db--30 {
  margin-bottom: -30px;
}

.space-db--35 {
  margin-bottom: -35px;
}

.space-db--40 {
  margin-bottom: -40px;
}

.space-db--45 {
  margin-bottom: -45px;
}

.space-db--50 {
  margin-bottom: -50px;
}

.space-db--55 {
  margin-bottom: -55px;
}

.space-db--60 {
  margin-bottom: -60px;
}

.space-db--65 {
  margin-bottom: -65px;
}

.space-db--70 {
  margin-bottom: -70px;
}

.space-db--75 {
  margin-bottom: -75px;
}

.space-db--80 {
  margin-bottom: -80px;
}

.space-db--85 {
  margin-bottom: -85px;
}

.space-db--90 {
  margin-bottom: -90px;
}

.space-db--95 {
  margin-bottom: -95px;
}

.space-db--100 {
  margin-bottom: -100px;
}

.space-db--105 {
  margin-bottom: -105px;
}

.space-db--110 {
  margin-bottom: -110px;
}

.space-db--115 {
  margin-bottom: -115px;
}

.space-db--120 {
  margin-bottom: -120px;
}

.space-db--125 {
  margin-bottom: -125px;
}

.space-db--130 {
  margin-bottom: -130px;
}

.space-db--135 {
  margin-bottom: -135px;
}

.space-db--140 {
  margin-bottom: -140px;
}

.space-db--145 {
  margin-bottom: -145px;
}

.space-db--150 {
  margin-bottom: -150px;
}

.mb--40 {
  margin-bottom: 40px;
}

.mb--25 {
  margin-bottom: 25px;
}

.mb--15 {
  margin-bottom: 15px;
}

/* container 450px*/
/*-- Padding Top --*/
.pt--5 {
  padding-top: 5px;
}

.pt--10 {
  padding-top: 10px;
}

.pt--15 {
  padding-top: 15px;
}

.pt--20 {
  padding-top: 20px;
}

.pt--25 {
  padding-top: 25px;
}

.pt--30 {
  padding-top: 30px;
}

.pt--35 {
  padding-top: 35px;
}

.pt--40 {
  padding-top: 40px;
}

.pt--45 {
  padding-top: 45px;
}

.pt--50 {
  padding-top: 50px;
}

.pt--55 {
  padding-top: 55px;
}

.pt--60 {
  padding-top: 60px;
}

.pt--65 {
  padding-top: 65px;
}

.pt--70 {
  padding-top: 70px;
}

.pt--75 {
  padding-top: 75px;
}

.pt--80 {
  padding-top: 80px;
}

.pt--85 {
  padding-top: 85px;
}

.pt--90 {
  padding-top: 90px;
}

.pt--95 {
  padding-top: 95px;
}

.pt--100 {
  padding-top: 100px;
}

.pt--105 {
  padding-top: 105px;
}

.pt--110 {
  padding-top: 110px;
}

.pt--115 {
  padding-top: 115px;
}

.pt--120 {
  padding-top: 120px;
}

.pt--125 {
  padding-top: 125px;
}

.pt--130 {
  padding-top: 130px;
}

.pt--135 {
  padding-top: 135px;
}

.pt--140 {
  padding-top: 140px;
}

.pt--145 {
  padding-top: 145px;
}

.pt--150 {
  padding-top: 150px;
}

@media (min-width: 992px) {
  .pt-lg--5 {
    padding-top: 5px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--10 {
    padding-top: 10px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--15 {
    padding-top: 15px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--20 {
    padding-top: 20px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--25 {
    padding-top: 25px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--30 {
    padding-top: 30px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--35 {
    padding-top: 35px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--40 {
    padding-top: 40px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--45 {
    padding-top: 45px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--50 {
    padding-top: 50px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--55 {
    padding-top: 55px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--60 {
    padding-top: 60px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--65 {
    padding-top: 65px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--70 {
    padding-top: 70px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--75 {
    padding-top: 75px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--80 {
    padding-top: 80px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--85 {
    padding-top: 85px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--90 {
    padding-top: 90px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--95 {
    padding-top: 95px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--100 {
    padding-top: 100px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--105 {
    padding-top: 105px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--110 {
    padding-top: 110px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--115 {
    padding-top: 115px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--120 {
    padding-top: 120px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--125 {
    padding-top: 125px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--130 {
    padding-top: 130px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--135 {
    padding-top: 135px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--140 {
    padding-top: 140px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--145 {
    padding-top: 145px !important;
  }
}

@media (min-width: 992px) {
  .pt-lg--150 {
    padding-top: 150px !important;
  }
}

@media only screen and (max-width: 575px) {
  .pt_sm--5 {
    padding-top: 5px;
  }
  .pt_sm--10 {
    padding-top: 10px;
  }
  .pt_sm--15 {
    padding-top: 15px;
  }
  .pt_sm--20 {
    padding-top: 20px;
  }
  .pt_sm--25 {
    padding-top: 25px;
  }
  .pt_sm--30 {
    padding-top: 30px;
  }
  .pt_sm--35 {
    padding-top: 35px;
  }
  .pt_sm--40 {
    padding-top: 40px;
  }
  .pt_sm--45 {
    padding-top: 45px;
  }
  .pt_sm--50 {
    padding-top: 50px;
  }
}

/*-- Padding Bottom --*/
.pb--5 {
  padding-bottom: 5px;
}

.pb--10 {
  padding-bottom: 10px;
}

.pb--15 {
  padding-bottom: 15px;
}

.pb--20 {
  padding-bottom: 20px;
}

.pb--25 {
  padding-bottom: 25px;
}

.pb--30 {
  padding-bottom: 30px;
}

.pb--35 {
  padding-bottom: 35px;
}

.pb--40 {
  padding-bottom: 40px;
}

.pb--45 {
  padding-bottom: 45px;
}

.pb--50 {
  padding-bottom: 50px;
}

.pb--55 {
  padding-bottom: 55px;
}

.pb--60 {
  padding-bottom: 60px;
}

.pb--65 {
  padding-bottom: 65px;
}

.pb--70 {
  padding-bottom: 70px;
}

.pb--75 {
  padding-bottom: 75px;
}

.pb--80 {
  padding-bottom: 80px;
}

.pb--85 {
  padding-bottom: 85px;
}

.pb--90 {
  padding-bottom: 90px;
}

.pb--95 {
  padding-bottom: 95px;
}

.pb--100 {
  padding-bottom: 100px;
}

.pb--105 {
  padding-bottom: 105px;
}

.pb--110 {
  padding-bottom: 110px;
}

.pb--115 {
  padding-bottom: 115px;
}

.pb--120 {
  padding-bottom: 120px;
}

.pb--125 {
  padding-bottom: 125px;
}

.pb--130 {
  padding-bottom: 130px;
}

.pb--135 {
  padding-bottom: 135px;
}

.pb--140 {
  padding-bottom: 140px;
}

.pb--145 {
  padding-bottom: 145px;
}

.pb--150 {
  padding-bottom: 150px;
}

@media only screen and (max-width: 575px) {
  .pb_sm--5 {
    padding-bottom: 5px;
  }
  .pb_sm--10 {
    padding-bottom: 10px;
  }
  .pb_sm--15 {
    padding-bottom: 15px;
  }
  .pb_sm--20 {
    padding-bottom: 20px;
  }
  .pb_sm--25 {
    padding-bottom: 25px;
  }
  .pb_sm--30 {
    padding-bottom: 30px;
  }
  .pb_sm--35 {
    padding-bottom: 35px;
  }
  .pb_sm--40 {
    padding-bottom: 40px;
  }
  .pb_sm--45 {
    padding-bottom: 45px;
  }
  .pb_sm--50 {
    padding-bottom: 50px;
  }
}

/*-- Padding Left --*/
.pl--5 {
  padding-left: 5px;
}

.pl--10 {
  padding-left: 10px;
}

.pl--15 {
  padding-left: 15px;
}

.pl--20 {
  padding-left: 20px;
}

.pl--25 {
  padding-left: 25px;
}

.pl--30 {
  padding-left: 30px;
}

.pl--35 {
  padding-left: 35px;
}

.pl--40 {
  padding-left: 40px;
}

.pl--45 {
  padding-left: 45px;
}

.pl--50 {
  padding-left: 50px;
}

.pl--55 {
  padding-left: 55px;
}

.pl--60 {
  padding-left: 60px;
}

.pl--65 {
  padding-left: 65px;
}

.pl--70 {
  padding-left: 70px;
}

.pl--75 {
  padding-left: 75px;
}

.pl--80 {
  padding-left: 80px;
}

.pl--85 {
  padding-left: 85px;
}

.pl--90 {
  padding-left: 90px;
}

.pl--95 {
  padding-left: 95px;
}

.pl--100 {
  padding-left: 100px;
}

.pl--105 {
  padding-left: 105px;
}

.pl--110 {
  padding-left: 110px;
}

.pl--115 {
  padding-left: 115px;
}

.pl--120 {
  padding-left: 120px;
}

.pl--125 {
  padding-left: 125px;
}

.pl--130 {
  padding-left: 130px;
}

.pl--135 {
  padding-left: 135px;
}

.pl--140 {
  padding-left: 140px;
}

.pl--145 {
  padding-left: 145px;
}

.pl--150 {
  padding-left: 150px;
}

@media (min-width: 992px) {
  .pl-lg--5 {
    padding-left: 5px;
  }
}

@media (min-width: 992px) {
  .pl-lg--10 {
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  .pl-lg--15 {
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .pl-lg--20 {
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .pl-lg--25 {
    padding-left: 25px;
  }
}

@media (min-width: 992px) {
  .pl-lg--30 {
    padding-left: 30px;
  }
}

@media (min-width: 992px) {
  .pl-lg--35 {
    padding-left: 35px;
  }
}

@media (min-width: 992px) {
  .pl-lg--40 {
    padding-left: 40px;
  }
}

@media (min-width: 992px) {
  .pl-lg--45 {
    padding-left: 45px;
  }
}

@media (min-width: 992px) {
  .pl-lg--50 {
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .pl-lg--55 {
    padding-left: 55px;
  }
}

@media (min-width: 992px) {
  .pl-lg--60 {
    padding-left: 60px;
  }
}

@media (min-width: 992px) {
  .pl-lg--65 {
    padding-left: 65px;
  }
}

@media (min-width: 992px) {
  .pl-lg--70 {
    padding-left: 70px;
  }
}

@media (min-width: 992px) {
  .pl-lg--75 {
    padding-left: 75px;
  }
}

@media (min-width: 992px) {
  .pl-lg--80 {
    padding-left: 80px;
  }
}

@media (min-width: 992px) {
  .pl-lg--85 {
    padding-left: 85px;
  }
}

@media (min-width: 992px) {
  .pl-lg--90 {
    padding-left: 90px;
  }
}

@media (min-width: 992px) {
  .pl-lg--95 {
    padding-left: 95px;
  }
}

@media (min-width: 992px) {
  .pl-lg--100 {
    padding-left: 100px;
  }
}

@media (min-width: 992px) {
  .pl-lg--105 {
    padding-left: 105px;
  }
}

@media (min-width: 992px) {
  .pl-lg--110 {
    padding-left: 110px;
  }
}

@media (min-width: 992px) {
  .pl-lg--115 {
    padding-left: 115px;
  }
}

@media (min-width: 992px) {
  .pl-lg--120 {
    padding-left: 120px;
  }
}

@media (min-width: 992px) {
  .pl-lg--125 {
    padding-left: 125px;
  }
}

@media (min-width: 992px) {
  .pl-lg--130 {
    padding-left: 130px;
  }
}

@media (min-width: 992px) {
  .pl-lg--135 {
    padding-left: 135px;
  }
}

@media (min-width: 992px) {
  .pl-lg--140 {
    padding-left: 140px;
  }
}

@media (min-width: 992px) {
  .pl-lg--145 {
    padding-left: 145px;
  }
}

@media (min-width: 992px) {
  .pl-lg--150 {
    padding-left: 150px;
  }
}

@media (min-width: 992px) {
  .pr-lg--5 {
    padding-right: 5px;
  }
}

@media (min-width: 992px) {
  .pr-lg--10 {
    padding-right: 10px;
  }
}

@media (min-width: 992px) {
  .pr-lg--15 {
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .pr-lg--20 {
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .pr-lg--25 {
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .pr-lg--30 {
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .pr-lg--35 {
    padding-right: 35px;
  }
}

@media (min-width: 992px) {
  .pr-lg--40 {
    padding-right: 40px;
  }
}

@media (min-width: 992px) {
  .pr-lg--45 {
    padding-right: 45px;
  }
}

@media (min-width: 992px) {
  .pr-lg--50 {
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .pr-lg--55 {
    padding-right: 55px;
  }
}

@media (min-width: 992px) {
  .pr-lg--60 {
    padding-right: 60px;
  }
}

@media (min-width: 992px) {
  .pr-lg--65 {
    padding-right: 65px;
  }
}

@media (min-width: 992px) {
  .pr-lg--70 {
    padding-right: 70px;
  }
}

@media (min-width: 992px) {
  .pr-lg--75 {
    padding-right: 75px;
  }
}

@media (min-width: 992px) {
  .pr-lg--80 {
    padding-right: 80px;
  }
}

@media (min-width: 992px) {
  .pr-lg--85 {
    padding-right: 85px;
  }
}

@media (min-width: 992px) {
  .pr-lg--90 {
    padding-right: 90px;
  }
}

@media (min-width: 992px) {
  .pr-lg--95 {
    padding-right: 95px;
  }
}

@media (min-width: 992px) {
  .pr-lg--100 {
    padding-right: 100px;
  }
}

@media (min-width: 992px) {
  .pr-lg--105 {
    padding-right: 105px;
  }
}

@media (min-width: 992px) {
  .pr-lg--110 {
    padding-right: 110px;
  }
}

@media (min-width: 992px) {
  .pr-lg--115 {
    padding-right: 115px;
  }
}

@media (min-width: 992px) {
  .pr-lg--120 {
    padding-right: 120px;
  }
}

@media (min-width: 992px) {
  .pr-lg--125 {
    padding-right: 125px;
  }
}

@media (min-width: 992px) {
  .pr-lg--130 {
    padding-right: 130px;
  }
}

@media (min-width: 992px) {
  .pr-lg--135 {
    padding-right: 135px;
  }
}

@media (min-width: 992px) {
  .pr-lg--140 {
    padding-right: 140px;
  }
}

@media (min-width: 992px) {
  .pr-lg--145 {
    padding-right: 145px;
  }
}

@media (min-width: 992px) {
  .pr-lg--150 {
    padding-right: 150px;
  }
}

@media (min-width: 992px) {
  .mt-lg--5 {
    margin-top: 5px;
  }
}

@media (min-width: 992px) {
  .mt-lg--10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .mt-lg--15 {
    margin-top: 15px;
  }
}

@media (min-width: 992px) {
  .mt-lg--20 {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .mt-lg--25 {
    margin-top: 25px;
  }
}

@media (min-width: 992px) {
  .mt-lg--30 {
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .mt-lg--35 {
    margin-top: 35px;
  }
}

@media (min-width: 992px) {
  .mt-lg--40 {
    margin-top: 40px;
  }
}

@media (min-width: 992px) {
  .mt-lg--45 {
    margin-top: 45px;
  }
}

@media (min-width: 992px) {
  .mt-lg--50 {
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .mt-lg--55 {
    margin-top: 55px;
  }
}

@media (min-width: 992px) {
  .mt-lg--60 {
    margin-top: 60px;
  }
}

@media (min-width: 992px) {
  .mt-lg--65 {
    margin-top: 65px;
  }
}

@media (min-width: 992px) {
  .mt-lg--70 {
    margin-top: 70px;
  }
}

@media (min-width: 992px) {
  .mt-lg--75 {
    margin-top: 75px;
  }
}

@media (min-width: 992px) {
  .mt-lg--80 {
    margin-top: 80px;
  }
}

@media (min-width: 992px) {
  .mt-lg--85 {
    margin-top: 85px;
  }
}

@media (min-width: 992px) {
  .mt-lg--90 {
    margin-top: 90px;
  }
}

@media (min-width: 992px) {
  .mt-lg--95 {
    margin-top: 95px;
  }
}

@media (min-width: 992px) {
  .mt-lg--100 {
    margin-top: 100px;
  }
}

@media (min-width: 992px) {
  .mt-lg--105 {
    margin-top: 105px;
  }
}

@media (min-width: 992px) {
  .mt-lg--110 {
    margin-top: 110px;
  }
}

@media (min-width: 992px) {
  .mt-lg--115 {
    margin-top: 115px;
  }
}

@media (min-width: 992px) {
  .mt-lg--120 {
    margin-top: 120px;
  }
}

@media (min-width: 992px) {
  .mt-lg--125 {
    margin-top: 125px;
  }
}

@media (min-width: 992px) {
  .mt-lg--130 {
    margin-top: 130px;
  }
}

@media (min-width: 992px) {
  .mt-lg--135 {
    margin-top: 135px;
  }
}

@media (min-width: 992px) {
  .mt-lg--140 {
    margin-top: 140px;
  }
}

@media (min-width: 992px) {
  .mt-lg--145 {
    margin-top: 145px;
  }
}

@media (min-width: 992px) {
  .mt-lg--150 {
    margin-top: 150px;
  }
}

.pl--5 {
  padding-left: 5px !important;
}

/*-- Padding Right --*/
.pr--5 {
  padding-right: 5px;
}

.pr--10 {
  padding-right: 10px;
}

.pr--15 {
  padding-right: 15px;
}

.pr--20 {
  padding-right: 20px;
}

.pr--25 {
  padding-right: 25px;
}

.pr--30 {
  padding-right: 30px;
}

.pr--35 {
  padding-right: 35px;
}

.pr--40 {
  padding-right: 40px;
}

.pr--45 {
  padding-right: 45px;
}

.pr--50 {
  padding-right: 50px;
}

.pr--55 {
  padding-right: 55px;
}

.pr--60 {
  padding-right: 60px;
}

.pr--65 {
  padding-right: 65px;
}

.pr--70 {
  padding-right: 70px;
}

.pr--75 {
  padding-right: 75px;
}

.pr--80 {
  padding-right: 80px;
}

.pr--85 {
  padding-right: 85px;
}

.pr--90 {
  padding-right: 90px;
}

.pr--95 {
  padding-right: 95px;
}

.pr--100 {
  padding-right: 100px;
}

.pr--105 {
  padding-right: 105px;
}

.pr--110 {
  padding-right: 110px;
}

.pr--115 {
  padding-right: 115px;
}

.pr--120 {
  padding-right: 120px;
}

.pr--125 {
  padding-right: 125px;
}

.pr--130 {
  padding-right: 130px;
}

.pr--135 {
  padding-right: 135px;
}

.pr--140 {
  padding-right: 140px;
}

.pr--145 {
  padding-right: 145px;
}

.pr--150 {
  padding-right: 150px;
}

/*-- Margin Top --*/
.mt--5 {
  margin-top: 5px;
}

.mt--10 {
  margin-top: 10px;
}

.mt--15 {
  margin-top: 15px;
}

.mt--20 {
  margin-top: 20px;
}

.mt--25 {
  margin-top: 25px;
}

.mt--30 {
  margin-top: 30px;
}

.mt--35 {
  margin-top: 35px;
}

.mt--40 {
  margin-top: 40px;
}

.mt--45 {
  margin-top: 45px;
}

.mt--50 {
  margin-top: 50px;
}

.mt--55 {
  margin-top: 55px;
}

.mt--60 {
  margin-top: 60px;
}

.mt--65 {
  margin-top: 65px;
}

.mt--70 {
  margin-top: 70px;
}

.mt--75 {
  margin-top: 75px;
}

.mt--80 {
  margin-top: 80px;
}

.mt--85 {
  margin-top: 85px;
}

.mt--90 {
  margin-top: 90px;
}

.mt--95 {
  margin-top: 95px;
}

.mt--100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .md-mt--30 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .sm-mt--30 {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .lr-mt--30 {
    margin-top: 30px;
  }
}

/*-- Margin Bottom --*/
.mb--5 {
  margin-bottom: 5px;
}

.mb--10 {
  margin-bottom: 10px;
}

.mb--15 {
  margin-bottom: 15px;
}

.mb--20 {
  margin-bottom: 20px;
}

.mb--25 {
  margin-bottom: 25px;
}

.mb--30 {
  margin-bottom: 30px;
}

.mb--35 {
  margin-bottom: 35px;
}

.mb--40 {
  margin-bottom: 40px;
}

.mb--45 {
  margin-bottom: 45px;
}

.mb--50 {
  margin-bottom: 50px;
}

.mb--55 {
  margin-bottom: 55px;
}

.mb--60 {
  margin-bottom: 60px;
}

.mb--65 {
  margin-bottom: 65px;
}

.mb--70 {
  margin-bottom: 70px;
}

.mb--75 {
  margin-bottom: 75px;
}

.mb--80 {
  margin-bottom: 80px;
}

.mb--85 {
  margin-bottom: 85px;
}

.mb--90 {
  margin-bottom: 90px;
}

.mb--95 {
  margin-bottom: 95px;
}

.mb--100 {
  margin-bottom: 100px;
}

/*-- Margin Bottom --*/
.ptb--5 {
  padding: 5px 0;
}

.ptb--10 {
  padding: 10px 0;
}

.ptb--15 {
  padding: 15px 0;
}

.ptb--20 {
  padding: 20px 0;
}

.ptb--25 {
  padding: 25px 0;
}

.ptb--30 {
  padding: 30px 0;
}

.ptb--35 {
  padding: 35px 0;
}

.ptb--40 {
  padding: 40px 0;
}

.ptb--45 {
  padding: 45px 0;
}

.ptb--50 {
  padding: 50px 0;
}

.ptb--55 {
  padding: 55px 0;
}

.ptb--60 {
  padding: 60px 0;
}

.ptb--65 {
  padding: 65px 0;
}

.ptb--70 {
  padding: 70px 0;
}

.ptb--75 {
  padding: 75px 0;
}

.ptb--80 {
  padding: 80px 0;
}

.ptb--85 {
  padding: 85px 0;
}

.ptb--90 {
  padding: 90px 0;
}

.ptb--95 {
  padding: 95px 0;
}

.ptb--100 {
  padding: 100px 0;
}

/*-- Margin Bottom --*/
@media (min-width: 768px) {
  .pct-md-5 {
    padding: 5px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-10 {
    padding: 10px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-15 {
    padding: 15px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-20 {
    padding: 20px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-25 {
    padding: 25px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-30 {
    padding: 30px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-35 {
    padding: 35px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-40 {
    padding: 40px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-45 {
    padding: 45px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-50 {
    padding: 50px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-55 {
    padding: 55px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-60 {
    padding: 60px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-65 {
    padding: 65px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-70 {
    padding: 70px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-75 {
    padding: 75px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-80 {
    padding: 80px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-85 {
    padding: 85px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-90 {
    padding: 90px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-95 {
    padding: 95px 0;
  }
}

@media (min-width: 768px) {
  .pct-md-100 {
    padding: 100px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-5 {
    padding: 5px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-10 {
    padding: 10px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-15 {
    padding: 15px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-20 {
    padding: 20px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-25 {
    padding: 25px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-30 {
    padding: 30px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-35 {
    padding: 35px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-40 {
    padding: 40px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-45 {
    padding: 45px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-50 {
    padding: 50px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-55 {
    padding: 55px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-60 {
    padding: 60px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-65 {
    padding: 65px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-70 {
    padding: 70px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-75 {
    padding: 75px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-80 {
    padding: 80px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-85 {
    padding: 85px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-90 {
    padding: 90px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-95 {
    padding: 95px 0;
  }
}

@media (min-width: 992px) {
  .pct-lg-100 {
    padding: 100px 0;
  }
}

/* Paddings */
.mb-30 {
  margin-bottom: 30px;
}

.section-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .section-padding {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

/* No gutters */
@media (min-width: 992px) {
  .no-gutters-lg > .col,
  .no-gutters-lg > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .no-gutters-lg > .col,
  .no-gutters-lg > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1500px) {
  .no-gutters-lg > .col,
  .no-gutters-lg > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

/*Padding Top and Bottom*/
.ptb--0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ptb--5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.ptb--10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.ptb--15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.ptb--20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.ptb--25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.ptb--30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.ptb--35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.ptb--40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.ptb--45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.ptb--50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.ptb--55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.ptb--60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.ptb--65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.ptb--70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.ptb--75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.ptb--80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.ptb--85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.ptb--90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.ptb--95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.ptb--100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.ptb--105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.ptb--110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.ptb--115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.ptb--120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.ptb--125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.ptb--130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.ptb--135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.ptb--140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.ptb--145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.ptb--150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.ptb--155 {
  padding-top: 155px !important;
  padding-bottom: 155px !important;
}

.ptb--160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

.ptb--165 {
  padding-top: 165px !important;
  padding-bottom: 165px !important;
}

.ptb--170 {
  padding-top: 170px !important;
  padding-bottom: 170px !important;
}

.ptb--175 {
  padding-top: 175px !important;
  padding-bottom: 175px !important;
}

.ptb--180 {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}

.ptb--185 {
  padding-top: 185px !important;
  padding-bottom: 185px !important;
}

.ptb--190 {
  padding-top: 190px !important;
  padding-bottom: 190px !important;
}

.ptb--195 {
  padding-top: 195px !important;
  padding-bottom: 195px !important;
}

.ptb--200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.ptb--205 {
  padding-top: 205px !important;
  padding-bottom: 205px !important;
}

.ptb--210 {
  padding-top: 210px !important;
  padding-bottom: 210px !important;
}

.ptb--215 {
  padding-top: 215px !important;
  padding-bottom: 215px !important;
}

.ptb--220 {
  padding-top: 220px !important;
  padding-bottom: 220px !important;
}

.ptb--225 {
  padding-top: 225px !important;
  padding-bottom: 225px !important;
}

.ptb--230 {
  padding-top: 230px !important;
  padding-bottom: 230px !important;
}

.ptb--235 {
  padding-top: 235px !important;
  padding-bottom: 235px !important;
}

.ptb--240 {
  padding-top: 240px !important;
  padding-bottom: 240px !important;
}

.ptb--245 {
  padding-top: 245px !important;
  padding-bottom: 245px !important;
}

.ptb--250 {
  padding-top: 250px !important;
  padding-bottom: 250px !important;
}

.ptb--255 {
  padding-top: 255px !important;
  padding-bottom: 255px !important;
}

.ptb--260 {
  padding-top: 260px !important;
  padding-bottom: 260px !important;
}

.ptb--265 {
  padding-top: 265px !important;
  padding-bottom: 265px !important;
}

.ptb--270 {
  padding-top: 270px !important;
  padding-bottom: 270px !important;
}

.ptb--275 {
  padding-top: 275px !important;
  padding-bottom: 275px !important;
}

.ptb--280 {
  padding-top: 280px !important;
  padding-bottom: 280px !important;
}

.ptb--285 {
  padding-top: 285px !important;
  padding-bottom: 285px !important;
}

.ptb--290 {
  padding-top: 290px !important;
  padding-bottom: 290px !important;
}

.ptb--295 {
  padding-top: 295px !important;
  padding-bottom: 295px !important;
}

.ptb--300 {
  padding-top: 300px !important;
  padding-bottom: 300px !important;
}

.pb--0 {
  padding-bottom: 0px !important;
}

.pb--5 {
  padding-bottom: 5px !important;
}

.pb--10 {
  padding-bottom: 10px !important;
}

.pb--15 {
  padding-bottom: 15px !important;
}

.pb--20 {
  padding-bottom: 20px !important;
}

.pb--25 {
  padding-bottom: 25px !important;
}

.pb--30 {
  padding-bottom: 30px !important;
}

.pb--35 {
  padding-bottom: 35px !important;
}

.pb--40 {
  padding-bottom: 40px !important;
}

.pb--45 {
  padding-bottom: 45px !important;
}

.pb--50 {
  padding-bottom: 50px !important;
}

.pb--55 {
  padding-bottom: 55px !important;
}

.pb--60 {
  padding-bottom: 60px !important;
}

.pb--65 {
  padding-bottom: 65px !important;
}

.pb--70 {
  padding-bottom: 70px !important;
}

.pb--75 {
  padding-bottom: 75px !important;
}

.pb--80 {
  padding-bottom: 80px !important;
}

.pb--85 {
  padding-bottom: 85px !important;
}

.pb--90 {
  padding-bottom: 90px !important;
}

.pb--95 {
  padding-bottom: 95px !important;
}

.pb--100 {
  padding-bottom: 100px !important;
}

.pb--105 {
  padding-bottom: 105px !important;
}

.pb--110 {
  padding-bottom: 110px !important;
}

.pb--115 {
  padding-bottom: 115px !important;
}

.pb--120 {
  padding-bottom: 120px !important;
}

.pb--125 {
  padding-bottom: 125px !important;
}

.pb--130 {
  padding-bottom: 130px !important;
}

.pb--135 {
  padding-bottom: 135px !important;
}

.pb--140 {
  padding-bottom: 140px !important;
}

.pb--145 {
  padding-bottom: 145px !important;
}

.pb--150 {
  padding-bottom: 150px !important;
}

.pb--155 {
  padding-bottom: 155px !important;
}

.pb--160 {
  padding-bottom: 160px !important;
}

.pb--165 {
  padding-bottom: 165px !important;
}

.pb--170 {
  padding-bottom: 170px !important;
}

.pb--175 {
  padding-bottom: 175px !important;
}

.pb--180 {
  padding-bottom: 180px !important;
}

.pb--185 {
  padding-bottom: 185px !important;
}

.pb--190 {
  padding-bottom: 190px !important;
}

.pb--195 {
  padding-bottom: 195px !important;
}

.pb--200 {
  padding-bottom: 200px !important;
}

.pb--205 {
  padding-bottom: 205px !important;
}

.pb--210 {
  padding-bottom: 210px !important;
}

.pb--215 {
  padding-bottom: 215px !important;
}

.pb--220 {
  padding-bottom: 220px !important;
}

.pb--225 {
  padding-bottom: 225px !important;
}

.pb--230 {
  padding-bottom: 230px !important;
}

.pb--235 {
  padding-bottom: 235px !important;
}

.pb--240 {
  padding-bottom: 240px !important;
}

.pb--245 {
  padding-bottom: 245px !important;
}

.pb--250 {
  padding-bottom: 250px !important;
}

.pb--255 {
  padding-bottom: 255px !important;
}

.pb--260 {
  padding-bottom: 260px !important;
}

.pb--265 {
  padding-bottom: 265px !important;
}

.pb--270 {
  padding-bottom: 270px !important;
}

.pb--275 {
  padding-bottom: 275px !important;
}

.pb--280 {
  padding-bottom: 280px !important;
}

.pb--285 {
  padding-bottom: 285px !important;
}

.pb--290 {
  padding-bottom: 290px !important;
}

.pb--295 {
  padding-bottom: 295px !important;
}

.pb--300 {
  padding-bottom: 300px !important;
}

.pt--0 {
  padding-top: 0px !important;
}

.pt--5 {
  padding-top: 5px !important;
}

.pt--10 {
  padding-top: 10px !important;
}

.pt--15 {
  padding-top: 15px !important;
}

.pt--20 {
  padding-top: 20px !important;
}

.pt--25 {
  padding-top: 25px !important;
}

.pt--30 {
  padding-top: 30px !important;
}

.pt--35 {
  padding-top: 35px !important;
}

.pt--40 {
  padding-top: 40px !important;
}

.pt--45 {
  padding-top: 45px !important;
}

.pt--50 {
  padding-top: 50px !important;
}

.pt--55 {
  padding-top: 55px !important;
}

.pt--60 {
  padding-top: 60px !important;
}

.pt--65 {
  padding-top: 65px !important;
}

.pt--70 {
  padding-top: 70px !important;
}

.pt--75 {
  padding-top: 75px !important;
}

.pt--80 {
  padding-top: 80px !important;
}

.pt--85 {
  padding-top: 85px !important;
}

.pt--90 {
  padding-top: 90px !important;
}

.pt--95 {
  padding-top: 95px !important;
}

.pt--100 {
  padding-top: 100px !important;
}

.pt--105 {
  padding-top: 105px !important;
}

.pt--110 {
  padding-top: 110px !important;
}

.pt--115 {
  padding-top: 115px !important;
}

.pt--120 {
  padding-top: 120px !important;
}

.pt--125 {
  padding-top: 125px !important;
}

.pt--130 {
  padding-top: 130px !important;
}

.pt--135 {
  padding-top: 135px !important;
}

.pt--140 {
  padding-top: 140px !important;
}

.pt--145 {
  padding-top: 145px !important;
}

.pt--150 {
  padding-top: 150px !important;
}

.pt--155 {
  padding-top: 155px !important;
}

.pt--160 {
  padding-top: 160px !important;
}

.pt--165 {
  padding-top: 165px !important;
}

.pt--170 {
  padding-top: 170px !important;
}

.pt--175 {
  padding-top: 175px !important;
}

.pt--180 {
  padding-top: 180px !important;
}

.pt--185 {
  padding-top: 185px !important;
}

.pt--190 {
  padding-top: 190px !important;
}

.pt--195 {
  padding-top: 195px !important;
}

.pt--200 {
  padding-top: 200px !important;
}

.pt--205 {
  padding-top: 205px !important;
}

.pt--210 {
  padding-top: 210px !important;
}

.pt--215 {
  padding-top: 215px !important;
}

.pt--220 {
  padding-top: 220px !important;
}

.pt--225 {
  padding-top: 225px !important;
}

.pt--230 {
  padding-top: 230px !important;
}

.pt--235 {
  padding-top: 235px !important;
}

.pt--240 {
  padding-top: 240px !important;
}

.pt--245 {
  padding-top: 245px !important;
}

.pt--250 {
  padding-top: 250px !important;
}

.pt--255 {
  padding-top: 255px !important;
}

.pt--260 {
  padding-top: 260px !important;
}

.pt--265 {
  padding-top: 265px !important;
}

.pt--270 {
  padding-top: 270px !important;
}

.pt--275 {
  padding-top: 275px !important;
}

.pt--280 {
  padding-top: 280px !important;
}

.pt--285 {
  padding-top: 285px !important;
}

.pt--290 {
  padding-top: 290px !important;
}

.pt--295 {
  padding-top: 295px !important;
}

.pt--300 {
  padding-top: 300px !important;
}

.pr--0 {
  padding-right: 0px !important;
}

.pr--5 {
  padding-right: 5px !important;
}

.pr--10 {
  padding-right: 10px !important;
}

.pr--15 {
  padding-right: 15px !important;
}

.pr--20 {
  padding-right: 20px !important;
}

.pr--25 {
  padding-right: 25px !important;
}

.pr--30 {
  padding-right: 30px !important;
}

.pr--35 {
  padding-right: 35px !important;
}

.pr--40 {
  padding-right: 40px !important;
}

.pr--45 {
  padding-right: 45px !important;
}

.pr--50 {
  padding-right: 50px !important;
}

.pr--55 {
  padding-right: 55px !important;
}

.pr--60 {
  padding-right: 60px !important;
}

.pr--65 {
  padding-right: 65px !important;
}

.pr--70 {
  padding-right: 70px !important;
}

.pr--75 {
  padding-right: 75px !important;
}

.pr--80 {
  padding-right: 80px !important;
}

.pr--85 {
  padding-right: 85px !important;
}

.pr--90 {
  padding-right: 90px !important;
}

.pr--95 {
  padding-right: 95px !important;
}

.pr--100 {
  padding-right: 100px !important;
}

.pr--105 {
  padding-right: 105px !important;
}

.pr--110 {
  padding-right: 110px !important;
}

.pr--115 {
  padding-right: 115px !important;
}

.pr--120 {
  padding-right: 120px !important;
}

.pr--125 {
  padding-right: 125px !important;
}

.pr--130 {
  padding-right: 130px !important;
}

.pr--135 {
  padding-right: 135px !important;
}

.pr--140 {
  padding-right: 140px !important;
}

.pr--145 {
  padding-right: 145px !important;
}

.pr--150 {
  padding-right: 150px !important;
}

.pr--155 {
  padding-right: 155px !important;
}

.pr--160 {
  padding-right: 160px !important;
}

.pr--165 {
  padding-right: 165px !important;
}

.pr--170 {
  padding-right: 170px !important;
}

.pr--175 {
  padding-right: 175px !important;
}

.pr--180 {
  padding-right: 180px !important;
}

.pr--185 {
  padding-right: 185px !important;
}

.pr--190 {
  padding-right: 190px !important;
}

.pr--195 {
  padding-right: 195px !important;
}

.pr--200 {
  padding-right: 200px !important;
}

.pl--0 {
  padding-left: 0px !important;
}

.pl--5 {
  padding-left: 5px !important;
}

.pl--10 {
  padding-left: 10px !important;
}

.pl--15 {
  padding-left: 15px !important;
}

.pl--20 {
  padding-left: 20px !important;
}

.pl--25 {
  padding-left: 25px !important;
}

.pl--30 {
  padding-left: 30px !important;
}

.pl--35 {
  padding-left: 35px !important;
}

.pl--40 {
  padding-left: 40px !important;
}

.pl--45 {
  padding-left: 45px !important;
}

.pl--50 {
  padding-left: 50px !important;
}

.pl--55 {
  padding-left: 55px !important;
}

.pl--60 {
  padding-left: 60px !important;
}

.pl--65 {
  padding-left: 65px !important;
}

.pl--70 {
  padding-left: 70px !important;
}

.pl--75 {
  padding-left: 75px !important;
}

.pl--80 {
  padding-left: 80px !important;
}

.pl--85 {
  padding-left: 85px !important;
}

.pl--90 {
  padding-left: 90px !important;
}

.pl--95 {
  padding-left: 95px !important;
}

.pl--100 {
  padding-left: 100px !important;
}

.pl--105 {
  padding-left: 105px !important;
}

.pl--110 {
  padding-left: 110px !important;
}

.pl--115 {
  padding-left: 115px !important;
}

.pl--120 {
  padding-left: 120px !important;
}

.pl--125 {
  padding-left: 125px !important;
}

.pl--130 {
  padding-left: 130px !important;
}

.pl--135 {
  padding-left: 135px !important;
}

.pl--140 {
  padding-left: 140px !important;
}

.pl--145 {
  padding-left: 145px !important;
}

.pl--150 {
  padding-left: 150px !important;
}

.pl--155 {
  padding-left: 155px !important;
}

.pl--160 {
  padding-left: 160px !important;
}

.pl--165 {
  padding-left: 165px !important;
}

.pl--170 {
  padding-left: 170px !important;
}

.pl--175 {
  padding-left: 175px !important;
}

.pl--180 {
  padding-left: 180px !important;
}

.pl--185 {
  padding-left: 185px !important;
}

.pl--190 {
  padding-left: 190px !important;
}

.pl--195 {
  padding-left: 195px !important;
}

.pl--200 {
  padding-left: 200px !important;
}

.plr--0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.plr--5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.plr--10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.plr--15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.plr--20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.plr--25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.plr--30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.plr--35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.plr--40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.plr--45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.plr--50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.plr--55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.plr--60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.plr--65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.plr--70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.plr--75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.plr--80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.plr--85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.plr--90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.plr--95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.plr--100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.plr--105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

.plr--110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.plr--115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.plr--120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.plr--125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}

.plr--130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.plr--135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}

.plr--140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.plr--145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}

.plr--150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.plr--155 {
  padding-left: 155px !important;
  padding-right: 155px !important;
}

.plr--160 {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

.plr--165 {
  padding-left: 165px !important;
  padding-right: 165px !important;
}

.plr--170 {
  padding-left: 170px !important;
  padding-right: 170px !important;
}

.plr--175 {
  padding-left: 175px !important;
  padding-right: 175px !important;
}

.plr--180 {
  padding-left: 180px !important;
  padding-right: 180px !important;
}

.plr--185 {
  padding-left: 185px !important;
  padding-right: 185px !important;
}

.plr--190 {
  padding-left: 190px !important;
  padding-right: 190px !important;
}

.plr--195 {
  padding-left: 195px !important;
  padding-right: 195px !important;
}

.plr--200 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.mtb--0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mtb--5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mtb--10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mtb--15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mtb--20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mtb--25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mtb--30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mtb--35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mtb--40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mtb--45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mtb--50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mtb--55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.mtb--60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mtb--65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.mtb--70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mtb--75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mtb--80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mtb--85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.mtb--90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mtb--95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.mtb--100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mtb--105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.mtb--110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.mtb--115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.mtb--120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.mtb--125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important;
}

.mtb--130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important;
}

.mtb--135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important;
}

.mtb--140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.mtb--145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important;
}

.mtb--150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.mtb--155 {
  margin-top: 155px !important;
  margin-bottom: 155px !important;
}

.mtb--160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

.mtb--165 {
  margin-top: 165px !important;
  margin-bottom: 165px !important;
}

.mtb--170 {
  margin-top: 170px !important;
  margin-bottom: 170px !important;
}

.mtb--175 {
  margin-top: 175px !important;
  margin-bottom: 175px !important;
}

.mtb--180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important;
}

.mtb--185 {
  margin-top: 185px !important;
  margin-bottom: 185px !important;
}

.mtb--190 {
  margin-top: 190px !important;
  margin-bottom: 190px !important;
}

.mtb--195 {
  margin-top: 195px !important;
  margin-bottom: 195px !important;
}

.mtb--200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}

.mb--0 {
  margin-bottom: 0px !important;
}

.mb--5 {
  margin-bottom: 5px !important;
}

.mb--10 {
  margin-bottom: 10px !important;
}

.mb--15 {
  margin-bottom: 15px !important;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.mb--25 {
  margin-bottom: 25px !important;
}

.mb--30 {
  margin-bottom: 30px !important;
}

.mb--35 {
  margin-bottom: 35px !important;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.mb--45 {
  margin-bottom: 45px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mb--55 {
  margin-bottom: 55px !important;
}

.mb--60 {
  margin-bottom: 60px !important;
}

.mb--65 {
  margin-bottom: 65px !important;
}

.mb--70 {
  margin-bottom: 70px !important;
}

.mb--75 {
  margin-bottom: 75px !important;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.mb--85 {
  margin-bottom: 85px !important;
}

.mb--90 {
  margin-bottom: 90px !important;
}

.mb--95 {
  margin-bottom: 95px !important;
}

.mb--100 {
  margin-bottom: 100px !important;
}

.mb--105 {
  margin-bottom: 105px !important;
}

.mb--110 {
  margin-bottom: 110px !important;
}

.mb--115 {
  margin-bottom: 115px !important;
}

.mb--120 {
  margin-bottom: 120px !important;
}

.mb--125 {
  margin-bottom: 125px !important;
}

.mb--130 {
  margin-bottom: 130px !important;
}

.mb--135 {
  margin-bottom: 135px !important;
}

.mb--140 {
  margin-bottom: 140px !important;
}

.mb--145 {
  margin-bottom: 145px !important;
}

.mb--150 {
  margin-bottom: 150px !important;
}

.mb--155 {
  margin-bottom: 155px !important;
}

.mb--160 {
  margin-bottom: 160px !important;
}

.mb--165 {
  margin-bottom: 165px !important;
}

.mb--170 {
  margin-bottom: 170px !important;
}

.mb--175 {
  margin-bottom: 175px !important;
}

.mb--180 {
  margin-bottom: 180px !important;
}

.mb--185 {
  margin-bottom: 185px !important;
}

.mb--190 {
  margin-bottom: 190px !important;
}

.mb--195 {
  margin-bottom: 195px !important;
}

.mb--200 {
  margin-bottom: 200px !important;
}

.mt--0 {
  margin-top: 0px !important;
}

.mt--5 {
  margin-top: 5px !important;
}

.mt--10 {
  margin-top: 10px !important;
}

.mt--15 {
  margin-top: 15px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.mt--25 {
  margin-top: 25px !important;
}

.mt--30 {
  margin-top: 30px !important;
}

.mt--35 {
  margin-top: 35px !important;
}

.mt--40 {
  margin-top: 40px !important;
}

.mt--45 {
  margin-top: 45px !important;
}

.mt--50 {
  margin-top: 50px !important;
}

.mt--55 {
  margin-top: 55px !important;
}

.mt--60 {
  margin-top: 60px !important;
}

.mt--65 {
  margin-top: 65px !important;
}

.mt--70 {
  margin-top: 70px !important;
}

.mt--75 {
  margin-top: 75px !important;
}

.mt--80 {
  margin-top: 80px !important;
}

.mt--85 {
  margin-top: 85px !important;
}

.mt--90 {
  margin-top: 90px !important;
}

.mt--95 {
  margin-top: 95px !important;
}

.mt--100 {
  margin-top: 100px !important;
}

.mt--105 {
  margin-top: 105px !important;
}

.mt--110 {
  margin-top: 110px !important;
}

.mt--115 {
  margin-top: 115px !important;
}

.mt--120 {
  margin-top: 120px !important;
}

.mt--125 {
  margin-top: 125px !important;
}

.mt--130 {
  margin-top: 130px !important;
}

.mt--135 {
  margin-top: 135px !important;
}

.mt--140 {
  margin-top: 140px !important;
}

.mt--145 {
  margin-top: 145px !important;
}

.mt--150 {
  margin-top: 150px !important;
}

.mt--155 {
  margin-top: 155px !important;
}

.mt--160 {
  margin-top: 160px !important;
}

.mt--165 {
  margin-top: 165px !important;
}

.mt--170 {
  margin-top: 170px !important;
}

.mt--175 {
  margin-top: 175px !important;
}

.mt--180 {
  margin-top: 180px !important;
}

.mt--185 {
  margin-top: 185px !important;
}

.mt--190 {
  margin-top: 190px !important;
}

.mt--195 {
  margin-top: 195px !important;
}

.mt--200 {
  margin-top: 200px !important;
}

@media (min-width: 992px) {
  .mt-lg--0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--5 {
    margin-top: 5px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--10 {
    margin-top: 10px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--15 {
    margin-top: 15px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--20 {
    margin-top: 20px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--25 {
    margin-top: 25px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--30 {
    margin-top: 30px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--35 {
    margin-top: 35px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--40 {
    margin-top: 40px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--45 {
    margin-top: 45px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--50 {
    margin-top: 50px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--55 {
    margin-top: 55px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--60 {
    margin-top: 60px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--65 {
    margin-top: 65px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--70 {
    margin-top: 70px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--75 {
    margin-top: 75px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--80 {
    margin-top: 80px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--85 {
    margin-top: 85px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--95 {
    margin-top: 95px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--100 {
    margin-top: 100px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--105 {
    margin-top: 105px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--110 {
    margin-top: 110px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--115 {
    margin-top: 115px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--125 {
    margin-top: 125px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--130 {
    margin-top: 130px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--135 {
    margin-top: 135px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--140 {
    margin-top: 140px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--145 {
    margin-top: 145px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--150 {
    margin-top: 150px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--155 {
    margin-top: 155px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--160 {
    margin-top: 160px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--165 {
    margin-top: 165px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--170 {
    margin-top: 170px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--175 {
    margin-top: 175px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--180 {
    margin-top: 180px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--185 {
    margin-top: 185px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--190 {
    margin-top: 190px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--195 {
    margin-top: 195px !important;
  }
}

@media (min-width: 992px) {
  .mt-lg--200 {
    margin-top: 200px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--5 {
    margin-top: 5px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--10 {
    margin-top: 10px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--15 {
    margin-top: 15px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--20 {
    margin-top: 20px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--25 {
    margin-top: 25px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--30 {
    margin-top: 30px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--35 {
    margin-top: 35px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--40 {
    margin-top: 40px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--45 {
    margin-top: 45px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--50 {
    margin-top: 50px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--55 {
    margin-top: 55px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--60 {
    margin-top: 60px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--65 {
    margin-top: 65px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--70 {
    margin-top: 70px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--75 {
    margin-top: 75px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--80 {
    margin-top: 80px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--85 {
    margin-top: 85px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--95 {
    margin-top: 95px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--100 {
    margin-top: 100px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--105 {
    margin-top: 105px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--110 {
    margin-top: 110px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--115 {
    margin-top: 115px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--125 {
    margin-top: 125px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--130 {
    margin-top: 130px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--135 {
    margin-top: 135px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--140 {
    margin-top: 140px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--145 {
    margin-top: 145px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--150 {
    margin-top: 150px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--155 {
    margin-top: 155px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--160 {
    margin-top: 160px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--165 {
    margin-top: 165px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--170 {
    margin-top: 170px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--175 {
    margin-top: 175px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--180 {
    margin-top: 180px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--185 {
    margin-top: 185px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--190 {
    margin-top: 190px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--195 {
    margin-top: 195px !important;
  }
}

@media (min-width: 768px) {
  .mt-md--200 {
    margin-top: 200px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--5 {
    margin-top: 5px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--10 {
    margin-top: 10px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--15 {
    margin-top: 15px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--20 {
    margin-top: 20px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--25 {
    margin-top: 25px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--30 {
    margin-top: 30px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--35 {
    margin-top: 35px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--40 {
    margin-top: 40px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--45 {
    margin-top: 45px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--50 {
    margin-top: 50px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--55 {
    margin-top: 55px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--60 {
    margin-top: 60px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--65 {
    margin-top: 65px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--70 {
    margin-top: 70px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--75 {
    margin-top: 75px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--80 {
    margin-top: 80px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--85 {
    margin-top: 85px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--95 {
    margin-top: 95px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--100 {
    margin-top: 100px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--105 {
    margin-top: 105px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--110 {
    margin-top: 110px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--115 {
    margin-top: 115px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--125 {
    margin-top: 125px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--130 {
    margin-top: 130px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--135 {
    margin-top: 135px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--140 {
    margin-top: 140px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--145 {
    margin-top: 145px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--150 {
    margin-top: 150px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--155 {
    margin-top: 155px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--160 {
    margin-top: 160px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--165 {
    margin-top: 165px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--170 {
    margin-top: 170px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--175 {
    margin-top: 175px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--180 {
    margin-top: 180px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--185 {
    margin-top: 185px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--190 {
    margin-top: 190px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--195 {
    margin-top: 195px !important;
  }
}

@media (min-width: 480px) {
  .mt-sm--200 {
    margin-top: 200px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--0 {
    margin-top: 0px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--5 {
    margin-top: 5px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--10 {
    margin-top: 10px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--15 {
    margin-top: 15px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--20 {
    margin-top: 20px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--25 {
    margin-top: 25px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--30 {
    margin-top: 30px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--35 {
    margin-top: 35px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--40 {
    margin-top: 40px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--45 {
    margin-top: 45px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--50 {
    margin-top: 50px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--55 {
    margin-top: 55px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--60 {
    margin-top: 60px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--65 {
    margin-top: 65px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--70 {
    margin-top: 70px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--75 {
    margin-top: 75px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--80 {
    margin-top: 80px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--85 {
    margin-top: 85px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--95 {
    margin-top: 95px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--100 {
    margin-top: 100px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--105 {
    margin-top: 105px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--110 {
    margin-top: 110px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--115 {
    margin-top: 115px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--120 {
    margin-top: 120px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--125 {
    margin-top: 125px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--130 {
    margin-top: 130px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--135 {
    margin-top: 135px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--140 {
    margin-top: 140px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--145 {
    margin-top: 145px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--150 {
    margin-top: 150px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--155 {
    margin-top: 155px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--160 {
    margin-top: 160px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--165 {
    margin-top: 165px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--170 {
    margin-top: 170px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--175 {
    margin-top: 175px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--180 {
    margin-top: 180px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--185 {
    margin-top: 185px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--190 {
    margin-top: 190px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--195 {
    margin-top: 195px !important;
  }
}

@media (min-width: 320px) {
  .mt-xs--200 {
    margin-top: 200px !important;
  }
}

.ml--0 {
  margin-left: 0px !important;
}

.ml--5 {
  margin-left: 5px !important;
}

.ml--10 {
  margin-left: 10px !important;
}

.ml--15 {
  margin-left: 15px !important;
}

.ml--20 {
  margin-left: 20px !important;
}

.ml--25 {
  margin-left: 25px !important;
}

.ml--30 {
  margin-left: 30px !important;
}

.ml--35 {
  margin-left: 35px !important;
}

.ml--40 {
  margin-left: 40px !important;
}

.ml--45 {
  margin-left: 45px !important;
}

.ml--50 {
  margin-left: 50px !important;
}

.ml--55 {
  margin-left: 55px !important;
}

.ml--60 {
  margin-left: 60px !important;
}

.ml--65 {
  margin-left: 65px !important;
}

.ml--70 {
  margin-left: 70px !important;
}

.ml--75 {
  margin-left: 75px !important;
}

.ml--80 {
  margin-left: 80px !important;
}

.ml--85 {
  margin-left: 85px !important;
}

.ml--90 {
  margin-left: 90px !important;
}

.ml--95 {
  margin-left: 95px !important;
}

.ml--100 {
  margin-left: 100px !important;
}

.mr--0 {
  margin-right: 0px !important;
}

.mr--10 {
  margin-right: 10px !important;
}

.mr--20 {
  margin-right: 20px !important;
}

.mr--30 {
  margin-right: 30px !important;
}

.mr--40 {
  margin-right: 40px !important;
}

.mr--50 {
  margin-right: 50px !important;
}

.mr--60 {
  margin-right: 60px !important;
}

.mr--70 {
  margin-right: 70px !important;
}

.mr--80 {
  margin-right: 80px !important;
}

.mr--90 {
  margin-right: 90px !important;
}

.mr--100 {
  margin-right: 100px !important;
}

@media (min-width: 992px) {
  .mr-lg--0 {
    margin-right: 0px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--5 {
    margin-right: 5px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--10 {
    margin-right: 10px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--15 {
    margin-right: 15px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--20 {
    margin-right: 20px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--25 {
    margin-right: 25px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--30 {
    margin-right: 30px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--35 {
    margin-right: 35px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--40 {
    margin-right: 40px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--45 {
    margin-right: 45px !important;
  }
}

@media (min-width: 992px) {
  .mr-lg--50 {
    margin-right: 50px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--0 {
    margin-right: 0px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--5 {
    margin-right: 5px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--10 {
    margin-right: 10px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--15 {
    margin-right: 15px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--20 {
    margin-right: 20px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--25 {
    margin-right: 25px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--30 {
    margin-right: 30px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--35 {
    margin-right: 35px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--40 {
    margin-right: 40px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--45 {
    margin-right: 45px !important;
  }
}

@media (min-width: 768px) {
  .mr-md--50 {
    margin-right: 50px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--0 {
    margin-right: 0px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--5 {
    margin-right: 5px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--10 {
    margin-right: 10px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--15 {
    margin-right: 15px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--20 {
    margin-right: 20px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--25 {
    margin-right: 25px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--30 {
    margin-right: 30px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--35 {
    margin-right: 35px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--40 {
    margin-right: 40px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--45 {
    margin-right: 45px !important;
  }
}

@media (min-width: 480px) {
  .mr-sm--50 {
    margin-right: 50px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--0 {
    margin-right: 0px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--5 {
    margin-right: 5px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--10 {
    margin-right: 10px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--15 {
    margin-right: 15px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--20 {
    margin-right: 20px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--25 {
    margin-right: 25px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--30 {
    margin-right: 30px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--35 {
    margin-right: 35px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--40 {
    margin-right: 40px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--45 {
    margin-right: 45px !important;
  }
}

@media (min-width: 320px) {
  .mr-xs--50 {
    margin-right: 50px !important;
  }
}

.sp-inner-page {
  padding: 60px 0;
}

@media (min-width: 992px) {
  .sp-inner-page {
    padding: 90px 0;
  }
}

.sp-inner-page--top {
  padding-top: 40px;
}

@media (min-width: 768px) {
  .sp-inner-page--top {
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .sp-inner-page--top {
    padding-top: 90px;
  }
}

.sp-inner-page--bottom {
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .sp-inner-page--bottom {
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .sp-inner-page--bottom {
    padding-bottom: 90px;
  }
}

.left-slide-margin {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .left-slide-margin {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .pt-lg-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
}

/*Sliders*/
.petmark-slick-slider.grid-column-slider .slick-slide.slick-active div + div .single-slide:after {
  height: 0;
}

.petmark-slick-slider.grid-column-slider .slick-slide.slick-current .single-slide:before {
  width: 0;
  opacity: 0;
}

.petmark-slick-slider.grid-column-slider .slick-slide .single-slide {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.petmark-slick-slider.grid-column-slider .slick-slide .single-slide:before {
  content: "";
  width: 1px;
  position: absolute;
  background: #ececec;
  left: 0;
  top: 20px;
  bottom: 5px;
}

.petmark-slick-slider.grid-column-slider .slick-slide .single-slide:after {
  content: "";
  height: 1px;
  position: absolute;
  background: #ececec;
  left: 10px;
  right: 10px;
  bottom: 0px;
}

.petmark-slick-slider .pm-product {
  padding: 0;
}

/* Petmark Products Slider*/
.one-column-slider.two-row .slick-slide > div {
  padding: 13px 0;
}

@media (min-width: 1200px) {
  .one-column-slider.two-row .slick-slide > div {
    padding: 11.5px 0 24.5px;
  }
}

@media (min-width: 480px) {
  .one-column-slider.two-row .product-type-list {
    display: flex;
  }
}

@media (min-width: 480px) {
  .one-column-slider.two-row .product-type-list .image {
    width: 225px;
  }
}

@media (min-width: 992px) {
  .one-column-slider.two-row .product-type-list .image {
    width: 194.5px;
  }
}

@media (min-width: 1200px) {
  .one-column-slider.two-row .product-type-list .image {
    width: 225px;
  }
}

.one-column-slider.two-row .product-type-list .image img {
  width: 100%;
}

.one-column-slider.two-row .product-type-list .content {
  overflow: unset;
  padding-top: 20px;
}

@media (max-width: 479px) {
  .one-column-slider.two-row .product-type-list .content {
    padding-left: 20px;
  }
}

.one-column-slider.two-row .product-type-list .content .btn-block {
  margin-top: 20px;
  position: relative;
  transform: translateY(0);
  height: auto;
}

.one-column-slider.two-row .product-type-list .content:before {
  z-index: -1 !important;
}

.one-column-slider.three-row .slick-slide > div {
  padding: 13px 0;
}

@media (min-width: 1200px) {
  .one-column-slider.three-row .slick-slide > div {
    padding: 11.5px 0 24.5px;
  }
}

@media (min-width: 480px) {
  .one-column-slider.three-row .product-type-list {
    display: flex;
  }
}

@media (min-width: 480px) {
  .one-column-slider.three-row .product-type-list .image {
    width: 225px;
  }
}

@media (min-width: 992px) {
  .one-column-slider.three-row .product-type-list .image {
    width: 194.5px;
  }
}

@media (min-width: 1200px) {
  .one-column-slider.three-row .product-type-list .image {
    width: 40%;
    padding: 0 10px;
  }
}

.one-column-slider.three-row .product-type-list .image img {
  width: 100%;
}

.one-column-slider.three-row .product-type-list .content {
  overflow: unset;
  padding-top: 20px;
}

@media (max-width: 479px) {
  .one-column-slider.three-row .product-type-list .content {
    padding-left: 20px;
  }
}

.one-column-slider.three-row .product-type-list .content:before {
  z-index: -1 !important;
}

.normal-slider .slick-slide {
  padding: 11.5px;
}

@media (min-width: 480px) {
  .normal-slider .product-type-list {
    display: flex;
  }
}

@media (min-width: 480px) {
  .normal-slider .product-type-list .image {
    width: 225px;
  }
}

@media (min-width: 992px) {
  .normal-slider .product-type-list .image {
    width: 145px;
  }
}

@media (min-width: 1200px) {
  .normal-slider .product-type-list .image {
    width: 145px;
  }
}

.normal-slider .product-type-list .image img {
  width: 100%;
}

.normal-slider .product-type-list .content {
  overflow: unset;
  padding-top: 20px;
}

@media (max-width: 479px) {
  .normal-slider .product-type-list .content {
    padding-left: 20px;
  }
}

.normal-slider .product-type-list .content .btn-block {
  margin-top: 20px;
  position: relative;
  transform: translateY(0);
  height: auto;
}

.normal-slider .product-type-list .content:before {
  z-index: -1 !important;
}

.normal-slider.grid-border-none .slick-slide:before {
  opacity: 0;
}

@media (min-width: 480px) {
  .normal-two-column-slider .product-type-list {
    display: flex;
  }
}

@media (min-width: 480px) {
  .normal-two-column-slider .product-type-list .image {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .normal-two-column-slider .product-type-list .image {
    width: 225px;
  }
}

.normal-two-column-slider .product-type-list .image img {
  width: 100%;
}

.normal-two-column-slider .product-type-list .content {
  overflow: unset;
  padding-bottom: 25px;
}

@media (max-width: 479px) {
  .normal-two-column-slider .product-type-list .content {
    padding-left: 20px;
  }
}

@media (min-width: 480px) {
  .normal-two-column-slider .product-type-list .content {
    padding-top: 25px;
    width: 50%;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .normal-two-column-slider .product-type-list .content {
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .normal-two-column-slider .product-type-list .content {
    width: 50%;
  }
}

.normal-two-column-slider .product-type-list .content .btn-block {
  text-align: left;
  margin-top: 20px;
  position: relative;
  transform: translateY(0);
  height: auto;
}

.normal-two-column-slider .product-type-list .content .btn-block a {
  margin-right: auto;
}

.normal-two-column-slider .product-type-list .content:before {
  z-index: -1 !important;
}

.petmark-slick-slider button.slick-arrow {
  position: absolute;
  top: -38px;
  font-size: 0;
  right: 5px;
  height: 30px;
  line-height: 2px;
  width: 30px;
}

.petmark-slick-slider button.slick-arrow:before {
  position: absolute;
  content: '\f3cf';
  font-family: 'Ionicons';
  font-size: 18px;
  color: #696969;
  width: 100%;
  left: 0;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.petmark-slick-slider button.slick-arrow.slick-next {
  right: -15px;
}

.petmark-slick-slider button.slick-arrow.slick-next::before {
  content: "\f3d1";
}

.petmark-slick-slider button.slick-arrow:hover:before {
  color: #56a700;
}

.petmark-slick-slider.arrow-type-two button.slick-arrow {
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  z-index: 99;
  margin-right: -20px;
  right: 100%;
}

@media (min-width: 992px) {
  .petmark-slick-slider.arrow-type-two button.slick-arrow {
    margin-right: 0;
  }
}

.petmark-slick-slider.arrow-type-two button.slick-arrow:before {
  height: 100%;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.petmark-slick-slider.arrow-type-two button.slick-arrow.slick-next {
  right: 10px;
}

@media (min-width: 992px) {
  .petmark-slick-slider.arrow-type-two button.slick-arrow.slick-next {
    margin-right: -30px;
    margin-right: -42px;
  }
}

.petmark-slick-slider--wrapper-2 button.slick-arrow {
  position: absolute;
  top: -61px;
}

.brand-slider {
  padding: 10px 0px;
}

@media (min-width: 768px) {
  .brand-slider {
    padding: 10px 30px;
  }
}

@media (min-width: 992px) {
  .brand-slider {
    padding: 20px 50px;
  }
}

.brand-slider .brand-image img {
  margin: 0 auto;
}

.brand-slider button.slick-arrow {
  position: absolute;
  top: 0;
  font-size: 0;
  left: 0;
  height: 100%;
  width: 14px;
  background: #fff;
  z-index: 2;
}

@media (min-width: 768px) {
  .brand-slider button.slick-arrow {
    width: 35px;
  }
}

.brand-slider button.slick-arrow::before {
  font-size: 23px;
}

@media (min-width: 576px) {
  .brand-slider button.slick-arrow::before {
    font-size: 28px;
  }
}

.brand-slider button.slick-arrow.slick-next {
  left: auto;
  right: 1px;
  margin-right: 0px;
}

.slider-side-block .single-slide-block {
  margin-bottom: 50px;
}

.slider-side-block .single-slide-block:last-child {
  margin-bottom: 0;
}

.slider-main-block .single-main-block {
  margin-bottom: 50px;
}

.slider-main-block .single-main-block:last-child {
  margin-bottom: 0;
}

.home-slider ul.slick-dots {
  font-size: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  left: 0;
  margin-left: 60px;
}

.home-slider ul.slick-dots button {
  height: 8px;
  width: 8px;
  background: #ffff;
  border-radius: 500px;
  margin-right: 10px;
  /* left: 0; */
  transition: .4s;
}

.home-slider ul.slick-dots li.slick-active button,
.home-slider ul.slick-dots li:hover button {
  width: 28px;
  background: #56a700;
}

.home-slider.dot-position-1 ul.slick-dots {
  transform: translate(-50%);
  left: 50%;
  margin-left: 0;
}

.sidebar-one-column.petmark-slick-slider .pm-product.product-type-list {
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .sidebar-one-column.petmark-slick-slider .pm-product.product-type-list .content {
    width: 60%;
  }
}

@media (min-width: 992px) {
  .sidebar-one-column.petmark-slick-slider .pm-product.product-type-list .content {
    width: 100%;
    margin-left: 20px;
    padding-top: 5px;
  }
}

@media (min-width: 1200px) {
  .sidebar-one-column.petmark-slick-slider .pm-product.product-type-list .content {
    width: 60%;
    margin-left: 0px;
    padding-top: 20px;
  }
}

/* Tabs */
.pm-tab-nav.nav-tabs {
  border: 0;
  margin-bottom: 5px;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .pm-tab-nav.nav-tabs {
    margin-left: auto;
  }
}

.pm-tab-nav.nav-tabs .nav-link {
  border: 1px solid transparent;
  padding: 5px 10px;
  border-radius: 500px;
}

@media (min-width: 992px) {
  .pm-tab-nav.nav-tabs .nav-link {
    padding: 5px 20px;
  }
}

.pm-tab-nav.nav-tabs .nav-link.active {
  border-color: #56a700;
}

.pm-tab-nav.nav-tabs .nav-link:hover {
  color: #56a700;
}

.pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link {
  border-radius: 0;
  position: relative;
  padding: 10px 20px;
}

.pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link::after {
  content: "";
  background: transparent;
  bottom: 0;
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  width: 34px;
  transform: translateX(-50%);
  margin-bottom: -6px;
}

.pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link.active {
  border-color: transparent;
}

.pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link.active::after {
  background: #56a700;
}

.pm-tab-nav.tab-nav-style-3 {
  margin-bottom: 0;
}

.pm-tab-nav.tab-nav-style-3.nav-tabs {
  align-items: center;
}

.pm-tab-nav.tab-nav-style-3.nav-tabs .nav-link {
  border-radius: 0;
  position: relative;
  padding: 10px 20px;
  border: 0;
}

.pm-tab-nav.tab-nav-style-3.nav-tabs .nav-link.active {
  color: #56a700;
}

.pm-tab-nav-4 {
  padding-bottom: 5px !important;
}

@media (min-width: 576px) {
  .pm-tab-nav-4 {
    padding-bottom: 15px !important;
  }
}

.pm-tab-nav-4 .nav-item {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .pm-tab-nav-4 .nav-item {
    margin-bottom: -1px;
  }
}

.tab-content.pm-slider-tab-content .tab-pane {
  display: block;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: opacity .4s;
}

.tab-content.pm-slider-tab-content .tab-pane.active {
  opacity: 1;
  visibility: visible;
  height: auto;
  overflow: unset;
}

.slider-header-block.tab-header {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .slider-header-block.tab-header {
    flex-direction: row;
  }
}

.slider-header-block.tab-header .pm-tab-nav.nav-tabs {
  margin-bottom: 0;
  position: relative;
  justify-content: flex-start;
  margin-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 768px) {
  .slider-header-block.tab-header .pm-tab-nav.nav-tabs {
    top: -8px;
    justify-content: flex-end;
    margin-top: 0px;
    padding-bottom: 0px;
  }
}

/* Typography */
.title-font, .calculate-shipping h4, .discount-coupon h4 {
  font-family: 'Prata', serif;
}

.block-title {
  position: relative;
}

.block-title:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #56a700;
}

.block-title h2 {
  display: block;
  font-size: 16px;
  padding-bottom: 11px;
  position: relative;
  text-transform: uppercase;
  margin-top: -5px;
}

@media (min-width: 768px) {
  .block-title h2 {
    font-size: 18px;
    padding-bottom: 15px;
  }
}

.block-title-2 {
  border-bottom: 1px solid #cecece;
  margin-bottom: 20px;
}

.block-title-2 h2 {
  background: #00b1bf;
  color: #fff;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  font-size: 14px;
  padding-bottom: 15px;
  text-transform: uppercase;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

@media (min-width: 480px) {
  .block-title-2 h2 {
    padding: 0 28px;
    font-size: 16px;
  }
}

.block-title-3 {
  border-bottom: 2px solid #56a700;
}

.block-title-3 h2 {
  background: #56a700;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  padding: 0 28px;
  padding-bottom: 15px;
  text-transform: uppercase;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.text-italic {
  font-style: italic;
}

.font-weight-mid {
  font-weight: 500;
}

.page-section-title h1 {
  text-transform: uppercase;
  line-height: 1.35;
  margin: 0 0 5px;
  color: #1a1f2b;
}

/* Buttons */
.btn {
  border-radius: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 14px;
  width: 150px;
  border-radius: 3px;
}

.btn-outlined {
  background: transparent;
  border: 2px solid #ececec !important;
}

.btn-outlined:hover {
  background: #56a700;
  border-color: #56a700 !important;
  color: #ffff;
}

.btn-outlined--primary {
  background: transparent;
  border: 2px solid #56a700 !important;
}

.btn-outlined--primary:hover {
  background: #56a700;
  border-color: #56a700 !important;
  color: #ffff;
}

.btn-outlined--white {
  background: transparent;
  border: 2px solid #ffff;
  color: #ffff;
}

.btn-outlined--white:hover {
  background: #56a700;
  border-color: #56a700;
  color: #ffff;
}

.btn-outlined--black {
  background: transparent;
  border: 1px solid #1a1f2b;
  color: #1a1f2b;
}

.btn-outlined--black:hover {
  background: #56a700;
  border-color: #56a700;
  color: #ffff;
}

.btn-outlined--orange {
  background: transparent;
  border: 2px solid #ff7c00;
  color: #ff7c00;
}

.btn-outlined--orange:hover {
  background: #ff7c00;
  border-color: #ff7c00;
  color: #ffff;
}

.btn-rounded {
  border-radius: 500px;
}

.btn-mid {
  height: 40px;
  width: 195px;
}

.btn--primary {
  background: #56a700;
  color: #fff;
}

.btn--primary:hover {
  background: #1a1f2b;
  color: #fff;
}

.btn-black {
  height: 50px;
  padding: 0 30px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  background: #1a1f2b;
  font-size: 13px;
  font-weight: 500;
  font-family: Rubik, Arial, Helvetica, sans-serif;
  box-shadow: none;
  border-radius: 3px;
  border: none;
  outline: none;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.btn-black:hover {
  background: #56a700;
  color: #ffff;
}

/* Modal */
.modal.modal-quick-view {
  display: block;
  opacity: 0;
  visibility: hidden;
  z-index: -9999;
}

.modal.modal-quick-view .modal-dialog {
  max-width: 100%;
  margin: 20% auto;
}

@media (min-width: 768px) {
  .modal.modal-quick-view .modal-dialog {
    max-width: 700px;
    overflow-x: scroll;
  }
}

@media (min-width: 992px) {
  .modal.modal-quick-view .modal-dialog {
    max-width: 900px;
    overflow: unset;
    margin: 10% auto;
  }
}

@media (min-width: 1200px) {
  .modal.modal-quick-view .modal-dialog {
    max-width: 1100px;
    overflow: unset;
  }
}

.modal.modal-quick-view.show {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
}

.modal.modal-quick-view .pm-product-details {
  padding: 10px;
}

@media (min-width: 480px) {
  .modal.modal-quick-view .pm-product-details {
    padding: 20px;
  }
}

.modal.modal-quick-view .pm-product-details .description-block .share-block-2 {
  display: block;
}

.modal.modal-quick-view .add-to-cart .btn-block .btn {
  width: 135px;
}

@media (min-width: 768px) {
  .modal.modal-quick-view .add-to-cart .btn-block .btn {
    width: 190px !important;
  }
}

.modal.modal-quick-view #product-view-gallery {
  margin-top: 35px;
}

/* images */
.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  background-position: center;
}

.testimonial-bg {
  background-image: url(../image/banner-images/bg-testimonial.jpg);
}

.testimonial-bg-2 {
  background-image: url(../image/banner-images/home-2__bg-testimonial.jpg);
}

.testimonial-bg-3 {
  background-image: url(../image/banner-images/testimonial-bg-3.png);
}

.testimonial-bg-4 {
  background-image: url(../image/banner-images/testimonial-bg-4.png);
}

.banner4-bg-img-1 {
  background-image: url(../image/banner-images/banner4-bg-img-2.png);
}

/* Badge Images */
.badge--sell-green {
  background-image: url(../image/icons/badge-sell-3.png);
}

.badge--sell-red {
  background-image: url(../image/icons/badge-sell-2.png);
}

.badge--sell-chocolate {
  background-image: url(../image/icons/badge-sell.png);
}

.badge--new {
  background-image: url(../image/icons/badge-new.png);
}

.service-bg-image {
  position: relative;
}

@media (min-width: 992px) {
  .service-bg-image {
    padding-bottom: 215px !important;
  }
}

.service-bg-image:before {
  content: '';
  background: url(../image/bg-images/home-3/service-bg.png) no-repeat 0 0;
  background-size: 100%;
  width: 100%;
  height: 209px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-position: bottom;
}

/* Forms Styles */
.site-form .form-group {
  margin-bottom: 20px;
}

.site-form .form-control {
  border-radius: 0;
  border: 1px solid #ececec;
  background: #f4f4f4;
}

.site-form .form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: #f4f4f4;
}

.site-form .form-control::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
}

.site-form .form-control:-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
}

.site-form .form-control::-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
}

.site-form .form-control:-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
}

.site-form textarea.form-control {
  height: 130px;
  width: 100%;
}

.site-form textarea.form-control:focus {
  box-shadow: none;
  outline: none;
  border-color: #f4f4f4;
}

.site-form input.form-control {
  height: 50px;
  line-height: 50px;
  max-width: 100%;
  padding: 0 10px;
}

.site-form label {
  color: #999;
  display: block;
  margin: 0 0 5px;
}

.site-form .submit-btn {
  margin-top: 15px;
}

.site-form.contact-form-2 .form-control {
  background: #e7e7e7;
}

.border {
  border: 1px solid #ececec !important;
}

.border-bottom-green {
  border-bottom: 2px solid #56a700;
}

.rating-widget .single-rating {
  font-size: 14px;
  color: #F5C60D;
}

#scrollUp {
  background: #1a1f2b;
  border: 2px solid #ddd;
  border-radius: 100%;
  cursor: pointer;
  height: 50px;
  position: fixed;
  right: 15px;
  bottom: 0;
  margin-bottom: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  color: #fff;
}

#scrollUp.show {
  bottom: 5%;
  visibility: visible;
}

#scrollUp:hover {
  background-color: #56a700;
  border-color: #56a700;
}

/*
====> Sections Styles
*/
/* Header Section */
.site-brand {
  padding-top: 20px;
}

@media (min-width: 576px) {
  .site-brand {
    padding-top: 0px;
  }
}

@media (min-width: 768px) {
  .site-brand {
    padding-bottom: 0;
    padding-top: 25px;
  }
}

@media (min-width: 992px) {
  .site-brand {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/* Header */
.header-top-nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (min-width: 576px) {
  .header-top-nav.right-nav {
    justify-content: flex-end;
  }
}

.header-top-nav.white-color {
  color: #fff;
}

.header-top-nav.white-color .nav-item {
  color: #fff;
}

.header-top-nav.white-color .nav-item::after {
  background: #fff;
}

.header-top-nav.white-color .nav-item:hover > a {
  color: #ffff;
}

.header-top-nav .slide-down--btn i {
  font-size: 10px;
  margin-left: 5px;
}

.header-top-nav .nav-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 12px;
  margin-right: 12px;
  font-weight: 300;
  position: relative;
}

@media (min-width: 576px) and (max-width: 767px) {
  .header-top-nav .nav-item {
    padding-right: 6px;
    margin-right: 6px;
  }
}

.header-top-nav .nav-item::after {
  position: absolute;
  right: 0;
  top: 13px;
  bottom: 13px;
  content: "";
  background: rgba(26, 31, 43, 0.4);
  width: 1px;
}

.header-top-nav .nav-item:hover > a {
  color: #56a700;
}

.header-top-nav .nav-item:last-child {
  padding-right: 0px;
  margin-right: 0px;
}

.header-top-nav .nav-item:last-child:after {
  width: 0;
}

.header-top-nav .dropdown-list {
  z-index: 999;
  background: #fff;
  width: 65px;
  border: 1px solid #ececec;
}

.header-top-nav .dropdown-list li {
  text-align: center;
  position: relative;
}

.header-top-nav .dropdown-list li:before {
  content: "";
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 1px;
  position: absolute;
  background: #ececec;
}

.header-top-nav .dropdown-list li a {
  padding: 2px;
  text-align: center;
}

.header-top-nav .dropdown-list li a:hover {
  color: #56a700;
}

.header-top-nav .dropdown-list li:last-child:before {
  height: 0;
}

.header-top-nav.white-color .dropdown-list {
  color: #1a1f2b;
}

.header-top p {
  padding-top: 10px;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .header-top p {
    padding-top: 0;
  }
}

/* ------ header-middle ------ */
.header-middle {
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .header-middle {
    padding: 0;
  }
}

/* ---- Slide Down Styles ---- */
.slide-down-wrapper {
  position: relative;
}

.slide-down-wrapper .slide-down--btn {
  position: relative;
}

.slide-down-wrapper .slide-down--btn::after {
  position: absolute;
  right: 0;
  top: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 10px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.slide-down-wrapper .slide-down--item {
  position: absolute;
  top: 100%;
  right: 0px;
  display: none;
  z-index: 99;
}

.header-widget-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .header-widget-2 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/* ------ Catagory Widgets ------ */
.category-widget {
  position: relative;
  display: flex;
  z-index: 303;
}

.category-widget input {
  background: #fff;
  border: 2px solid #ececec;
  border-right: none;
  display: block;
  float: left;
  font-family: Rubik, Arial, Helvetica, sans-serif;
  height: 50px;
  letter-spacing: -0.02em;
  line-height: 46px;
  outline: none;
  text-align: left;
  padding: 0 60px 0 20px;
  white-space: nowrap;
  width: 100%;
  border-radius: 4px 4px;
}

@media (min-width: 480px) {
  .category-widget input {
    padding: 0 160px 0 20px;
  }
}

.category-widget .search-form__group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  display: flex;
  -moz-align-items: center;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  position: absolute;
  top: 2px;
  right: 0;
  bottom: 2px;
  margin-right: 50px;
}

.category-widget .search-form__group:before {
  position: absolute;
  content: "";
  background: #2c2e3d;
  left: 0;
  top: 15px;
  bottom: 15px;
  width: 0;
  z-index: 111;
}

@media (min-width: 480px) {
  .category-widget .search-form__group:before {
    width: 1px;
  }
}

.category-widget .search-form__group:after {
  position: absolute;
  font-family: "font Awesome 5 Free";
  font-weight: 900;
  right: 38px;
  top: 13px;
  bottom: 15px;
  font-size: 13px;
  z-index: 111;
  transition: .4s;
}

.category-widget .search-form__select {
  -ms-flex-preferred-size: 15rem;
  width: auto;
  height: 100%;
  min-width: 150px;
  padding-left: 15px;
  padding-right: 25px;
  line-height: 1.2;
  color: #2c2e3d;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  background-color: #fff;
  font-size: 14px;
  border: 0;
  transition: .4s;
}

.category-widget .search-form__select:focus {
  box-shadow: none;
  border: 0;
  outline: none;
}

.category-widget .search-form__select.nice-select {
  display: none;
}

@media (min-width: 480px) {
  .category-widget .search-form__select.nice-select {
    display: flex;
    align-items: center;
  }
}

.category-widget .search-submit {
  width: 50px;
  background: #56a700;
  color: #ffff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0px;
}

.category-widget .search-submit:hover {
  background: rgba(86, 167, 0, 0.8);
}

.category-widget .nice-select .list {
  height: 365px;
  overflow: auto;
}

@media (min-width: 576px) {
  .header-widget-2 {
    padding-left: 60px;
  }
}

@media (min-width: 768px) {
  .header-widget-2 {
    padding-left: 0px;
  }
}

@media (min-width: 992px) {
  .header-widget-2 {
    padding-left: 0px;
  }
}

@media (min-width: 1200px) {
  .header-widget-2 {
    padding-left: 80px;
  }
}

.header-widget-2 .header-links {
  border-top: 1px solid #ececec;
  justify-content: center;
}

@media (min-width: 576px) {
  .header-widget-2 .header-links {
    justify-content: flex-end;
  }
}

.header-widget-2 .call-widget {
  padding-bottom: 10px;
}

.call-widget {
  color: #1a1f2b;
}

.call-widget i {
  background: #c61132;
  color: #fff;
  font-size: 16px;
  height: 22px;
  line-height: 22px;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  width: 22px;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.call-widget p {
  margin-bottom: 0;
}

.header-links {
  display: flex;
  flex-wrap: wrap;
  color: #1a1f2b;
}

.header-links li {
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
}

@media (min-width: 992px) {
  .header-links li {
    font-size: 14px;
  }
}

.header-links li i {
  margin-left: 3px;
}

.header-links li:after {
  position: absolute;
  content: "";
  right: 0;
  width: 1px;
  top: 5px;
  bottom: 5px;
  background: #1a1f2b;
}

.header-links li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.header-links li:last-child:after {
  width: 0px;
}

.header-links li:hover a {
  color: #56a700;
}

.header-links.white-color {
  color: #ffff;
}

.header-links.white-color:hover a {
  color: #ffff;
}

.header-links.no-border li {
  font-weight: 300;
}

.header-links.no-border li:after {
  display: none;
}

@media (min-width: 992px) {
  .header-nav-wrapper .header-bottom-inner {
    border-top: 1px solid #ececec;
  }
}

.header-nav-wrapper > .container {
  position: relative;
}

@media (min-width: 992px) {
  .header-nav-wrapper > .container {
    position: static;
  }
}

.search-form__select.open {
  z-index: 102;
}

/***********************
Category Navigation
************************/
.category-nav {
  position: relative;
  z-index: 301;
}

@media (min-width: 992px) {
  .category-nav {
    height: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.category-nav__title {
  color: #fff;
  cursor: pointer;
  font-family: Rubik, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  width: 100%;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}

.category-nav__title i {
  margin-right: 15px;
  background: transparent;
}

.category-nav__menu {
  width: 100%;
  position: absolute;
  right: 0;
  background: #fff;
  top: 100%;
  z-index: 9;
  border-top: none;
  text-align: left;
  box-shadow: 0px 0px 6.65px 0.35px rgba(0, 0, 0, 0.15);
  display: none;
}

.category-nav__menu__item {
  position: relative;
}

.category-nav__menu__item.has-children:hover:after {
  color: #56a700;
}

.category-nav__menu__item:last-child a {
  border-bottom: none;
}

.category-nav__menu__item a {
  display: block;
  padding: 15px 20px;
  font-size: 14px;
  color: #333333;
  position: relative;
}

.category-nav__menu__item a:hover {
  color: #56a700;
}

.category-nav__menu__item a i {
  padding-right: 1rem;
  font-size: 1.5rem;
  color: #333333;
}

@media (max-width: 74.9375em) {
  .category-nav__menu__item a {
    padding: 10px 20px;
  }
}

@media (max-width: 991.04px) {
  .category-nav__menu__item a {
    margin: 0;
  }
}

.category-nav__menu__item a.js-expand-hidden-menu {
  padding-left: 50px;
}

.category-nav__menu__item a.js-expand-hidden-menu:before {
  position: absolute;
  content: '\f067';
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  left: 0;
  top: 0;
  width: 2rem;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 20px;
}

.category-nav__menu__item a.js-expand-hidden-menu.menu-close:before {
  content: '\f068';
}

.category-nav__menu__item > a {
  border-bottom: 1px solid #e5e5e5;
}

.category-nav__menu__item.has-children:after {
  position: absolute;
  content: "\f107";
  right: 2rem;
  top: 50%;
  color: #2c2e3d;
  font-family: 'Font Awesome 5 Free';
  font-size: 16px;
  font-weight: 900;
}

@media (min-width: 992px) {
  .category-nav__menu__item.has-children:after {
    content: "\f105";
    transform: translateY(-50%);
  }
}

@media (max-width: 991.04px) {
  .category-nav__menu__item.has-children:after {
    top: 8px;
  }
}

@media (min-width: 992px) {
  .category-nav__menu__item.has-children:hover .category-nav__submenu {
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}

.category-nav__submenu {
  background: #fff;
  z-index: 99;
  white-space: -moz-nowrap;
  white-space: nowrap;
  height: 0;
  min-width: 200px;
  display: none;
}

@media (min-width: 992px) {
  .category-nav__submenu {
    display: block;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    position: absolute;
    left: 100%;
    top: 0;
  }
}

.category-nav__submenu.mega-menu {
  padding: 20px 30px;
  min-width: 40rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  display: none;
}

@media (min-width: 992px) {
  .category-nav__submenu.mega-menu {
    padding: 10px 15px 15px 25px;
    flex-direction: row;
    display: flex;
  }
}

.category-nav__submenu.mega-menu.three-column {
  min-width: 670px;
  border-bottom: 1px solid #ececec;
}

@media (min-width: 992px) {
  .category-nav__submenu.mega-menu.three-column {
    transition: all 0.4s;
    border: 1px solid #ececec;
  }
}

.category-nav__submenu.mega-menu .category-nav__submenu--inner ul li {
  border-bottom: none;
  padding: 0;
}

@media (max-width: 991.04px) {
  .category-nav__submenu {
    visibility: visible;
    opacity: 1;
    height: auto;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 100% !important;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0);
    border-bottom: 1px solid #ececec;
  }
}

@media (max-width: 575.04px) {
  .category-nav__submenu {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.category-nav__submenu--inner {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .category-nav__submenu--inner {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 991.04px) {
  .category-nav__submenu--inner {
    padding: 0;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 991.04px) {
  .category-nav__submenu--inner:last-child {
    margin-bottom: 0;
  }
}

.category-nav__submenu__title {
  color: #333333;
  text-transform: capitalize;
  font-size: 16px;
  padding-bottom: 15px;
}

@media (min-width: 992px) {
  .category-nav__submenu__title {
    margin-bottom: 15px;
  }
}

.category-nav__submenu__title:after {
  left: 0;
  bottom: 0;
  width: 8rem;
  height: 1px;
  background-color: #333333;
}

.category-nav__submenu ul li {
  padding: 13px 20px;
}

.category-nav__submenu ul li:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
}

.category-nav__submenu ul li a {
  border-bottom: none;
  margin: 0;
  padding: 0;
  line-height: 2;
}

.category-nav--2 .category-nav__title {
  display: none;
}

.category-nav--2 .category-nav__menu__item > a {
  padding-top: 1.57rem;
  padding-bottom: 1.57rem;
}

@media (max-width: 74.9375em) {
  .category-nav--2 .category-nav__menu__item > a {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.category-nav--3 .category-nav__title {
  border-radius: 0;
}

.hidden-menu-item {
  display: none;
}

@media (max-width: 74.9375em) {
  .hidden-lg-menu-item {
    display: none;
  }
}

@media (min-width: 992px) {
  .category-nav__submenu {
    visibility: hidden;
    opacity: 0;
  }
}

/* ---- Cart Widget ----*/
/*Cart Widget Block*/
.cart-widget {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 100;
  position: relative;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
  z-index: 99;
}

@media (min-width: 768px) {
  .cart-widget {
    justify-content: center;
    padding-right: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .cart-widget {
    padding: 9.5px 15px;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
  }
}

.cart-widget .cart-icon {
  position: relative;
  font-size: 28px;
  padding-right: 20px;
  margin-right: 10px;
  transition: .4s;
}

.cart-widget .cart-icon .cart-count-badge {
  font-size: 10px;
  background: #56a700;
  color: #fff;
  height: 16px;
  width: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.cart-widget .cart-text span {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
  transition: .4s;
  cursor: pointer;
}

.cart-widget .cart-text .amount {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: #56a700;
}

.cart-widget:hover {
  color: #56a700;
}

.cart-widget.white-color {
  color: #fff;
}

.cart-widget.white-color .cart-count-badge {
  background: #fff;
  color: #1a1f2b;
}

.cart-widget.white-color .amount {
  color: #ffff;
}

/* Slide Down Cart Block*/
.cart-widget-wrapper {
  z-index: 302;
  /**/
  /*Cart Single Item*/
  /*Subtotal*/
  /* Cart Buttons */
}

.cart-widget-wrapper .cart-widget-box {
  background: #ffffff;
  border: 1px solid #ececec;
  color: #595e6f;
  font-size: 1em;
  line-height: 1.5;
  max-height: 500px;
  overflow: auto;
  padding: 30px 25px 30px 25px;
  width: 280px;
}

@media (min-width: 480px) {
  .cart-widget-wrapper .cart-widget-box {
    width: 360px;
  }
}

.cart-widget-wrapper .cart-product {
  display: flex;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ececec;
}

.cart-widget-wrapper .cart-product:last-child {
  padding-bottom: 00px;
  margin-bottom: 0px;
  border-bottom: 0px solid #ececec;
}

.cart-widget-wrapper .cart-product:hover {
  color: inherit;
  border-color: inherit;
}

.cart-widget-wrapper .cart-product-img {
  border: 1px solid #ececec;
  margin-right: 15px;
  position: relative;
  width: 75px;
  transition: .4s;
}

.cart-widget-wrapper .cart-product-img:hover {
  border-color: #56a700;
}

.cart-widget-wrapper .cart-product .product-details--title {
  color: #1a1f2b;
  font-size: 1em;
  font-weight: 500;
  line-height: 20px;
  margin: 0 30px 5px 0;
  text-align: left;
  text-transform: none;
  transition: .4s;
}

.cart-widget-wrapper .cart-product .product-details--title:hover {
  color: #56a700;
}

.cart-widget-wrapper .cart-product .cart-cross {
  background: none;
  color: #1a1f2b;
  display: block;
  font-size: 18px;
  font-weight: bold;
  height: auto;
  left: auto;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 1;
  transition: .4s;
}

.cart-widget-wrapper .cart-product .cart-cross:hover {
  color: #56a700;
}

.cart-widget-wrapper .cart-items .single-cart {
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
  margin-bottom: 15px;
}

.cart-widget-wrapper .cart-items .single-cart:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.cart-widget-wrapper .cart-product__subtotal {
  display: flex;
  color: #1a1f2b;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.cart-widget-wrapper .cart-product__subtotal span {
  width: 50%;
}

.cart-widget-wrapper .cart-product__subtotal span + span {
  text-align: right;
}

.cart-widget-wrapper .cart-buttons {
  display: flex;
}

.cart-widget-wrapper .cart-buttons .btn {
  width: auto;
  padding: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1f2b;
}

@media (min-width: 480px) {
  .cart-widget-wrapper .cart-buttons .btn {
    padding: 25px;
    font-size: 16px;
  }
}

.cart-widget-wrapper .cart-buttons .btn:last-child {
  margin-left: auto;
}

.cart-widget-wrapper .cart-buttons .btn:hover {
  color: #ffff;
}

/* Header 1 */
.petmark-header-1 .header-widget-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.petmark-header-1 .header-top-nav {
  margin-top: -5px;
}

.petmark-header-1 .category-nav {
  position: relative;
  z-index: 303;
}

/* Header 2 */
.petmark-header-2 .header-top {
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.petmark-header-2 .header-middle {
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .petmark-header-2 .header-middle .cart-widget {
    padding: 9.5px 0px;
  }
}

.petmark-header-2 .site-brand {
  padding-top: 0;
}

@media (min-width: 768px) {
  .petmark-header-2 .site-brand {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.petmark-header-2 .header-bottom {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 480px) {
  .petmark-header-2 .header-bottom {
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .petmark-header-2 .header-bottom {
    padding-top: 10px;
  }
}

@media (min-width: 992px) {
  .petmark-header-2 .header-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.petmark-header-2 .cart-widget-wrapper {
  z-index: 304 !important;
}

@media (min-width: 992px) {
  .petmark-header-2 .cart-widget {
    justify-content: flex-end;
  }
}

.petmark-header-2 .header-top-nav.right-nav {
  justify-content: center;
}

@media (min-width: 768px) {
  .petmark-header-2 .header-top-nav.right-nav {
    justify-content: flex-end;
  }
}

.petmark-header-2 .category-widget {
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 301;
}

@media (min-width: 576px) {
  .petmark-header-2 .category-widget {
    z-index: 300;
  }
}

@media (min-width: 768px) {
  .petmark-header-2 .category-widget {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.petmark-header-2 .category-nav {
  border-radius: 2px;
  padding: 0 0;
}

@media (min-width: 576px) {
  .petmark-header-2 .category-nav {
    z-index: 301;
  }
}

.petmark-header-2 .category-nav .category-nav__title {
  height: 50px;
  line-height: 50px;
}

@media (min-width: 576px) {
  .petmark-header-2 .category-nav {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .petmark-header-2 .category-nav {
    margin-top: 0;
  }
}

.petmark-header-2 .call-widget {
  text-align: right;
  margin-top: 10px;
}

@media (min-width: 576px) {
  .petmark-header-2 .call-widget {
    margin-top: 0px;
  }
}

@media (min-width: 768px) {
  .petmark-header-2 .call-widget {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .petmark-header-2 .call-widget {
    margin-top: 0;
  }
}

/* Header 3 */
.petmark-header-3 .header-top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.petmark-header-3 .header-middle {
  padding-bottom: 0px;
}

@media (min-width: 576px) {
  .petmark-header-3 .header-middle {
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .petmark-header-3 .header-middle {
    padding-bottom: 0px;
  }
}

.petmark-header-3 .header-bottom {
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
}

.petmark-header-3 .site-brand {
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .petmark-header-3 .site-brand {
    padding-bottom: 20px;
  }
}

.petmark-header-3 .cart-widget-wrapper {
  text-align: center;
}

@media (min-width: 576px) {
  .petmark-header-3 .cart-widget-wrapper {
    text-align: initial;
  }
}

.petmark-header-3 .cart-widget-wrapper .cart-widget {
  display: inline-flex;
}

@media (min-width: 576px) {
  .petmark-header-3 .cart-widget-wrapper .cart-widget {
    display: flex;
  }
}

@media (min-width: 768px) {
  .petmark-header-3 .cart-widget-wrapper .cart-widget {
    justify-content: flex-end;
  }
}

.petmark-header-3 .header-bottom > .container {
  position: relative;
}

@media (min-width: 992px) {
  .petmark-header-3 .header-bottom > .container {
    position: static;
  }
}

.petmark-header-3 .header-bottom .call-widget {
  padding: 20px 0;
}

@media (min-width: 992px) {
  .petmark-header-3 .header-bottom .call-widget {
    padding: 0;
  }
}

.petmark-header-3 .cart-widget-wrapper {
  position: relative;
  z-index: 302;
}

@media (min-width: 576px) {
  .petmark-header-3 .cart-widget-wrapper {
    z-index: 303;
  }
}

.petmark-header-3 .category-widget {
  z-index: 303;
}

@media (min-width: 576px) {
  .petmark-header-3 .category-widget {
    z-index: 302;
  }
}

/* Header 4 */
@media (min-width: 992px) {
  .petmark-header-4 .header-nav-wrapper {
    padding-top: 25px;
  }
}

@media (min-width: 992px) {
  .petmark-header-4 .header-nav-wrapper .header-bottom-inner {
    border: 0;
    position: absolute;
    z-index: 999;
    width: 100%;
    left: 0;
    transform: translateY(-50%);
  }
}

.petmark-header-4 .header-nav-wrapper > .container {
  position: relative;
}

.petmark-header-4 .cart-widget {
  padding: 4.5px 15px;
  border: 0;
}

/* Menu Styles*/
.main-navigation {
  display: block;
}

.main-navigation.white-color .mainmenu > li > a {
  color: #ffff;
}

.main-navigation.white-color .mainmenu > li.menu-item-has-children:after {
  color: #ffff;
}

.main-navigation.white-color .mainmenu > li.menu-item-has-children:hover:after {
  color: #ffff !important;
}

.sticky-navigation.white-color .mainmenu > li > a {
  color: #ffff;
}

.sticky-navigation.white-color .mainmenu > li > a:hover {
  color: #ffff;
}

.sticky-navigation.white-color .mainmenu li.menu-item-has-children:after {
  color: #ffff;
}

.sticky-navigation.white-color .mainmenu li.menu-item-has-children:after:hover {
  color: #ffff;
}

@media (max-width: 991.04) {
  .main-navigation {
    display: none;
  }
}

.main-navigation-2 {
  padding-left: 2rem;
}

.mainmenu.left-align {
  text-align: left;
}

.mainmenu.right-align {
  text-align: right;
}

.mainmenu.center-align {
  text-align: right;
}

@media (max-width: 1199px) {
  .mainmenu {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .mainmenu__item {
    display: inline-block;
    position: relative;
  }
  .mainmenu__item.menu-item-has-children:after {
    content: '\f078';
    right: 0;
  }
}

.mainmenu li {
  position: relative;
}

.mainmenu li.menu-item-has-children li a {
  font-weight: 400;
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children > ul {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-transform-origin: center top 0;
    -moz-transform-origin: center top 0;
    -ms-transform-origin: center top 0;
    -o-transform-origin: center top 0;
    transform-origin: center top 0;
    -webkit-transform: perspective(600px) rotateX(-90deg);
    -moz-transform: perspective(600px) rotateX(-90deg);
    -ms-transform: perspective(600px) rotateX(-90deg);
    -o-transform: perspective(600px) rotateX(-90deg);
    transform: perspective(600px) rotateX(-90deg);
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    visibility: hidden;
    opacity: 0;
    text-align: left;
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: -1;
    pointer-events: none;
  }
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children > ul.megamenu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
    width: 40rem;
    left: 0;
    padding: 14px 25px;
  }
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children > ul.megamenu > li {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children > ul.megamenu li {
    padding-right: 0;
  }
}

@media (max-width: 1199px) {
  .mainmenu li.menu-item-has-children > ul.megamenu li {
    padding-right: 1rem;
  }
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children > ul.megamenu li ul {
    position: relative;
    visibility: visible;
    opacity: 1;
    top: auto !important;
    left: auto;
    background: transparent;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    display: block;
    padding: 0 !important;
  }
}

.mainmenu li.menu-item-has-children > ul.megamenu li a {
  color: #666666;
  font-size: 14px;
  padding: 10px 10px;
  text-transform: capitalize;
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children > ul.megamenu li a:hover {
    color: #56a700;
  }
}

.mainmenu li.menu-item-has-children > ul.megamenu > li > a {
  font-weight: 600;
  font-size: 15px;
  color: #666666;
  text-transform: uppercase;
}

.mainmenu li.menu-item-has-children > ul.megamenu > li > a:hover {
  color: #666666;
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children > ul.megamenu.three-column {
    min-width: 500px;
    left: 50%;
    margin-left: -35rem;
    border: 1px solid #ececec;
  }
  .mainmenu li.menu-item-has-children > ul.megamenu.three-column > li {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 33.3333%;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children > ul ul {
    left: 100%;
    top: 15rem !important;
    visibility: hidden;
    opacity: 0;
  }
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children:after {
    position: absolute;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    top: 50%;
    margin-top: -12px;
    color: #1a1f2b;
  }
}

@media (max-width: 991.04) {
  .mainmenu li.menu-item-has-children:after {
    display: none;
  }
}

@media (min-width: 992px) {
  .mainmenu > li:hover > ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
    z-index: 9999;
    -webkit-transform: perspective(600px) rotateX(0deg);
    -moz-transform: perspective(600px) rotateX(0deg);
    -ms-transform: perspective(600px) rotateX(0deg);
    -o-transform: perspective(600px) rotateX(0deg);
    transform: perspective(600px) rotateX(0deg);
    pointer-events: visible;
  }
}

.mainmenu li a {
  display: block;
  padding: 24px 25px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1f2b;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .mainmenu .sub-menu {
    pointer-events: none;
    width: 195px;
    padding: 10px 0;
    white-space: -moz-nowrap;
    white-space: nowrap;
  }
}

@media (min-width: 1200px) {
  .mainmenu .sub-menu {
    width: 220px;
  }
}

@media (min-width: 992px) {
  .mainmenu .sub-menu.left-align {
    left: -100% !important;
  }
}

@media (min-width: 992px) {
  .mainmenu .sub-menu a {
    color: #666666;
    font-size: 14px;
    padding: 8px 30px;
    display: block;
    text-transform: capitalize;
  }
}

@media (min-width: 992px) {
  .mainmenu .sub-menu li.menu-item-has-children:after {
    content: '\f105';
    right: 2rem;
    color: #666666;
  }
}

@media (min-width: 992px) {
  .mainmenu .sub-menu li ul {
    -webkit-transform: perspective(600px) rotateX(0deg);
    -moz-transform: perspective(600px) rotateX(0deg);
    -ms-transform: perspective(600px) rotateX(0deg);
    -o-transform: perspective(600px) rotateX(0deg);
    transform: perspective(600px) rotateX(0deg);
  }
}

@media (min-width: 992px) {
  .mainmenu .sub-menu li:hover ul {
    visibility: visible;
    opacity: 1;
    pointer-events: visible;
    top: 0 !important;
  }
}

.mainmenu--2 {
  text-align: left;
}

.mainmenu--2 .mainmenu__item.active a {
  color: #40576c;
}

.mainmenu--2 .mainmenu__item.menu-item-has-children.active:after {
  color: #40576c;
}

.mainmenu--2 .mainmenu__item:hover > ul {
  top: 6.9rem;
}

.mainmenu--2 > li > a {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.mainmenu--3 {
  text-align: left;
}

.mainmenu--3 li.menu-item-has-children:after {
  color: #333333;
}

.mainmenu--3 li.menu-item-has-children > ul.megamenu.three-column {
  left: auto;
  right: 0;
  margin-left: 0;
}

.mainmenu--3 li a {
  color: #333333;
}

/***********************
  Mobile Menu
  ************************/
.mobile-menu {
  flex: 1 0 100%;
  padding: 8px 0;
}

.mobile-absolute-menu {
  position: absolute;
  right: 0;
  margin-top: 60px;
  z-index: 300;
}

@media (min-width: 768px) {
  .mobile-absolute-menu {
    margin-top: 0px;
  }
}

.mobile-menu .mean-bar {
  position: relative;
  background-color: transparent;
  z-index: 98 !important;
}

.mobile-menu .mean-bar:before {
  position: absolute;
  left: 2rem;
  top: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.mobile-menu .mean-bar .meanmenu-reveal {
  position: absolute;
  padding: 10px 13px 7px 9px;
  border: 1px solid #afadad;
  left: 15px !important;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .mobile-menu .mean-bar .meanmenu-reveal {
    right: 15px !important;
    margin-top: 0px;
    left: auto !important;
  }
}

.mobile-menu .mean-bar .meanmenu-reveal span {
  position: relative;
}

.mobile-menu .mean-bar .meanmenu-reveal span.menu-bar {
  height: 2px;
  width: 26px;
  background-color: #afadad;
  display: block;
  margin: 8px 0;
}

.mobile-menu .mean-bar .meanmenu-reveal span:before,
.mobile-menu .mean-bar .meanmenu-reveal span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #afadad;
}

.mobile-menu .mean-bar .meanmenu-reveal span:before {
  top: -8px;
}

.mobile-menu .mean-bar .meanmenu-reveal span:after {
  bottom: -8px;
}

.mobile-menu .mean-bar .meanmenu-reveal span.menu-close {
  height: 2px;
  width: 26px;
  background-color: transparent;
  display: block;
  margin: 8px 0;
}

.mobile-menu .mean-bar .meanmenu-reveal span.menu-close:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mobile-menu .mean-bar .meanmenu-reveal span.menu-close:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.mobile-menu .mean-bar .mean-nav {
  background-color: #ffffff;
  margin-top: 48px;
  margin-top: 65px;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .mobile-menu .mean-bar .mean-nav {
    margin-top: 48px;
  }
}

.mobile-menu .mean-bar .mean-nav > ul {
  overflow-x: hidden;
  max-height: 400px;
  position: absolute;
  background: #fff;
  right: 0;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 47.94em) {
  .mobile-menu .mean-bar .mean-nav > ul {
    max-height: 180px;
    overflow-y: auto;
  }
}

.mobile-menu .mean-bar .mean-nav > ul li {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
}

.mobile-menu .mean-bar .mean-nav > ul li a {
  font-size: 13px;
  display: block;
  color: #444444;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 44px;
  position: relative;
  border-top: 1px solid #eeeeee;
  padding: 0 40px 0 20px;
  width: 100%;
}

.mobile-menu .mean-bar .mean-nav > ul li ul {
  position: static;
  background-color: rgba(0, 0, 0, 0.03);
  margin: 0;
  padding: 0 !important;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  display: none;
  float: left;
  width: 100%;
  opacity: 1;
  visibility: visible;
  z-index: 1;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.mobile-menu .mean-bar .mean-nav > ul li ul.megamenu {
  width: 100%;
  left: 0;
  margin-left: 0;
}

.mobile-menu .mean-bar .mean-nav > ul li ul.megamenu li {
  max-width: 100%;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  padding: 0;
}

.mobile-menu .mean-bar .mean-nav > ul li ul.megamenu li a {
  font-size: 14px;
  margin-bottom: 0;
  padding: 0 40px 0 20px;
  opacity: 1;
}

.mobile-menu .mean-bar .mean-nav ul li a.mean-expand {
  position: absolute;
  right: -1px;
  top: 0;
  font-size: 20px !important;
  color: #444444;
  line-height: 44px;
  height: 45px;
  width: 40px;
  text-align: center;
  padding: 0 !important;
}

.mobile-menu .mean-bar .mean-nav ul li a.mean-expand:hover {
  background: rgba(0, 0, 0, 0);
}

.mobile-menu--3 .mean-bar {
  margin-top: 0;
  margin-bottom: 3rem;
}

.menu-item-has-children:hover:after {
  color: #56a700 !important;
}

@media (min-width: 992px) {
  .mainmenu li > a:hover {
    color: #56a700;
  }
}

.sticky-init {
  display: none;
  background: rgba(255, 255, 255, 0.95);
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  margin: 0;
  -webkit-animation: fadeInDown 0.8s ease-in-out;
  -moz-animation: fadeInDown 0.8s ease-in-out;
  -ms-animation: fadeInDown 0.8s ease-in-out;
  -o-animation: fadeInDown 0.8s ease-in-out;
  animation: fadeInDown 0.8s ease-in-out;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
}

@media (max-width: 991.04) {
  .sticky-header {
    padding: 1rem 0;
  }
}

.sticky-header.fixed-header {
  display: block;
}

.sticky-header .sticky-logo {
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
}

.sticky-header .mainmenu {
  text-align: right;
}

.sticky-header .mainmenu > .mainmenu__item > a {
  padding: 29px 25px;
}

@media (min-width: 62em) {
  .sticky-header .mainmenu li.menu-item-has-children > ul.megamenu {
    left: auto !important;
    margin-left: 0 !important;
    right: 0;
  }
}

@media (min-width: 62em) {
  .sticky-header .mainmenu li:hover ul {
    top: 100%;
  }
}

@media (max-width: 991.04) {
  .sticky-header .sticky-navigation {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 100% !important;
    -moz-box-flex: 1;
    -moz-flex: 1 0 100% !important;
    -ms-flex: 1 0 100% !important;
    flex: 1 0 100% !important;
  }
}

@media (max-width: 991.04) {
  .sticky-header .sticky-navigation .sticky-menu.mainmenu {
    max-height: 40rem;
    text-align: left;
    overflow-y: auto;
    padding-top: 2rem;
    display: none;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item {
    display: block;
    position: relative;
    background: #fff;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item a {
    padding: 1.2rem 1.8rem;
    display: block;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item:first-child > a {
    padding-left: 1.8rem;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul {
    position: relative;
    visibility: visible;
    opacity: 1;
    left: 0;
    top: 0 !important;
    width: 100%;
    background: #f2f2f2;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.hidden-sub {
    display: none;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.megamenu {
    padding: 0;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.megamenu li {
    max-width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding-right: 0;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.megamenu li .megamenu__heading {
    padding: 1.2rem 1.8rem;
    margin-bottom: 0;
    position: relative;
    font-size: 1.4rem;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.megamenu li .megamenu__heading:after {
    position: absolute;
    content: "\f107";
    font-family: fontAwesome;
    right: 20px;
    top: 13px;
    color: #40576c;
    display: block;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul li:first-child {
    border-top: 1px solid #e5e5e5;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul li:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul li a {
    padding-left: 2.5rem;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul li ul li a {
    padding-left: 3rem;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item a {
    color: #40576c;
  }
  .sticky-header .sticky-navigation .sticky-menu.mainmenu .menu-item-has-children > a:after {
    position: absolute;
    content: "\f107";
    font-family: fontAwesome;
    right: 20px;
    top: 12px;
    color: #40576c;
    display: block;
  }
}

.sticky-header .sticky-navigation .sticky-mobile-menu {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  right: 0;
  top: -4rem;
  cursor: pointer;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn {
  height: 2px;
  width: 100%;
  background: #fff;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:before,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:after {
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:before {
  top: -8px;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:after {
  top: 8px;
}

.sticky-header .sticky-navigation .sticky-mobile-menu.sticky-close .sticky-menu-btn {
  background-color: transparent;
}

.sticky-header .sticky-navigation .sticky-mobile-menu.sticky-close .sticky-menu-btn:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sticky-header .sticky-navigation .sticky-mobile-menu.sticky-close .sticky-menu-btn:after {
  top: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sticky-header .sticky-navigation .sticky-mobile-menu {
  content: "";
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:before,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:after {
  position: absolute;
  content: "";
}

.sticky-header .sticky-navigation,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn {
  position: relative;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:before,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:after {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.mainmenu li.menu-item-has-children:after {
  opacity: 0;
}

@media (min-width: 992px) {
  .mainmenu li.menu-item-has-children:after {
    opacity: 1;
    transition: .4s;
  }
}

@media (min-width: 992px) {
  .mainmenu li:hover:after {
    color: #56a700;
  }
}

@media (min-width: 992px) {
  .mainmenu li:hover > a {
    color: #56a700;
  }
}

/*Header 2*/
.petmark-header-2 .sticky-header {
  background: rgba(0, 177, 191, 0.95);
}

.petmark-header-2 .mobile-absolute-menu {
  position: relative;
  top: 0;
  margin-top: 0;
  z-index: 302;
}

@media (min-width: 576px) {
  .petmark-header-2 .mobile-absolute-menu {
    position: absolute;
    margin-top: 7px;
  }
}

@media (min-width: 768px) {
  .petmark-header-2 .mobile-absolute-menu {
    margin-top: 17px;
  }
}

.petmark-header-2 .mobile-menu {
  padding: 0;
}

@media (min-width: 768px) {
  .petmark-header-2 .mobile-menu {
    padding: 8px 0;
  }
}

.petmark-header-2 .mobile-menu .mean-bar .meanmenu-reveal {
  border-color: #fff;
  left: auto !important;
  right: 0 !important;
}

.petmark-header-2 .mobile-menu .mean-bar .meanmenu-reveal span {
  color: #fff;
}

.petmark-header-2 .mobile-menu .mean-bar .meanmenu-reveal span:after, .petmark-header-2 .mobile-menu .mean-bar .meanmenu-reveal span:before {
  background: #fff;
}

.petmark-header-2 .mobile-menu .mean-bar .meanmenu-reveal span.menu-bar {
  background-color: #fff;
}

.petmark-header-2 .mobile-menu .mean-bar .mean-nav {
  margin-top: 64px;
}

.petmark-header-2 .mobile-menu .mean-bar .mean-nav > ul {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.petmark-header-2 .slide-down-wrapper {
  z-index: 201;
}

@media (min-width: 992px) {
  .petmark-header-2 .slide-down-wrapper {
    z-index: unset;
  }
}

/*Header 3*/
.petmark-header-3 .mainmenu > li > a {
  padding-top: 20px;
  padding-bottom: 20px;
}

.petmark-header-3 .mobile-absolute-menu {
  margin-top: 0;
}

.petmark-header-3 .mobile-menu .mean-bar .mean-nav {
  margin-top: 85px;
}

@media (min-width: 576px) {
  .petmark-header-3 .mobile-menu .mean-bar .mean-nav {
    margin-top: 65px;
  }
}

.petmark-header-3 .mobile-menu .mean-bar .meanmenu-reveal {
  left: auto !important;
  right: 0px !important;
  margin-top: 25px;
}

@media (min-width: 576px) {
  .petmark-header-3 .mobile-menu .mean-bar .meanmenu-reveal {
    margin-top: 15px;
  }
}

@media (min-width: 480px) {
  .petmark-header-3 .mobile-menu .mean-bar .mean-nav > ul {
    margin: 0;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .petmark-header-3 .mainmenu li.menu-item-has-children > ul.megamenu.three-column {
    margin-left: -28.7rem;
  }
}

/* Header 4 */
.petmark-header-4 .mobile-menu .mean-bar .mean-nav {
  margin-top: 58px;
}

@media (min-width: 768px) {
  .petmark-header-4 .mobile-menu .mean-bar .mean-nav {
    margin-top: 49px;
  }
}

@media (min-width: 992px) {
  .petmark-header-4 .mainmenu li.menu-item-has-children > ul.megamenu.three-column {
    margin-left: -25rem;
  }
}

.petmark-header-4 .mainmenu li.menu-item-has-children > ul.megamenu.three-column ul > li a {
  font-weight: 500;
}

@media (min-width: 992px) {
  .petmark-header-4 .mainmenu li.menu-item-has-children > ul.megamenu.three-column ul > li a {
    font-weight: 400;
  }
}

.petmark-header-4 .mainmenu > li > a {
  display: block;
  padding: 19px 25px;
}

.petmark-header-4 .mobile-absolute-menu {
  margin-top: -9.5px;
}

@media (min-width: 768px) {
  .petmark-header-4 .mobile-absolute-menu {
    margin-top: 0;
  }
}

.petmark-header-4 .mobile-menu .mean-bar .meanmenu-reveal {
  border: 1px solid #fff;
  left: 30px !important;
}

@media (min-width: 768px) {
  .petmark-header-4 .mobile-menu .mean-bar .meanmenu-reveal {
    right: 30px !important;
  }
}

.petmark-header-4 .mobile-menu .mean-bar .meanmenu-reveal span.menu-bar {
  background: #fff;
}

.petmark-header-4 .mobile-menu .mean-bar .meanmenu-reveal span.menu-bar:after, .petmark-header-4 .mobile-menu .mean-bar .meanmenu-reveal span.menu-bar:before {
  background: #fff;
}

.petmark-header-4 .mobile-menu .mean-bar .meanmenu-reveal span.menu-close:after, .petmark-header-4 .mobile-menu .mean-bar .meanmenu-reveal span.menu-close:before {
  background: #fff;
}

/* Hero Area*/
.home-content {
  height: 500px;
  display: flex !important;
  align-items: center;
  color: #000;
  position: relative;
}

.home-content h1 {
  text-transform: uppercase;
  font-size: 44px;
}

@media (min-width: 768px) {
  .home-content h1 {
    font-size: 56px;
  }
}

.home-content h2 {
  text-transform: uppercase;
  font-size: 29px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .home-content h2 {
    font-size: 40px;
  }
}

.home-content h4 {
  font-size: 20px;
  font-weight: 400;
}

.home-content h3 {
  font-size: 28px;
  font-weight: 500;
}

.home-content .slider-btn {
  color: #56a700;
}

.home-content .slider-btn .btn {
  height: 40px;
  display: inline-flex;
  width: 130px;
  font-size: 14px;
  text-transform: uppercase;
}

.home-content .slider-btn .btn:hover {
  color: #fff;
}

.herobanner-progress {
  animation: initial;
  background: rgba(51, 51, 51, 0.9);
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 5;
  opacity: 0.2;
}

.slick-current .herobanner-progress {
  animation: 8000ms ease-in-out 0s normal backwards 1 running hoFill;
}

@-webkit-keyframes hoFill {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes hoFill {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.hero-area-two .home-content {
  color: #ffff;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .hero-area-two .home-content {
    padding-left: 60px;
  }
}

@media (min-width: 992px) {
  .hero-area-two .home-content {
    padding-left: 45px;
    height: 516px;
  }
}

@media (min-width: 1200px) {
  .hero-area-two .home-content {
    height: 481px;
  }
}

.hero-area-two .home-content h4 {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.hero-area-two .home-content h2 {
  font-size: 32px;
  line-height: 32px;
}

@media (min-width: 768px) {
  .hero-area-two .home-content h2 {
    font-size: 40px;
    line-height: 40px;
  }
}

.hero-area-two .slider-btn {
  color: #fff;
}

.hero-area-two .slider-btn .btn {
  color: inherit;
}

.home-slider-3 .home-content {
  padding-left: 10px;
  color: #ffff;
}

@media (min-width: 768px) {
  .home-slider-3 .home-content {
    padding-left: 70px;
  }
}

.home-slider-3 .home-slider ul.slick-dots {
  margin-left: 75px;
}

.home-4 .home-content {
  height: 520px;
}

@media (min-width: 992px) {
  .home-4 .home-slider ul.slick-dots {
    margin-bottom: 75px;
  }
}

/* Breadcrumb Section */
.breadcrumb-wrapper {
  background: #e9ecef;
}

.breadcrumb-wrapper .breadcrumb {
  background: transparent;
  padding: 15px 0;
  margin-bottom: 0;
}

.breadcrumb-item {
  color: #1a1f2b;
}

.breadcrumb-item.active,
.breadcrumb-item:hover a {
  color: #56a700;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 15px;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 15px;
  content: ">";
  color: #56a700;
}

/* Policy Section */
.border-div {
  position: relative;
}

.border-div:before {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  background: #ececec;
  left: 0;
  top: 0;
}

@media (min-width: 992px) {
  .border-div:before {
    height: 100%;
    width: 1px;
  }
}

.border-four-column [class*="col-"] > .policy-block-single:before {
  content: "";
  width: 1px;
  position: absolute;
  background: #ececec;
  left: -15px;
  top: 10px;
  bottom: 10px;
}

.border-four-column [class*="col-"] > .policy-block-single:after {
  background: #ececec;
  position: absolute;
  content: "";
  left: 10px;
  right: 10px;
  top: -1px;
  height: 1px;
}

.policy-block {
  overflow: hidden;
  border: 1px solid #ececec;
}

@media (min-width: 992px) {
  .policy-block {
    padding: 0;
  }
}

.policy-block .policy-block-single {
  justify-content: center;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}

.policy-block.policy-hr .policy-block-single {
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 0;
  margin-bottom: 0;
}

.policy-block.policy-hr .policy-block-single:before {
  content: "";
  position: absolute;
  background: #ececec;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1px;
}

@media (min-width: 992px) {
  .policy-block.absoute-policy {
    position: absolute;
    width: calc(100% - 30px);
    top: 0;
    transform: translateY(-50%);
    z-index: 300;
    background: #fff;
  }
}

.policy-block-single {
  display: flex;
}

.policy-block-single .icon {
  font-size: 36px;
  color: #56a700;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
}

.policy-block-single .text {
  margin-left: 10px;
}

.policy-block-single .text h3 {
  font-size: 16px;
  margin-bottom: 4px;
}

.policy-block-single .text p {
  font-weight: 300;
  margin-bottom: 7px;
}

/* Category Section */
.category-block-single {
  text-align: center;
  position: relative;
  padding-right: 2px;
  padding-left: 2px;
}

.category-block-single:before {
  content: "";
  width: 0%;
  position: absolute;
  background: #ececec;
  left: 0;
}

@media (min-width: 992px) {
  .category-block-single:before {
    top: 10px;
    width: 1px;
    bottom: 0;
  }
}

.category-block-single .icon {
  overflow: hidden;
  display: block;
  width: 100%;
}

.category-block-single .icon img {
  transform: scale(1);
  transition: .4s;
}

.category-block-single h3 {
  color: #1a1f2b;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.category-block-single h3 a {
  transition: .4s;
}

.category-block-single h3:hover a {
  color: #56a700;
}

.category-block-single:hover .icon img {
  transform: scale(1.1);
}

.category-block {
  border: 1px solid #ececec;
}

@media (min-width: 768px) and (max-width: 991px) {
  .category-block [class*="col-"]:nth-child(1) > .category-block-single::before,
  .category-block [class*="col-"]:nth-child(2) > .category-block-single::before,
  .category-block [class*="col-"]:nth-child(3) > .category-block-single::before {
    width: 100%;
    height: 1px;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .category-block [class*="col-"]:nth-child(1) > .category-block-single::before {
    width: 0;
  }
}

.category-block .category-block-single {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .category-block .category-block-single {
    padding-bottom: 40px;
    margin-bottom: 0;
  }
}

/* Comment Section */
.comment-block-wrapper h3 {
  display: inline-block;
  font-size: 17px;
  margin-bottom: 20px;
  position: relative;
  text-transform: uppercase;
  color: #1a1f2b;
}

.single-comment {
  border: 1px solid #ececec;
  margin: 0 0 20px;
  padding: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.single-comment .comment-avatar {
  margin-right: 25px;
  width: 50px;
  height: 50px;
}

.single-comment .comment-text .author {
  font-size: 14px;
  font-weight: 700;
  color: #1a1f2b;
  margin-bottom: 5px;
}

.single-comment .comment-text .author a {
  color: inherit;
}

.single-comment .comment-text .author a:hover {
  color: #56a700;
}

.single-comment .comment-text .time {
  color: #999;
  margin: 0 0 15px;
  font-size: .93em;
  font-style: italic;
}

.single-comment .comment-text p {
  color: #999;
}

.single-comment .reply-btn {
  position: absolute;
  right: 30px;
  font-weight: 700;
  color: #56a700;
  border: 1px solid #56a700 !important;
  position: absolute;
  bottom: 10px;
  font-weight: 700;
  color: #56a700;
  border: 1px solid #56a700 !important;
  right: 10px;
}

@media (min-width: 576px) {
  .single-comment .reply-btn {
    top: 20px;
    bottom: auto;
    right: 20px;
  }
}

.single-comment .reply-btn:hover {
  color: #ffff;
}

@media (min-width: 768px) {
  .single-comment:nth-child(odd) {
    margin-left: 15px;
    width: calc(100% - 15px);
  }
}

.single-comment:last-child {
  margin-bottom: 0;
}

.reply-btn {
  font-size: .8em;
  width: 75px;
  height: 28px;
  line-height: 26px;
}

/* Product Section */
.pm-product {
  padding: 20px 0;
}

.pm-product .image {
  position: relative;
  margin-bottom: 10px;
}

.pm-product .image a {
  display: block;
}

.pm-product .image a img {
  display: block;
  width: 100%;
}

.pm-product .content {
  position: relative;
  overflow: hidden;
}

.pm-product .content h3 {
  color: #1a1f2b;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.35;
  margin-bottom: 8px;
}

.pm-product .content h3 a {
  transition: .4s;
}

.pm-product .content h3 a:hover {
  color: #56a700;
}

.pm-product .content .price {
  font-weight: 500;
}

.pm-product .content .price .old {
  text-decoration: line-through;
  color: #999;
  margin-right: 5px;
}

.pm-product .content .btn-block {
  margin-top: 12px;
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  bottom: 0;
  transform: translateY(110%);
  transition: .4s;
  z-index: 5;
  text-align: center;
  justify-content: center;
}

.pm-product .content:before {
  position: absolute;
  content: "";
  background: #fff;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.pm-product .hover-conents {
  position: absolute;
  right: 15px;
  top: 0%;
  opacity: 0;
  visibility: hidden;
  transition: .4s;
}

.pm-product .hover-conents .product-btns a {
  font-size: 24px;
}

.pm-product .hover-conents .product-btns a:hover {
  color: #56a700;
}

.pm-product:hover .content .btn-block {
  transform: translateY(0);
}

.pm-product:hover .content:before {
  z-index: 1;
}

.pm-product:hover .hover-conents {
  opacity: 1;
  top: 5px;
  visibility: visible;
}

.pm-product .onsale-badge {
  background: #56a700;
  color: #ffffff;
  display: block;
  font-size: 11px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  min-height: 0;
  min-width: 0;
  padding: 0 10px;
  pointer-events: none;
  position: absolute;
  right: auto;
  text-align: center;
  left: 0px;
  top: 0px;
  z-index: 3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.pm-product.product-type-list {
  padding: 0 !important;
}

@media (min-width: 480px) {
  .pm-product.product-type-list {
    display: flex;
  }
}

.pm-product.product-type-list .onsale-badge {
  left: 15px;
  top: 15px;
}

.pm-product.product-type-list .image {
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 15px;
}

@media (min-width: 480px) {
  .pm-product.product-type-list .image {
    width: 50%;
    padding-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .pm-product.product-type-list .image {
    width: 260px;
  }
}

.pm-product.product-type-list .image img {
  width: 100%;
}

.pm-product.product-type-list .content {
  overflow: unset;
  padding-bottom: 25px;
}

@media (max-width: 479px) {
  .pm-product.product-type-list .content {
    padding-left: 20px;
  }
}

@media (min-width: 480px) {
  .pm-product.product-type-list .content {
    padding-top: 25px;
    width: 50%;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .pm-product.product-type-list .content {
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .pm-product.product-type-list .content {
    width: 50%;
  }
}

.pm-product.product-type-list .content .btn-block {
  text-align: left;
  margin-top: 20px;
  position: relative;
  transform: translateY(0);
  height: auto;
}

.pm-product.product-type-list .content .btn-block a {
  margin-right: auto;
}

.pm-product.product-type-list .content:before {
  z-index: -1 !important;
}

.pm-product.sidebar-product .image {
  width: 81px;
  margin-right: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 15px;
  padding-left: 5px !important;
  padding-top: 5px !important;
}

.pm-product.sidebar-product .image:focus {
  box-shadow: none;
  outline: none;
}

.pm-product.sidebar-product .content {
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 0;
}

.pm-product.sidebar-product .content h3 {
  color: #1a1f2b;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3;
  margin: 0;
  text-transform: none;
}

.pm-product.sidebar-product .content .rating-widget .single-rating {
  font-size: 10px;
}

/* Product Count Down*/
.product-countdown {
  display: flex;
}

.product-countdown .single-countdown {
  margin-right: 10px;
  text-align: center;
}

.product-countdown .single-countdown .single-countdown__time {
  display: flex;
  height: 45px;
  width: 45px;
  background: #f0f0f0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.product-countdown .single-countdown .single-countdown__text {
  text-transform: uppercase;
  font-size: 10px;
}

/*Promo Products*/
.promo-image {
  margin-bottom: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .promo-image {
    margin-bottom: 17px;
  }
}

.promo-image img {
  width: 100%;
}

.overflow-image {
  overflow: hidden;
  display: block;
}

.overflow-image img {
  transform: scale(1);
  transition: .4s;
}

.overflow-image:hover img {
  transform: scale(1.1);
}

/* Sidebars Section */
.single-sidebar {
  margin-bottom: 40px;
}

.single-sidebar .promo-image {
  max-width: 300px;
}

.single-sidebar:last-child {
  margin-bottom: 0;
}

.sidebar-title {
  border-bottom: 2px solid #ececec;
  margin-bottom: 15px;
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 15px;
  color: #1a1f2b;
}

.sidebar-title:after {
  position: absolute;
  content: "";
  bottom: -2px;
  height: 2px;
  width: 60px;
  background: #56a700;
  left: 0;
}

/* Sidebar Search */
.site-mini-search {
  background: #fff;
  border: 1px solid #ececec;
  color: #999;
  font-family: Rubik, Arial, Helvetica, sans-serif;
  position: relative;
  width: 100%;
}

.site-mini-search input {
  background: none;
  border: none;
  height: 34px;
  padding: 0 40px 0 15px;
  width: 100%;
}

.site-mini-search input:focus {
  outline: none;
  box-shadow: none;
}

.site-mini-search button {
  background: none;
  border: none;
  color: #999;
  font-size: 14px;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 34px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-mini-search button:hover {
  color: #56a700;
}

/*  Sidebar List */
.sidebar-list {
  list-style: disc;
  padding-left: 15px;
}

.sidebar-list li {
  color: #1a1f2b;
  margin-bottom: 5px;
}

.sidebar-list li a {
  color: inherit;
  transition: .4s;
}

.sidebar-list li:hover {
  color: #56a700;
}

.sidebar-tag-list {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-tag-list a {
  background: #f1f1f1;
  border: none;
  color: #999;
  display: inline-block;
  font-size: 1em !important;
  margin: 0 5px 10px 0;
  padding: 3px 12px;
  text-transform: capitalize;
  transition: all .2s ease;
  border-radius: 4px;
}

.sidebar-tag-list a:hover {
  background: #56a700;
  color: #ffff;
}

/*Shop Sidebar*/
ul.sidebar-filter-list li {
  margin: 0 0 10px 0;
}

ul.sidebar-filter-list li a {
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  display: block;
}

ul.sidebar-filter-list li a:before {
  border: 1px solid #ececec;
  content: '\f383';
  display: block;
  font-family: 'Ionicons';
  font-size: 0;
  height: 16px;
  left: 0;
  line-height: 14px;
  position: absolute;
  text-align: center;
  top: 2px;
  width: 16px;
  border-radius: 3px;
}

ul.sidebar-filter-list li a:after {
  content: attr(data-count);
  position: absolute;
  right: 0;
}

ul.sidebar-filter-list li.active a:before, ul.sidebar-filter-list li:hover a:before {
  background: #56a700;
  border-color: #56a700;
  color: #fff;
  font-size: 10px;
}

/* Ranage Slider*/
.pm-range-slider {
  height: 4px;
  background: #e8e8e8;
}

.pm-range-slider .ui-slider-handle {
  width: 14px;
  height: 14px;
  top: -5px;
  background: #1d62b2;
  border-radius: 100%;
}

.pm-range-slider .ui-slider-handle:focus {
  outline: none;
  border: none;
}

.pm-range-slider .ui-widget-header {
  background: #1d62b2;
  top: 0;
  height: 100%;
}

.slider-price {
  margin-top: 25px;
  text-align: center;
}

.slider-price p {
  display: flex;
  align-items: center;
}

.slider-price p .btn {
  width: 100px;
}

.slider-price input#amount {
  background: transparent;
  border: transparent;
  color: #1a1f2b;
  text-align: left;
  word-spacing: 15px;
}

.sidebar-product {
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
}

.sidebar-product + .sidebar-product {
  margin-top: 20px;
}

/* Footer Section */
.site-footer {
  padding-top: 60px;
  color: #a7a7a7;
  background: #1a1f2b;
}

.site-footer .footer-title {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}

.site-footer a {
  color: inherit;
}

.site-footer .single-footer {
  padding-bottom: 60px;
}

.site-footer .single-footer.contact-block p {
  margin-bottom: 20px;
  padding-right: 30px;
  line-height: 1.45;
}

.site-footer .single-footer.contact-block p:last-child {
  margin-bottom: 0;
}

.footer-block-2 {
  padding: 60px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.13);
}

.social-icons a {
  font-size: 24px;
  line-height: inherit;
  margin-right: 15px;
}

.social-icons a:hover {
  color: #56a700;
}

.footer-list {
  color: #a7a7a7;
}

.footer-list li {
  margin-bottom: 15px;
  line-height: 1.25;
}

.footer-list li a {
  color: inherit;
}

.footer-list li a:hover {
  color: #56a700;
}

.footer-list li:last-child {
  margin-bottom: 0;
}

.footer-list.list-inline {
  display: flex;
  flex-wrap: wrap;
}

.footer-list.list-inline li {
  margin-bottom: 10px;
}

.footer-list.list-inline li a {
  padding-right: 10px;
  position: relative;
}

.footer-list.list-inline li a:after {
  position: absolute;
  content: "";
  background: #a7a7a7;
  right: 5px;
  width: 1px;
  top: 2px;
  bottom: 2px;
}

.footer-list.list-inline li:last-child a:after {
  width: 0;
}

.footer-copyright {
  text-align: center;
  background: #10151f;
  padding: 20px 0;
}

.footer-copyright p {
  margin-bottom: 0;
}

.footer-copyright a {
  color: #56a700;
}

.footer-copyright a:hover {
  text-decoration: underline;
}

.input-box-with-icon {
  background: #202530;
  border: 1px solid #3a3a3b;
  position: relative;
}

.input-box-with-icon input {
  background: none;
  border: none;
  color: #f1f1f1;
  display: block;
  font-size: .929em;
  height: 48px;
  line-height: 46px;
  max-width: 100%;
  padding: 0 20px;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  padding-right: 46px;
}

.input-box-with-icon input:focus {
  box-shadow: none;
  outline: none;
}

.input-box-with-icon button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  height: 46px;
  line-height: 46px;
  max-width: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 46px;
  transition: all .3s ease;
}

.input-box-with-icon button:hover {
  color: #56a700;
}

.input-box-with-icon button i {
  color: inherit;
}

/*
====> Pages
*/
/* Shop Page */
.shop-page-section.section-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}

/* Count Widget*/
.count-input-block {
  display: flex;
}

.count-input-block input {
  height: 45px;
  text-align: center;
  background: #28323c;
  border: none;
  color: #fff;
}

.count-input-block input:focus {
  background: #28323c;
  outline: none;
  border-color: transparent;
  box-shadow: none;
  color: #fff;
}

.count-input-block .count-input-btns {
  margin-left: 5px;
}

.count-input-block button {
  display: block;
  height: 20px;
  border: none;
  box-shadow: none;
  text-align: center;
  width: 20px;
  font-size: 14px;
  border-radius: 3px;
  border: 0;
  background: #28323c;
  padding: 0;
  color: #a6a6a6;
  margin-bottom: 5px;
  transition: .4s;
}

.count-input-block button:hover {
  background-color: #56a700;
  color: #fff;
}

.product-list-group li {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 2px solid #29323b;
}

.product-list-group li:last-child {
  border-bottom: 0;
}

.product-list-group .single-product-list {
  display: flex;
  align-items: center;
}

@media (max-width: 479px) {
  .product-list-group .single-product-list {
    flex-wrap: wrap;
    margin-bottom: -10px;
    padding-top: 30px;
  }
  .product-list-group .single-product-list .product-image,
  .product-list-group .single-product-list .product-name,
  .product-list-group .single-product-list .count-input-block,
  .product-list-group .single-product-list .product-price {
    margin-bottom: 10px;
  }
}

.product-list-group .product-image {
  width: 65px;
  border: 2px solid #29323b;
}

.product-list-group .product-name {
  color: #b2b2b2;
  margin-right: 40px;
}

.product-list-group .product-image,
.product-list-group .count-input-block {
  margin-right: 15px;
}

.product-list-group .count-input-block {
  min-width: 150px;
}

.product-list-group .product-price {
  font-weight: 500;
  font-size: 18px;
  color: #56a700;
}

/* Shop View Type Toolbar */
.shop-toolbar {
  padding-right: 15px;
}

.shop-toolbar a.sortting-btn {
  font-size: 18px;
  margin-right: 10px;
  color: #1a1f2b;
}

.shop-toolbar a.sortting-btn:hover {
  color: #56a700;
}

.shop-toolbar a.sortting-btn.active {
  color: #56a700;
}

.shop-toolbar .sorting-selection #input-sort,
.shop-toolbar .sorting-selection #input-limit {
  background: none;
  padding: 0;
  width: auto;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  color: #1a1f2b;
  font-weight: 300;
  text-align: center;
  height: 52px;
  border: 0;
}

.shop-toolbar .nice-select {
  height: 36px;
  line-height: initial;
  border-radius: 0;
  padding-left: 12px;
  display: flex;
  align-items: center;
  margin-left: 7px;
  width: 180px;
  border-color: #ececec;
}

.shop-toolbar .nice-select:focus {
  box-shadow: none;
}

.shop-product-wrap .pm-product .card-list-content {
  display: none;
}

@media (min-width: 992px) {
  .shop-product-wrap .pm-product.product-type-list .content {
    width: 70%;
  }
}

.shop-product-wrap .pm-product.product-type-list .hover-conents {
  display: none;
}

.shop-product-wrap .pm-product.product-type-list .card-list-content {
  display: block;
}

.shop-product-wrap .pm-product.product-type-list .card-list-content article p:first-child {
  padding-top: 16px;
}

.shop-product-wrap .pm-product.product-type-list .card-list-content .btn-block {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.shop-product-wrap .pm-product.product-type-list .card-list-content .btn-block .btn {
  height: 40px;
}

.shop-product-wrap .pm-product.product-type-list .card-list-content .btn-block a {
  margin-right: 10px;
}

@media (min-width: 480px) {
  .shop-product-wrap .pm-product.product-type-list .card-list-content .btn-block a {
    margin-right: 0;
  }
}

@media (min-width: 576px) {
  .shop-product-wrap .pm-product.product-type-list .card-list-content .btn-block a {
    margin-right: 20px;
  }
}

.shop-product-wrap .pm-product.product-type-list .card-list-content .btn-block .btn-options {
  flex-wrap: wrap;
  margin-top: 10px;
}

@media (min-width: 992px) {
  .shop-product-wrap .pm-product.product-type-list .card-list-content .btn-block .btn-options {
    margin-top: 0;
  }
}

.shop-product-wrap .pm-product.product-type-list .card-list-content .btn-block .btn-options i {
  font-size: 21px;
}

.shop-product-wrap .pm-product.product-type-list .grid-btn {
  display: none;
}

/*List Vewing Toolbar*/
.shop-product-wrap.with-pagination.list [class*="col"] {
  flex: 100%;
  max-width: 100%;
}

.shop-product-wrap.with-pagination.list [class*="col"]:last-child {
  border-bottom: 0;
}

.grid-four-column > [class*="col"]:first-child > .pm-product.product-type-list:after {
  width: 0;
}

.grid-four-column > [class*="col"] > .pm-product {
  position: relative;
  padding: 20px 20px;
}

.grid-four-column > [class*="col"] > .pm-product:before {
  background: #ececec;
  position: absolute;
  content: "";
  left: -1px;
  top: 10px;
  bottom: 10px;
  width: 1px;
}

.grid-four-column > [class*="col"] > .pm-product:after {
  background: #ececec;
  position: absolute;
  content: "";
  left: 10px;
  right: 10px;
  top: -1px;
  height: 1px;
}

.grid-four-column > [class*="col"] > .pm-product.product-type-list:before {
  opacity: 0;
}

.grid-four-column > .slick-list > .single-slide > .pm-product {
  position: relative;
  padding: 20px 20px;
}

.grid-four-column > .slick-list > .single-slide > .pm-product:before {
  background: #ececec;
  position: absolute;
  content: "";
  left: -1px;
  top: 10px;
  bottom: 10px;
  width: 1px;
}

.grid-four-column > .slick-list > .single-slide > .pm-product:after {
  background: #ececec;
  position: absolute;
  content: "";
  left: 10px;
  right: 10px;
  top: -1px;
  height: 1px;
}

.grid-four-column > .slick-list > .single-slide > .pm-product.product-type-list:before {
  opacity: 0;
}

.five-column {
  display: flex;
}

@media (min-width: 992px) {
  .five-column .cus-col-lg {
    flex: 20% !important;
  }
}

/* Product Details Page */
.product-details-section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pm-product-details {
  overflow: hidden;
}

.pm-product-details .image-block.left-thumbnail {
  display: flex;
}

.pm-product-details .image-block.left-thumbnail .main-image {
  width: 70%;
  order: 2;
}

.pm-product-details .image-block.left-thumbnail .main-image > div {
  position: relative;
}

@media (min-width: 992px) {
  .pm-product-details .image-block.left-thumbnail .main-image {
    width: 80%;
  }
}

.pm-product-details .image-block.left-thumbnail .elevate-gallery {
  flex-direction: column;
  margin-top: 0 !important;
  position: relative;
  padding-top: 0;
  z-index: 250;
  width: 20%;
  order: 1;
}

@media (min-width: 768px) {
  .pm-product-details .image-block.left-thumbnail .elevate-gallery {
    width: 30%;
  }
}

@media (min-width: 992px) {
  .pm-product-details .image-block.left-thumbnail .elevate-gallery {
    width: 20%;
  }
}

.pm-product-details .image-block.left-thumbnail .elevate-gallery a {
  margin-bottom: 15px;
}

.pm-product-details .image-block.left-thumbnail .elevate-gallery a:last-child {
  margin-bottom: 0;
}

.pm-product-details .image-block.right-thumbnail {
  display: flex;
}

.pm-product-details .image-block.right-thumbnail .main-image {
  width: 70%;
}

.pm-product-details .image-block.right-thumbnail .main-image > div {
  position: relative;
}

@media (min-width: 992px) {
  .pm-product-details .image-block.right-thumbnail .main-image {
    width: 80%;
  }
}

.pm-product-details .image-block.right-thumbnail .elevate-gallery {
  flex-direction: column;
  margin-top: 0 !important;
  position: relative;
  padding-top: 0;
  z-index: 250;
  width: 20%;
}

@media (min-width: 768px) {
  .pm-product-details .image-block.right-thumbnail .elevate-gallery {
    width: 30%;
  }
}

@media (min-width: 992px) {
  .pm-product-details .image-block.right-thumbnail .elevate-gallery {
    width: 20%;
  }
}

.pm-product-details .image-block.right-thumbnail .elevate-gallery a {
  margin-bottom: 15px;
}

.pm-product-details .image-block.right-thumbnail .elevate-gallery a:last-child {
  margin-bottom: 0;
}

.pm-product-details .image-block.gallery-thumb .single-thumb-image {
  border: 1px solid #ececec;
  margin-bottom: 30px;
}

.pm-product-details .image-block.image-list-block .single-thumb-image {
  border: 1px solid #ececec;
  margin-bottom: 30px;
}

.pm-product-details .image-block.image-list-block .single-thumb-image img {
  display: block;
  width: 100%;
}

.pm-product-details .description-block .header-block {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.pm-product-details .description-block .header-block h3 {
  color: #1a1f2b;
  font-size: 18px;
}

.pm-product-details .description-block .header-block .navigation {
  margin-left: auto;
}

.pm-product-details .description-block .header-block .navigation a {
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  color: #d1d1d1;
  font-size: 15px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  width: 30px;
}

.pm-product-details .description-block .header-block .navigation a:hover {
  background: #56a700;
  color: #fff;
  border-color: #56a700;
}

.pm-product-details .description-block .rating-block .rating-text {
  margin-left: 5px;
  margin-bottom: 0;
  color: #999;
}

.pm-product-details .description-block .rating-block .rating-text:hover a {
  color: #56a700;
}

.pm-product-details .description-block .price {
  color: #c61132;
  font-family: Rubik, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 30px;
}

.pm-product-details .description-block .price span {
  margin-right: 7px;
}

.pm-product-details .description-block .status {
  color: #1a1f2b;
  font-size: .857em;
  font-weight: 500;
  margin: 0 0 2s 5px 0;
  text-transform: uppercase;
  width: 100%;
}

.pm-product-details .description-block .status i {
  color: #56a700;
  margin-right: 5px;
}

.pm-product-details .description-block .add-to-cart {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid #ececec;
  margin: 25px 0;
  padding-top: 35px;
  width: 100%;
}

.pm-product-details .description-block .add-to-cart > div {
  margin-right: 15px;
}

.pm-product-details .description-block .add-to-cart .count-input-block {
  width: 70px;
}

.pm-product-details .description-block .add-to-cart .count-input-block input {
  background-attachment: #fff;
  background: #fff;
  border: 1px solid #ececec;
  color: rgba(0, 0, 0, 0.45098);
}

.pm-product-details .description-block .add-to-cart .count-input-block input:focus {
  border-color: #ececec;
}

.pm-product-details .description-block .add-to-cart .btn-block {
  width: auto;
}

.pm-product-details .description-block .add-to-cart .btn-block .btn {
  color: #56a700;
  height: 40px;
}

@media (min-width: 480px) {
  .pm-product-details .description-block .add-to-cart .btn-block .btn {
    width: 210px;
  }
}

.pm-product-details .description-block .add-to-cart .btn-block .btn:hover {
  color: #ffff;
}

.pm-product-details .description-block .count-input-block {
  width: 70px;
}

.pm-product-details .description-block .count-input-block input {
  background-attachment: #fff;
  background: #fff;
  border: 1px solid #ececec;
  color: rgba(0, 0, 0, 0.45098);
  border-radius: 0;
}

.pm-product-details .description-block .count-input-block input:focus {
  border-color: #ececec;
}

.pm-product-details .description-block .product-meta {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #1a1f2b;
  font-size: 14px;
}

.pm-product-details .description-block .product-meta .single-meta {
  color: #a43d21;
  font-weight: 400;
  text-transform: none;
}

.pm-product-details .description-block .product-meta .single-meta:hover {
  color: #56a700;
}

.pm-product-details .description-block .share-block-2 {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}

.pm-product-details .description-block .share-block-2 h4 {
  color: #1a1f2b;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
  text-transform: uppercase;
}

.pm-product-details .description-block .share-block-2 .social-btns li {
  margin-right: 15px;
}

.pm-product-details .description-block .share-block-2 .social-btns a {
  font-size: 14px;
}

.pm-product-details .description-block .grouped-product-block {
  border-top: 1px solid #ececec;
}

.pm-product-details .description-block .grouped-product {
  display: flex;
  padding-top: 25px;
}

.pm-product-details .description-block .grouped-product .count-input-block {
  margin-right: 10px;
}

.pm-product-details .description-block .grouped-product h3 {
  font-size: 14px;
  color: #1a1f2b;
  font-weight: 500;
  margin-bottom: 6px;
}

.pm-product-details .description-block .grouped-product h3 a:hover {
  color: #56a700;
}

.pm-product-details .description-block .grouped-product .price {
  font-size: 15px;
  line-height: 20px;
  color: #1a1f2b;
  font-weight: 400;
  margin-bottom: 0;
}

.pm-product-details .product-variable-block {
  border-top: 1px solid #ececec;
  padding-top: 35px;
  width: 100%;
}

.pm-product-details .product-variable-block h5 {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1a1f2b;
  margin-bottom: 10px;
}

.pm-product-details .product-variable-block .clear-var {
  color: #999;
  display: block;
  font-size: .9em;
  margin: 10px 0 20px;
}

.pm-product-details .product-variable-block .clear-var:hover {
  color: #56a700;
}

.pm-product-details .product-variable-block .color-select {
  background-color: #f9f9f9;
  border: 1px solid #ececec;
  height: 30px;
  padding: 5px 5px 5px 15px;
  width: auto;
  display: inline-block;
}

.pm-product-details .product-variable-block .color-select:focus {
  box-shadow: none;
  outline: none;
}

.pm-product-details .product-variable-block .price {
  border-bottom: 1px solid #1a1f2b;
  border-top: 1px solid #1a1f2b;
  color: #56a700;
  font-size: 18px;
  font-weight: 400;
  padding: 12px 0;
  margin-bottom: 20px;
  display: inline-block;
}

.old-price {
  color: #999;
  font-weight: normal;
  text-decoration: line-through;
}

/* Share Block 1*/
.social-btns {
  display: flex;
}

.social-btns li {
  margin-right: 10px;
}

.social-btns a {
  font-size: 11px;
  border-radius: 3px;
  height: 20px !important;
  padding: 0 6px;
  color: #fff;
  transition: ease .3s all;
}

.social-btns a i {
  margin-right: 4px;
}

.social-btns a.facebook {
  background: #4267b2;
}

.social-btns a.facebook:hover {
  background: #365899;
}

.social-btns a.twitter {
  background: #1b95e0;
}

.social-btns a.twitter:hover {
  background: #0c7abf;
}

.social-btns a.google {
  background: #fe6d4c;
}

.social-btns a.google:hover {
  background: #e75a3a;
}

.social-btns.social-btns-2 i {
  margin-right: 0;
}

.social-btns.social-btns-2 a {
  color: #1a1f2b;
  padding: 0;
}

.social-btns.social-btns-2 a:hover {
  color: #56a700;
}

/* Cart And Wishlist Button */
.btn-options {
  display: flex;
  flex-wrap: wrap;
}

.btn-options a {
  margin-right: 25px;
  transition: .4s;
  display: flex;
  align-items: center;
}

.btn-options a i {
  margin-right: 5px;
  transition: .4s;
  transform: rotateY(0deg);
  display: inline-block;
}

.btn-options a:hover {
  color: #56a700;
}

.btn-options a:hover i {
  transform: rotateY(180deg);
  color: #56a700;
}

/* Product Gallery Slider*/
a.gallary-item {
  margin: 0 5px;
  border: 1px solid #ececec;
  opacity: .6;
}

a.gallary-item:hover {
  opacity: 1;
  border-color: #56a700;
}

/*Elevate Zoom*/
.pm-product-details .image-block .zoomWrapper {
  margin: 0 auto;
}

.pm-product-details #product-view-gallery {
  margin-top: 20px;
}

/* Product zoom effect Mobile Control*/
@media (max-width: 479px) {
  .zoomWindow {
    display: none !important;
    visibility: hidden !important;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .zoomWindow {
    visibility: hidden !important;
  }
}

.elevate-gallery {
  display: flex;
}

@media (min-width: 992px) {
  .elevet-enable .zoomContainer {
    display: block !important;
  }
}

.zoomContainer {
  display: none !important;
}

.product-details-tab .nav-tabs {
  justify-content: center;
  background: #f2f2f2;
  border-bottom: 1px solid #ececec;
  margin: 0 0 30px 0;
  padding: 10px 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.product-details-tab .nav-tabs .nav-item .nav-link {
  background: transparent;
  border-color: transparent;
  font-size: 15px;
  font-weight: 500;
  color: #999;
}

.product-details-tab .nav-tabs .nav-item .nav-link.active {
  color: #56a700;
}

.product-details-tab .tab-content article p {
  font-size: 14px;
  line-height: 2.4;
  margin-bottom: 20px;
}

.product-details-tab .tab-content article p:last-child {
  margin-bottom: 0px;
}

.review-wrapper .title-lg {
  font-size: 17px;
  color: #1a1f2b;
}

.review-comment {
  display: flex;
}

.review-comment .avatar {
  width: 60px;
  margin-right: 10px;
}

.review-comment .text {
  border: 1px solid #ececec;
  width: calc(100% - 70px);
  padding: 1em 1em 0;
  border-radius: 3px;
  position: relative;
}

.review-comment .text:before {
  background: #fff;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  content: '';
  display: block;
  height: 10px;
  left: -6px;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
}

.review-comment .text .author {
  margin-bottom: 10px;
}

.review-comment .text p {
  padding-bottom: 10px;
}

/*Dynamic Rating*/
.rating-row p {
  margin-right: 3px;
  display: inline-block;
  font-weight: 500;
}

.rating-widget-block {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.rating-widget-block input {
  display: none;
}

.rating-widget-block label {
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  margin-bottom: 0;
  display: inline-block;
}

.rating-widget-block label:last-child {
  margin-right: 0;
}

.rating-widget-block label:before {
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  position: relative;
  display: block;
  font-size: 19px;
  color: #e3e3e3;
}

.rating-widget-block label:after {
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  display: block;
  font-size: 19px;
  color: #fec701;
  font-weight: 900;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.4s;
}

.rating-widget-block label:hover ~ label:after,
.rating-widget-block label:hover:after,
.rating-widget-block input:checked ~ label:after {
  opacity: 1;
}

/* Checkout Page */
/*-- Checkout Title --*/
.checkout-title {
  border-bottom: 1px solid #ececec;
  margin: 0 0 20px;
  padding-bottom: 10px;
  text-transform: none;
  width: 100%;
  color: #1a1f2b;
  font-size: 20px;
}

@media (min-width: 480px) {
  .checkout-title {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .checkout-title {
    font-size: 34px;
  }
}

/*-- Checkout Form --*/
.checkout-form {
  color: #d4d4d4;
}

.checkout-form label {
  color: #1a1f2b;
  font-family: Rubik, Arial, Helvetica, sans-serif;
  margin: 0 0 5px;
}

.checkout-form .nice-select {
  width: 100%;
  background-color: #f4f4f4;
  border: 1px solid transparent;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  height: 45px;
  color: #14191e;
  margin-bottom: 15px;
}

.checkout-form .nice-select::after {
  width: 6px;
  height: 6px;
  border-width: 1px;
  right: 20px;
  border-color: #565f69;
}

.checkout-form .nice-select .current {
  color: #565f69;
  display: block;
  line-height: 23px;
}

.checkout-form .nice-select .list {
  width: 100%;
}

.checkout-form input {
  width: 100%;
  background-color: #f4f4f4;
  border: 1px solid transparent;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #14191e;
  margin-bottom: 15px;
}

.checkout-form input[type="checkbox"] {
  width: auto;
}

.checkout-form input:focus {
  box-shadow: none;
  outline: none;
  border-color: #56a700;
}

.checkout-form .check-box {
  margin-right: 70px;
}

.checkout-form .check-box:last-child {
  margin-right: 0;
}

.checkout-form .check-box input[type="checkbox"] {
  display: none;
}

.checkout-form .check-box input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #14191e;
  margin: 0;
}

.checkout-form .check-box input[type="checkbox"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #565f69;
  content: "";
  transition: all 0.3s ease 0s;
}

.checkout-form .check-box input[type="checkbox"] + label::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  color: #1a1f2b;
  width: 20px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.checkout-form .check-box input[type="checkbox"]:checked + label::before {
  border: 2px solid #565f69;
}

.checkout-form .check-box input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

/*-- Shipping Form --*/
#shipping-form {
  display: none;
}

/*-- Checkout Cart Total --*/
.checkout-cart-total {
  background-color: #f9f9f9;
  color: #1a1f2b;
  padding: 35px 30px;
}

.checkout-cart-total .checkout-title {
  border-bottom: 1px solid #dfdfdf;
  font-size: 24px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}

@media only screen and (max-width: 479px) {
  .checkout-cart-total {
    padding: 30px;
  }
}

.checkout-cart-total h4 {
  line-height: 23px;
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
}

@media (max-width: 479px) {
  .checkout-cart-total h4 {
    font-size: 19px;
  }
}

.checkout-cart-total h4:first-child {
  margin-top: 0;
  margin-bottom: 25px;
}

.checkout-cart-total h4:last-child {
  margin-top: 15px;
  margin-bottom: 0;
}

.checkout-cart-total h4 span {
  float: right;
  display: block;
}

.checkout-cart-total ul {
  border-bottom: 1px solid #dfdfdf;
  color: #1a1f2b;
}

.checkout-cart-total ul li {
  color: inherit;
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  display: block;
  margin-bottom: 16px;
  overflow: hidden;
}

.checkout-cart-total ul li span {
  color: inherit;
  float: right;
}

.checkout-cart-total ul li span.left {
  float: left;
  width: 70%;
}

.checkout-cart-total p {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #1a1f2b;
  padding: 10px 0;
  border-bottom: 1px solid #dfdfdf;
  margin: 0;
}

.checkout-cart-total p span {
  float: right;
}

/*-- Checkout Payment Method --*/
.checkout-payment-method {
  background-color: #14191e;
  padding: 45px;
}

@media only screen and (max-width: 479px) {
  .checkout-payment-method {
    padding: 30px;
  }
}

/*-- Single Payment Method --*/
.single-method {
  margin-bottom: 20px;
}

.single-method:last-child {
  margin-bottom: 0;
}

.single-method input[type="radio"] {
  display: none;
}

.single-method input[type="radio"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #d4d4d4;
  margin: 0;
}

.single-method input[type="radio"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #565f69;
  content: "";
  transition: all 0.3s ease 0s;
}

.single-method input[type="radio"] + label::after {
  position: absolute;
  left: 5px;
  top: 5px;
  display: block;
  content: "";
  opacity: 0;
  background-color: #d4d4d4;
  width: 10px;
  text-align: center;
  height: 10px;
  transition: all 0.3s ease 0s;
}

.single-method input[type="radio"]:checked + label::before {
  border: 2px solid #565f69;
}

.single-method input[type="radio"]:checked + label::after {
  opacity: 1;
}

.single-method input[type="checkbox"] {
  display: none;
}

.single-method input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #d4d4d4;
  margin: 0;
}

.single-method input[type="checkbox"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  display: block;
  border: 2px solid #999999;
  content: "";
  transition: all 0.3s ease 0s;
}

.single-method input[type="checkbox"] + label::after {
  position: absolute;
  left: 4px;
  top: 4px;
  display: block;
  content: "";
  opacity: 0;
  background-color: #d4d4d4;
  width: 8px;
  text-align: center;
  height: 8px;
  transition: all 0.3s ease 0s;
}

.single-method input[type="checkbox"]:checked + label::before {
  border: 2px solid #565f69;
}

.single-method input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

.single-method p {
  display: none;
  margin-top: 8px;
  font-size: 14px;
  color: #d4d4d4;
  line-height: 23px;
}

/*-- Place Order --*/
.place-order {
  border-radius: 3px;
  height: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  color: #ffff;
  background-color: #56a700;
  transition: .4s;
}

.place-order:hover {
  background: #3c7400;
  border-color: #3c7400;
  color: #fff;
}

/*=====  End of 14. Checkout  ======*/
.checkout-area.checkout-area-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}

.term-block {
  margin-bottom: 10px;
}

.method-notice {
  display: flex;
  padding: 1em 2em 1em 3.5em;
  margin: 0 0 15px;
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  border-top: 3px solid #1e85be;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
}

.method-notice:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f249';
  display: inline-block;
  position: absolute;
  top: 1em;
  color: #1e85be;
  left: 1.5em;
}

.order-note-block label {
  font-family: Rubik, Arial, Helvetica, sans-serif;
  margin: 0 0 5px;
  line-height: 2;
  color: #666;
}

.order-note-block textarea {
  height: 100px;
  line-height: 1.5;
  padding: 10px;
  width: 100%;
  background: #f4f4f4;
  border: none;
}

.order-note-block textarea::-webkit-input-placeholder {
  color: #666;
}

.order-note-block textarea:-moz-placeholder {
  color: #666;
}

.order-note-block textarea::-moz-placeholder {
  color: #666;
}

.order-note-block textarea:-ms-input-placeholder {
  color: #666;
}

.order-note-block textarea:focus {
  box-shadow: none;
  outline: none;
}

.block-border {
  border-bottom: 1px solid #ececec;
  padding-bottom: 16px;
}

.checkout-quick-box {
  padding: 16px 32px 16px 20px;
  margin: 0 0 2em;
  position: relative;
  background-color: #f7f6f7;
  color: #515151;
  border-top: 3px solid #1e85be;
  list-style: none outside;
  width: auto;
  word-wrap: break-word;
}

.checkout-quick-box p {
  margin-bottom: 0;
}

.checkout-quick-box p i {
  color: #1e85be;
  margin-right: 10px;
}

.checkout-quick-box p a {
  color: #a43d21;
}

.checkout-quick-box p a:hover {
  color: #56a700;
}

.checkout-slidedown-box {
  display: none;
}

.quick-login-form {
  background: #fff;
  border: 1px solid #ececec;
  border-radius: 0;
  padding: 20px;
  color: #666;
  margin: 0 0 20px;
}

.quick-login-form .form-group input {
  margin-bottom: 0;
}

.quick-login-form label {
  display: block;
}

.quick-login-form input {
  border: 1px solid #ececec;
  height: 36px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 370px;
}

.pass-lost {
  color: #a43d21;
}

.pass-lost:hover {
  color: #56a700;
}

.checkout_coupon {
  border: 1px solid #d3ced2;
  padding: 20px;
  margin-bottom: 2em;
  text-align: left;
  border-radius: 5px;
}

.checkout_coupon input {
  background: #f4f4f4;
  border: none;
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  max-width: 100%;
  padding: 0 0 0 10px;
  vertical-align: middle;
  margin-right: 10px;
  width: 180px;
}

.checkout_coupon .btn {
  font-size: 14px;
  margin-top: 15px;
}

@media (min-width: 480px) {
  .checkout_coupon .btn {
    margin-top: 0;
  }
}

.quick-title {
  margin-bottom: 40px;
  line-height: 1.35;
  font-size: 28px;
  color: #1a1f2b;
}

@media (min-width: 768px) {
  .quick-title {
    font-size: 36px;
  }
}

.check-bx-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.check-bx-wrapper .check-box + .check-box {
  margin-top: 10px;
}

@media (min-width: 576px) {
  .check-bx-wrapper .check-box + .check-box {
    margin-top: 0;
  }
}

/* compare Page */
/*-- Compare Table --*/
.compare-table {
  color: #1a1f2b;
}

.compare-table::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee;
}

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

.compare-table .table tbody tr td:last-child {
  border-right: 1px solid #eeeeee;
}

.compare-table .table tbody tr td.first-column {
  min-width: 170px;
  font-size: 18px;
  font-weight: 600;
  color: #1a1f2b;
  margin: 0;
  line-height: 1;
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  padding-top: 70px;
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 40px;
}

.compare-table .table tbody tr td.product-image-title .image img {
  max-width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  clear: both;
  float: left;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-bottom: 7px;
  margin-top: 15px;
  text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #56a700;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td.pro-price {
  font-size: 16px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-color {
  font-size: 16px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-stock {
  font-size: 16px;
  font-weight: 600;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart {
  position: relative;
  background-color: #f6f6f6;
  border-radius: 50px;
  display: inline-block;
  width: 170px;
  padding: 10px 25px 10px 56px;
  transition: all 0.7s cubic-bezier(0.77, -1.5, 0.12, 3) 0s;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart i {
  width: 41px;
  height: 41px;
  background-color: #ffff;
  border-radius: 50px;
  display: block;
  float: left;
  font-size: 20px;
  text-align: center;
  line-height: 41px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease 0s;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  display: block;
  text-align: center;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.added {
  padding-left: 25px;
  padding-right: 56px;
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart.added i {
  left: calc(100% - 41px);
}

.compare-table .table tbody tr td.pro-addtocart .add-to-cart:hover {
  color: #56a700;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #1a1f2b;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  font-size: 14px;
}

/* Contact Page */
#googleMap {
  height: 550px;
  width: 100%;
}

.ct-single-side {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-right: 40px;
  padding-left: 40px;
}

@media (min-width: 992px) {
  .ct-single-side {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (min-width: 1200px) {
  .ct-single-side {
    padding-right: 100px;
    padding-left: 100px;
  }
}

.ct-description {
  color: #666;
}

.ct-article {
  padding-bottom: 30px;
}

.ct-article p {
  margin-bottom: 10px;
}

.contact-list {
  max-width: 380px;
}

.contact-list li {
  border-top: 1px solid #e4e4e4;
  list-style: none;
  padding: 10px 0;
}

.contact-list li i {
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}

.working-hour h3 {
  font-weight: 500;
  margin-bottom: 5px;
  color: #1a1f2b;
  font-size: 24px;
}

/* Contact page - 2*/
.ct-feature-section {
  padding: 40px 0 30px;
}

@media (min-width: 480px) {
  .ct-feature-section {
    padding: 60px 0 50px;
  }
}

@media (min-width: 992px) {
  .ct-feature-section {
    padding: 90px 0 80px;
  }
}

.ct-section-title {
  margin-bottom: 35px;
}

.ct-section-title h2 {
  font-size: 23px;
  color: #1a1f2b;
}

@media (min-width: 576px) {
  .ct-section-title h2 {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .ct-section-title h2 {
    font-size: 30px;
  }
}

.ct-section-title-2 h2 {
  font-weight: 900;
  text-transform: uppercase;
  color: #1a1f2b;
  font-size: 23px;
}

@media (min-width: 576px) {
  .ct-section-title-2 h2 {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .ct-section-title-2 h2 {
    font-size: 30px;
  }
}

.ct-section-title-2 p {
  font-size: 14px;
}

.ct-feature {
  text-align: center;
}

.ct-feature .icon {
  border: 0;
  border-radius: 100%;
  border: 3px solid #1a1f2b;
  color: #fff;
  height: 70px;
  width: 70px;
  margin: 0 auto 20px;
  text-align: center;
  transition: color .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1a1f2b;
}

@media (min-width: 768px) {
  .ct-feature .icon {
    height: 95px;
    width: 95px;
    margin: 0 auto 35px;
  }
}

.ct-feature .icon i {
  font-size: 34.4px;
}

.ct-feature .icon:hover {
  background: #56a700;
  color: #ffff;
  border-color: #56a700;
}

.ct-feature h3 {
  color: #1a1f2b;
  line-height: 1.35;
  margin-bottom: 5px;
}

.ct-feature p {
  line-height: 1.65;
}

.contact-page-section.section-padding {
  section-padding: 80px 0;
}

/* Blog Page */
.blog-post .blog-content header {
  margin-bottom: 20px;
}

.blog-post .blog-content .post-category {
  margin-bottom: 10px;
}

.blog-post .blog-content .post-category i {
  margin-right: 5px;
}

.blog-post .blog-content .post-category a:hover {
  color: #56a700;
}

.blog-post .blog-content .blog-title {
  display: inline-block;
  font-size: 25px;
  margin-bottom: 16px;
  padding: 0;
}

.blog-post .blog-content .blog-title a {
  color: #1a1f2b;
  transition: .4s;
}

.blog-post .blog-content .blog-title a:hover {
  color: #56a700;
}

.blog-post .blog-content .post-meta {
  font-size: 13.76px;
}

.blog-post .blog-content .post-meta .post-separator {
  margin: 0 10px;
}

.blog-post .blog-content .post-meta span {
  font-size: 13px;
}

.blog-post .blog-content .post-meta span i {
  margin-right: 5px;
}

.blog-post .blog-content article p {
  margin: 0;
  padding-bottom: 20px;
  line-height: 1.65;
}

.blog-post .blog-content footer {
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 480px) {
  .blog-post .blog-content footer {
    display: flex;
  }
}

.blog-post .blog-content footer div {
  flex: 50%;
  display: block;
  margin-bottom: 15px;
}

@media (min-width: 480px) {
  .blog-post .blog-content footer div {
    display: static;
    margin-bottom: 0;
  }
}

.blog-post .blog-btn .btn {
  color: #56a700;
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog-post .blog-btn .btn {
    width: 130px;
  }
}

.blog-post .blog-btn .btn:hover {
  color: #ffff;
}

.blog-post.card-style-list .blog-title {
  font-size: 30px;
}

.blog-post.card-style-list--2 .blog-date {
  color: #56a700;
  font-size: 22px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 15px;
}

.blog-post.card-style-list--2 .blog-date .day {
  font-size: 44px;
}

.blog-post.card-style-list--2 .blog-date .year:before {
  content: '/';
  display: inline;
  margin-left: -2px;
}

.blog-post.card-style-list--2 .blog-date span {
  margin-right: 5px;
}

.blog-post.card-style-list--2 .blog-title {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.blog-post.card-style-list--2 .blog-title a {
  color: #56a700;
}

.blog-post.card-style-list--2 article p {
  padding-bottom: 10px;
}

.blog-post.card-style-list--2 .blog-btn a:hover {
  color: inherit;
  text-decoration: underline;
}

.blog-post.post-details .blog-title {
  font-size: 30px;
}

.blog-post.post-details .blog-content {
  margin-top: 30px;
}

.blog-post.post-details .blog-content header {
  margin-bottom: 25px;
}

.blog-post.post-details .blog-content blockquote {
  background: #f6f6f6;
  border: 1px solid #ececec;
  border-left: 5px solid #56a700;
  font-size: 14px;
  font-style: italic;
  line-height: 26px;
  margin: 30px 0 30px 30px;
  padding: 30px 45px;
}

.blog-post.post-details .blog-meta {
  font-size: 13px;
  padding: 10px;
  color: #1a1f2b;
  font-size: 13px;
  padding: 15px 0;
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
  margin-top: 10px;
}

.blog-post.post-details .blog-meta a {
  color: #56a700;
}

.blog-post.post-details .blog-meta a:hover {
  text-decoration: underline;
}

.share-block {
  text-align: center;
}

.share-block h3 {
  background: none;
  color: #8c8c8c;
  font-size: 12px;
  line-height: 26px;
  margin: 0 20px;
  text-transform: uppercase;
  width: auto;
}

.social-links {
  display: flex;
}

.social-links .single-social {
  color: #1a1f2b;
  background: #f7f7f7;
  border-radius: 100%;
  color: #353535;
  display: inline-block;
  font-size: 12px;
  height: 26px;
  line-height: 26px;
  margin-right: 10px;
  text-align: center;
  width: 26px;
}

.social-links .single-social:hover {
  color: #fff;
  background: #56a700;
}

.blog-posts-container .blog-post {
  border-bottom: 1px solid #ececec;
  margin: 0 0 50px;
  padding: 0 0 50px 0;
  width: 100%;
}

.blog-posts-container .blog-post:last-child {
  padding-bottom: 0;
  border: none;
}

.related-post-block h3 {
  display: inline-block;
  font-size: 20px;
  text-transform: uppercase;
  color: #1a1f2b;
}

.related-post .post-date {
  color: #999;
  display: block;
  font-style: italic;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

.related-post .post-title {
  font-size: 1.143em;
  color: #1a1f2b;
}

.related-post .post-title a {
  display: inline-block;
}

.related-post .post-title:hover a {
  color: #56a700;
}

.blog-image-gallery {
  padding: 5px;
  border: 1px solid #cecece;
  border-radius: 3px;
  position: relative;
}

.blog-image-gallery .slick-arrow {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  display: block;
  width: 40px;
  height: 40px;
  margin-top: 0px;
  background: black 0 0 url(../image/icon-logo/arrow-icon.png) no-repeat;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: all .2s;
  left: 60px;
  z-index: 9;
  opacity: 0;
  background-position: 10% 20%;
}

.blog-image-gallery .slick-arrow:before {
  opacity: 0;
}

.blog-image-gallery .slick-arrow.slick-next {
  left: 100%;
  margin-left: -60px;
  right: 0;
  transform: translate(-100%, -50%);
  background-position: 90% 20% !important;
}

.blog-image-gallery:hover .slick-arrow {
  opacity: 1;
  left: 10px;
}

.blog-image-gallery:hover .slick-arrow.slick-next {
  left: 100%;
  margin-left: -10px;
}

.blog-page-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 576px) {
  .blog-page-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 992px) {
  .blog-page-section {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.blog-page-section.with-sidebar {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .blog-page-section.with-sidebar {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

/* Pagination */
.pagination-widget {
  text-align: right;
}

.pagination-widget p {
  color: inherit;
}

.site-pagination a {
  background: #fff;
  border: 1px solid #ececec;
  color: #1a1f2b;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  height: 32px;
  line-height: 30px;
  margin-right: 7px;
  min-width: 0;
  padding: 0;
  text-align: center;
  width: 32px;
  transition: all .2s ease;
  border-radius: 4px;
}

.site-pagination a:hover {
  color: #56a700;
}

.site-pagination a.active, .site-pagination a:hover {
  background: #56a700;
  border-color: #56a700;
  color: #fff;
}

.blog-page-section.with-sidebar .single-block {
  margin-bottom: 40px;
}

.blog-page-section.with-sidebar .single-block:last-child {
  margin-bottom: 0;
}

.replay-form-wrapper h3 {
  font-size: 17px;
  margin: 20px 0;
  position: relative;
  text-transform: uppercase;
}

.replay-form-wrapper p {
  color: #999;
  font-size: 12px;
  padding-bottom: 10px;
}

.blog-slider .single-slide {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.blog-slider .single-slide img {
  width: 100%;
}

.blog-slider .slick-slide:before {
  top: 20px;
  bottom: 10px;
}

/* Cart Page */
.cart_area .page-section-title {
  margin-bottom: 40px;
}

.cart_area .page-section-title h1 {
  font-size: 22px;
}

@media (min-width: 480px) {
  .cart_area .page-section-title h1 {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .cart_area .page-section-title h1 {
    font-size: 34px;
  }
}

/*-- Cart Table --*/
.cart-table {
  color: #d4d4d4;
}

.cart-table .table {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 575px) {
  .cart-table .table {
    border-top: 8px solid #14191e;
  }
}

.cart-table .table thead {
  background-color: transparent;
}

@media only screen and (max-width: 575px) {
  .cart-table .table thead {
    display: none;
  }
}

.cart-table .table thead tr {
  border-bottom: 1px solid #ececec;
}

.cart-table .table thead tr th {
  text-align: center;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  color: #1a1f2b;
  border-right: 1px solid #ececec;
  padding: 20px 10px;
  font-family: Rubik, Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.cart-table .table tbody {
  color: #1a1f2b;
  font-weight: 500;
}

.cart-table .table tbody tr {
  transition: .4s;
}

.cart-table .table tbody tr:not(:last-child):hover {
  background: #f6f6f6;
}

.cart-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 20px 10px;
  vertical-align: middle;
  border-top: 1px solid #ececec;
  border-right: 1px solid #ececec;
}

@media only screen and (max-width: 575px) {
  .cart-table .table tbody tr td {
    display: block;
    width: 100%;
    max-width: none;
    padding: 15px;
    text-align: left;
  }
}

.cart-table .table tbody tr td .coupon-block {
  display: flex;
  border: 1px solid #ececec;
  margin: 0 0 20px;
  padding: 25px !important;
  flex-wrap: wrap;
}

.cart-table .table tbody tr td .coupon-block .coupon-text {
  flex: 100%;
}

@media (min-width: 576px) {
  .cart-table .table tbody tr td .coupon-block .coupon-text {
    flex: 50%;
    text-align: left;
  }
}

.cart-table .table tbody tr td .coupon-block .coupon-text input {
  border: 1px solid #ececec;
  height: 50px;
  line-height: 48px;
  padding: 6px 6px 5px;
  background: #f4f4f4;
  font-weight: 500;
  width: 100%;
}

@media (min-width: 576px) {
  .cart-table .table tbody tr td .coupon-block .coupon-text input {
    width: auto;
  }
}

.cart-table .table tbody tr td .coupon-block .coupon-text input:focus {
  outline: none;
}

.cart-table .table tbody tr td .coupon-block .coupon-btn {
  flex: 100%;
  margin-top: 10px;
}

@media (min-width: 576px) {
  .cart-table .table tbody tr td .coupon-block .coupon-btn {
    flex: 50%;
    margin-top: 0px;
    text-align: right;
  }
}

.cart-table .table tbody tr td .coupon-block label {
  display: none;
}

.cart-table th.pro-thumbnail,
.cart-table td.pro-thumbnail {
  max-width: 160px;
  min-width: 32px;
  width: 160px;
}

@media only screen and (max-width: 575px) {
  .cart-table th.pro-thumbnail a,
  .cart-table td.pro-thumbnail a {
    width: 160px;
  }
}

.cart-table th.pro-title,
.cart-table td.pro-title {
  min-width: 200px;
}

.cart-table td.pro-thumbnail a {
  display: block;
}

.cart-table td.pro-thumbnail a img {
  width: 70px;
  background-color: #f6f7f8;
}

.cart-table td.pro-title a {
  font-size: 16px;
  color: #1a1f2b;
}

.cart-table td.pro-title a:hover {
  color: #56a700;
}

.cart-table td.pro-price span {
  display: block;
  font-size: 15px;
}

.cart-table td.pro-quantity .pro-qty {
  width: 50px;
  display: block;
  margin: auto;
}

.cart-table td.pro-quantity .pro-qty input {
  padding-right: 0;
  font-size: 15px;
  border-radius: 0;
  background: transparent;
  border: 1px solid #ececec;
  color: #1a1f2b;
}

@media only screen and (max-width: 575px) {
  .cart-table td.pro-quantity .pro-qty {
    margin: 0;
  }
}

.cart-table td.pro-subtotal span {
  display: block;
  font-size: 15px;
}

.cart-table td.pro-addtocart button {
  width: 140px;
  border-radius: 50px;
  height: 36px;
  border: 1px solid #56a700;
  line-height: 24px;
  padding: 5px 20px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  background-color: #56a700;
}

.cart-table td.pro-remove a {
  display: block;
  font-weight: 600;
}

.cart-table td.pro-remove a i {
  font-size: 15px;
}

.cart-table td.pro-remove a:hover {
  color: #56a700;
}

@media only screen and (max-width: 575px) {
  .cart-table td.pro-remove a {
    width: 60px;
    text-align: center;
  }
}

/*-- Calculate Shipping --*/
.calculate-shipping {
  margin-bottom: 23px;
}

.calculate-shipping h4 {
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
  color: #d4d4d4;
}

.calculate-shipping form .nice-select {
  width: 100%;
  border-radius: 50px;
  height: 36px;
  border: 1px solid #565f69;
  line-height: 24px;
  padding: 5px 20px;
  background-color: transparent;
}

.calculate-shipping form .nice-select::after {
  border-color: #565f69;
}

.calculate-shipping form .nice-select .current {
  display: block;
  line-height: 24px;
  font-size: 14px;
  color: #d4d4d4;
}

.calculate-shipping form input {
  width: 100%;
  border-radius: 50px;
  height: 36px;
  border: 1px solid #565f69;
  line-height: 24px;
  padding: 5px 20px;
  color: #d4d4d4;
  background-color: transparent;
}

.calculate-shipping form input::-webkit-input-placeholder {
  color: #d4d4d4;
}

.calculate-shipping form input:-moz-placeholder {
  color: #d4d4d4;
}

.calculate-shipping form input::-moz-placeholder {
  color: #d4d4d4;
}

.calculate-shipping form input:-ms-input-placeholder {
  color: #d4d4d4;
}

.calculate-shipping form input:focus {
  outline: none;
  box-shadow: none;
}

.calculate-shipping form input[type="submit"] {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: #56a700;
  border-color: #56a700;
  width: 140px;
  cursor: pointer;
}

/*-- Discount Coupon --*/
.discount-coupon h4 {
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
  color: #d4d4d4;
}

.discount-coupon form input {
  width: 100%;
  border-radius: 50px;
  height: 36px;
  border: 1px solid #565f69;
  line-height: 24px;
  padding: 5px 20px;
  color: #d4d4d4;
  background-color: transparent;
}

.discount-coupon form input::-webkit-input-placeholder {
  color: #d4d4d4;
}

.discount-coupon form input:-moz-placeholder {
  color: #d4d4d4;
}

.discount-coupon form input::-moz-placeholder {
  color: #d4d4d4;
}

.discount-coupon form input:-ms-input-placeholder {
  color: #d4d4d4;
}

.discount-coupon form input:focus {
  outline: none;
  box-shadow: none;
}

.discount-coupon form input[type="submit"] {
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  background-color: #56a700;
  border-color: #56a700;
  width: 140px;
  line-height: 27px;
  cursor: pointer;
}

/*-- Cart Summary --*/
.cart-summary {
  float: right;
  width: 100%;
  margin-left: auto;
}

@media (min-width: 992px) {
  .cart-summary {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-summary {
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .cart-summary {
    margin-left: 0;
  }
}

.cart-summary .cart-summary-wrap {
  background-color: #ffff;
  padding: 30px 30px;
  margin-bottom: 20px;
  border: 1px solid #ececec;
  color: #1a1f2b;
}

@media only screen and (max-width: 479px) {
  .cart-summary .cart-summary-wrap {
    padding: 25px 30px;
  }
}

.cart-summary .cart-summary-wrap h4 {
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px;
  text-align: right;
}

.cart-summary .cart-summary-wrap p {
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
}

.cart-summary .cart-summary-wrap p span {
  float: right;
}

.cart-summary .cart-summary-wrap h2 {
  border-top: 1px solid #ececec;
  padding-top: 9px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  margin: 0;
}

.cart-summary .cart-summary-wrap h2 span {
  float: right;
}

.cart-summary .cart-summary-button {
  overflow: hidden;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-button {
    display: flex;
    justify-content: flex-start;
  }
}

.cart-summary .cart-summary-button .c-btn {
  margin-top: 10px;
  width: 140px;
  border-radius: 50px;
  height: 36px;
  border: 1px solid #1a1f2b;
  line-height: initial;
  padding: 5px 20px;
  background-color: transparent;
  margin-left: 20px;
  float: right;
  color: #1a1f2b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-summary .cart-summary-button .c-btn:last-child {
  margin-left: 0;
}

.cart-summary .cart-summary-button .c-btn.checkout-btn {
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  background-color: #56a700;
  border-color: #56a700;
}

@media only screen and (max-width: 767px) {
  .cart-summary .cart-summary-button .c-btn {
    margin-left: 0;
    margin-right: 20px;
  }
  .cart-summary .cart-summary-button .c-btn:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .cart-summary .cart-summary-button .c-btn {
    margin-left: 0;
    margin-right: 10px;
  }
  .cart-summary .cart-summary-button .c-btn:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .cart-summary .cart-summary-button .c-btn {
    width: 130px;
  }
}

.cart-block-title h2 {
  color: #1a1f2b;
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 35px 0;
  text-align: center;
  text-transform: uppercase;
}

/* My Account */
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #cecece;
  border-bottom: none;
  color: #1a1f2b;
  font-weight: 500;
  font-size: 12px;
  display: block;
  padding: 15px 15px 13px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #cecece;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #56a700;
  color: #ffff;
}

.myaccount-tab-menu a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

/*-- My Account Content -*/
.myaccount-content {
  font-size: 14px;
  border: 1px solid #cecece;
  padding: 30px;
  color: #1a1f2b;
}

@media only screen and (max-width: 479px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content h3 {
  border-bottom: 1px dashed #cecece;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.myaccount-content .welcome a {
  color: inherit;
}

.myaccount-content .welcome a:hover {
  color: #56a700;
}

.myaccount-content .welcome strong {
  font-weight: 600;
}

.myaccount-content a.edit-address-btn {
  background: none;
  border: none;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #d4d4d4;
  border-color: #d4d4d4;
  padding: 10px 20px;
  border-radius: 3px;
}

.myaccount-content a.edit-address-btn i {
  padding-right: 5px;
}

.myaccount-content a.edit-address-btn:hover {
  background-color: #56a700;
}

.myaccount-content button.save-change-btn {
  background: none;
  border: none;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #d4d4d4;
  border-color: #d4d4d4;
  width: 140px;
  padding: 10px 0;
  border-radius: 3px;
}

.myaccount-content button.save-change-btn:hover {
  background-color: #56a700;
}

/*-- My Account Table -*/
.myaccount-table {
  white-space: nowrap;
  font-size: 15px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  background: #14191E;
  color: #fff;
  border-color: #565f69;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 20px 10px;
  vertical-align: middle;
  border-color: #565f69;
}

.myaccount-table table td a:hover,
.myaccount-table .table td a:hover {
  color: #56a700;
}

.saved-message {
  border-top: 3px solid #56a700;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  font-size: 13px;
  padding: 20px;
}

/*-- My Account Details Form -*/
.account-details-form h4 {
  margin: 0;
}

.account-details-form input {
  display: block;
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 0;
  line-height: 24px;
  padding: 11px 25px;
  color: #1a1f2b;
  background: transparent;
  border-radius: 3px;
}

.account-details-form input:focus {
  border-color: #56a700;
  box-shadow: none;
  outline: none;
}

/*=====  End of 16. My Account  ======*/
/* Login Register */
/*-- Login Title --*/
.login-title {
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 30px;
  color: #1a1f2b;
}

@media (min-width: 768px) {
  .login-title {
    font-size: 30px;
  }
}

/*-- Login Form --*/
.login-form {
  border: 1px solid #d3ced2;
  padding: 20px;
  margin: 2em 0;
  text-align: left;
  border-radius: 5px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .login-form {
    padding: 15px;
  }
}

.login-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 400;
  text-transform: capitalize;
}

.login-form .nice-select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #565f69;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  height: 45px;
  color: #d4d4d4;
  margin-bottom: 15px;
}

.login-form .nice-select::after {
  width: 6px;
  height: 6px;
  border-width: 1px;
  right: 20px;
  border-color: #565f69;
}

.login-form .nice-select .current {
  color: #d4d4d4;
  display: block;
  line-height: 23px;
}

.login-form .nice-select .list {
  width: 100%;
}

.login-form input {
  background: #f4f4f4;
  border: none;
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  max-width: 100%;
  width: 100%;
  padding: 0 0 0 10px;
  vertical-align: middle;
}

.login-form input[type="checkbox"] {
  width: auto;
}

.login-form input:focus {
  border-color: #56a700;
  box-shadow: none;
  outline: none;
}

.login-form .check-box {
  float: left;
  margin-right: 70px;
}

.login-form .check-box:last-child {
  margin-right: 0;
}

.login-form .check-box input[type="checkbox"] {
  display: none;
}

.login-form .check-box input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #d4d4d4;
  margin: 0;
}

.login-form .check-box input[type="checkbox"] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #565f69;
  content: "";
  transition: all 0.3s ease 0s;
}

.login-form .check-box input[type="checkbox"] + label::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "\f00c";
  font-family: Fontawesome;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  color: #d4d4d4;
  width: 20px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.login-form .check-box input[type="checkbox"]:checked + label::before {
  border: 2px solid #565f69;
}

.login-form .check-box input[type="checkbox"]:checked + label::after {
  opacity: 1;
}

/*-- Place Order --*/
.register-button {
  display: block;
  margin-top: 40px;
  width: 140px;
  border-radius: 0;
  height: 36px;
  border: none;
  line-height: 24px;
  padding: 6px 20px;
  float: left;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffff;
  border-radius: 3px;
}

/*=====  End of 17. Login Register  ======*/
.entry-header {
  margin-bottom: 40px;
}

.entry-header .entry-title {
  font-size: 30px;
  line-height: 1.35;
  margin: 0 0 5px;
  text-transform: uppercase;
  color: #1a1f2b;
}

@media (min-width: 992px) {
  .entry-header .entry-title {
    font-size: 36px;
  }
}

/* About Page */
.about-text {
  color: #1a1f2b;
}

.about-text h2 {
  font-size: 26px;
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .about-text h2 {
    font-size: 32px;
    margin-bottom: 40px;
  }
}

.about-text p {
  line-height: 24px;
}

.about-text .btn {
  width: 145px;
  height: 40px;
  font-weight: 500;
  text-transform: uppercase;
}

.about-two-sp.sec-1 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media (max-width: 74.9375em) {
  .skill-progress,
  .about-text {
    padding: 60px 0;
  }
}

@media (max-width: 74.9375em) {
  .about-text-2 {
    padding: 0;
  }
}

@media (max-width: 74.9375em) {
  .about-img-2 {
    padding: 0;
  }
}

.fact-area .row [class^="col"]:nth-child(odd) .fact {
  background-color: #f3f3f3;
}

.fact-area .row [class^="col"]:nth-child(even) .fact {
  background-color: #e8e8e8;
}

@media (max-width: 61.94em) {
  .fact-area .row [class^="col"]:nth-child(1) .fact,
  .fact-area .row [class^="col"]:nth-child(4) .fact {
    background-color: #f3f3f3;
  }
  .fact-area .row [class^="col"]:nth-child(2) .fact,
  .fact-area .row [class^="col"]:nth-child(3) .fact {
    background-color: #e8e8e8;
  }
}

@media (max-width: 35.94em) {
  .fact-area .row [class^="col"]:nth-child(odd) .fact {
    background-color: #f3f3f3;
  }
  .fact-area .row [class^="col"]:nth-child(even) .fact {
    background-color: #e8e8e8;
  }
}

.fact {
  padding: 90px 15px;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.fact__icon {
  margin-right: 20px;
}

.fact__content {
  text-align: left;
  color: #1a1f2b;
}

.fact__content h3 {
  font-size: 30px;
  line-height: 1;
  margin-bottom: 0;
}

.fact__content p {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
}

.skill-progress h2 {
  font-family: Raleway;
  font-weight: 900;
  color: #1a1f2b;
}

.skill-progress__single:not(:last-child) {
  margin-bottom: 40px;
}

.skill-progress__title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  margin-left: 50px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  line-height: 1.6;
}

.skill-progress .progress {
  background: #f7f7f7;
  border-radius: 3px;
  height: 18px;
  overflow: visible;
}

.skill-progress .progress-bar {
  background: #7b7b7b;
  border-radius: 3px;
  margin-left: 2px;
  position: relative;
}

.skill-progress .progress-bar span {
  background: #3e3e3e;
  border: 3px solid #a0a0a0;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  height: 40px;
  left: -12px;
  padding-top: 0;
  position: absolute;
  text-align: center;
  top: -13px;
  width: 40px;
  line-height: 33px;
}

/* FAQ Page */
.faq-section {
  padding: 40px 0px 40px;
  color: #1a1f2b;
}

@media (min-width: 480px) {
  .faq-section {
    padding: 90px 0px 90px;
  }
}

@media (min-width: 992px) {
  .faq-section {
    padding: 90px 0px 90px;
  }
}

.faq-section .section-header {
  margin-bottom: 18px;
}

@media (min-width: 480px) {
  .faq-section .section-header {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .faq-section .section-header {
    margin-bottom: 25px;
  }
}

.faq-section .section-header h2 {
  text-transform: uppercase;
  line-height: 1.35;
  margin-bottom: 5px;
  font-size: 22px;
}

@media (min-width: 480px) {
  .faq-section .section-header h2 {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .faq-section .section-header h2 {
    font-size: 36px;
  }
}

.faq-section .section-article {
  margin-bottom: 30px;
}

@media (min-width: 480px) {
  .faq-section .section-article {
    margin-bottom: 35px;
  }
}

@media (min-width: 768px) {
  .faq-section .section-article {
    margin-bottom: 45px;
  }
}

.faq-section .section-article h4 {
  line-height: 1.35;
  margin-bottom: 5px;
  font-size: 16px;
}

@media (min-width: 768px) {
  .faq-section .section-article h4 {
    font-size: 18px;
  }
}

.faq-section .section-article p {
  margin-bottom: 10px;
}

.faq-section .section-article p:last-child {
  margin-bottom: 0;
}

.site-faq-accordion .card {
  margin-bottom: 10px;
}

.site-faq-accordion .card .btn-link {
  width: 100%;
  height: auto;
  padding: 0;
  height: 100%;
  padding: 15px 35px 15px 10px;
  justify-content: flex-start;
  color: #56a700;
  line-height: 1.5;
  display: flex;
  border-width: 1px;
  border-style: solid;
  border-color: #56a700 #56a700 #ececec #56a700;
  border-radius: 0;
  text-decoration: none;
  white-space: unset;
  text-align: left;
}

@media (min-width: 480px) {
  .site-faq-accordion .card .btn-link {
    padding: 15px 30px 15px 10px;
  }
}

@media (min-width: 768px) {
  .site-faq-accordion .card .btn-link {
    padding: 15px 10px 15px 25px;
  }
}

.site-faq-accordion .card .btn-link.collapsed {
  border-color: #ececec;
  color: #1a1f2b;
}

.site-faq-accordion .card .btn-link.collapsed:before {
  content: "\f067";
}

.site-faq-accordion .card .btn-link:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 15px;
  position: absolute;
  right: 0;
  margin-right: 15px;
}

.site-faq-accordion .card .card-body {
  padding: 20px 25px;
  border-bottom: 1px solid #56a700;
  border-left: 1px solid #56a700;
  border-right: 1px solid #56a700;
  padding: 20px 25px;
}

.site-faq-accordion .card-header {
  padding: 0;
  background: #f9f9f9;
  border-radius: 0;
  border: 0;
}

/* 404 Page */
.error-section {
  padding-top: 100px;
  padding-bottom: 100px;
  border-top: 1px solid #f0f0f0;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 768px) {
  .error-section {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.error-content {
  text-align: center;
}

.error-content h2 {
  color: #1a1f2b;
  text-transform: uppercase;
  font-size: 30px;
}

@media (min-width: 768px) {
  .error-content h2 {
    font-size: 35px;
  }
}

.error-content p {
  color: #1a1f2b;
  font-weight: 300;
  margin: 20px auto 40px;
  text-align: center;
  line-height: 1.5;
  font-size: 19px;
}

@media (min-width: 576px) {
  .error-content p {
    font-size: 22px;
  }
}

@media (min-width: 992px) {
  .error-content p {
    padding: 0 21px;
  }
}

.error-content .search-form .btn {
  margin-top: 40px;
  width: 185px;
  height: 40px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.error-content .site-mini-search {
  margin: 0 auto;
}

@media (min-width: 576px) {
  .error-content .site-mini-search {
    max-width: 500px;
  }
}

.error-content .site-mini-search input {
  font-size: 1.143em;
  height: 50px;
  line-height: 50px;
}

.error-content .site-mini-search button {
  width: 50px;
  font-size: 16px;
}

/* Portfolio Page */
.portfolio-blog-masonry {
  padding: 60px 30px 30px;
}

@media (min-width: 992px) {
  .portfolio-blog-masonry {
    padding: 90px 0 60px;
  }
}

.mesonry-list {
  margin: 0 -5px;
}

.mesonry-list .portfolio-25 {
  padding: 0 8px;
  margin-bottom: 15px;
  width: 100%;
}

@media (min-width: 576px) {
  .mesonry-list .portfolio-25 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .mesonry-list .portfolio-25 {
    width: 33.3333%;
  }
}

@media (min-width: 992px) {
  .mesonry-list .portfolio-25 {
    width: 25%;
  }
}

.mesonry-list .resizer {
  width: 100%;
}

@media (min-width: 576px) {
  .mesonry-list .resizer {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .mesonry-list .resizer {
    width: 33.3333%;
  }
}

@media (min-width: 992px) {
  .mesonry-list .resizer {
    width: 25%;
  }
}

.messonry-button button {
  background: #666;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 30px;
  margin-right: 2px;
  padding: 0 30px;
  position: relative;
  transition: all .4s ease-out;
  z-index: 2;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-transform: capitalize;
}

.messonry-button button:before {
  border-color: transparent;
  border-right-color: #fff;
  border-style: solid;
  border-top-color: #fff;
  border-width: 5px;
  content: '';
  display: block;
  position: absolute;
  right: 2px;
  top: 2px;
}

.messonry-button button:hover, .messonry-button button.is-checked {
  background: #71a9d0;
  color: #fff;
}

.portfolio {
  position: relative;
}

.portfolio .portfolio__content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: .4s;
}

.portfolio .portfolio__content h4.portfolio__title {
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
  float: none;
  clear: both;
  margin: 15px 0px 0px 0px;
  padding: 5px 10px 5px 10px;
  border-radius: 0px 0px 0px 0px;
  background: rgba(255, 255, 255, 0.15);
  position: relative;
  z-index: 2 !important;
  font-family: "Open Sans";
  text-transform: uppercase;
}

.portfolio .portfolio__content .portfolio__icon {
  display: flex;
}

.portfolio .portfolio__content .portfolio__icon a {
  font-size: 16px !important;
  /* line-height: 22px !important; */
  color: #ffffff !important;
  font-weight: 400 !important;
  /* padding: 17px 17px 17px 17px !important; */
  border-radius: 500px;
  background: rgba(255, 255, 255, 0.15) !important;
  z-index: 2 !important;
  display: flex;
  border-top-width: 0px !important;
  border-right-width: 0px !important;
  border-bottom-width: 0px !important;
  border-left-width: 0px !important;
  border-color: #ffffff !important;
  border-style: solid !important;
  align-items: center;
  height: 55px;
  justify-content: center;
  width: 55px;
  margin-right: 10px;
}

.portfolio .portfolio__content .portfolio__icon a:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}

.portfolio .portfolio__content .portfolio__icon a:last-child {
  margin-right: 0;
}

.portfolio:hover .portfolio__content {
  top: 0%;
  opacity: 1;
}

.portfolio-area .portfolio-btn-group {
  margin-top: 35px;
}

@media (min-width: 768px) {
  .portfolio-area .portfolio-btn-group {
    margin-top: 50px;
  }
}

.portfolio-area .btn-portfolio {
  background: gray;
  padding: 25px 30px;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 30px;
  position: relative;
  transition: all .4s ease-out;
  z-index: 2;
  border-radius: 0;
  align-items: center;
  justify-content: center;
}

.portfolio-area .btn-portfolio:hover {
  background: #71a9d0;
  color: #fff;
}

.portfolio-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 768px) {
  .portfolio-filters {
    display: block;
  }
}

.portfolio-filters button {
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .portfolio-filters button {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* Service Page */
.service-wrapper .service-section-1 {
  padding: 60px 0 25px;
}

.service-wrapper .service-section-2 {
  padding-top: 60px;
  padding-bottom: 35px;
}

.service-wrapper .service-section-4 {
  padding-top: 60px;
  padding-bottom: 30px;
}

.service-wrapper-2 .service-section-1 {
  padding-top: 60px;
  padding-bottom: 30px;
}

.service-wrapper-2 .service-section-3 {
  padding-top: 60px;
  padding-bottom: 30px;
}

.service-wrapper-2 .service-about-section {
  padding-top: 90px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .service-wrapper-2 .service-about-section {
    padding-top: 90px;
    padding-bottom: 185px;
  }
}

@media (min-width: 992px) {
  .service-about-bg {
    background-image: url(../image/others/services/service-about-bg.jpg);
    background-position-x: left;
    background-size: cover;
    background-position-y: 62%;
  }
}

.service-cta .cta-content {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #ffffff;
}

.service-cta .cta-content h3 {
  font-size: 30px;
  text-align: center;
  font-family: Raleway;
  font-weight: 900;
  margin-bottom: 30px;
}

.service-cta .cta-content p {
  font-style: italic;
  font-weight: 400;
}

.service-wrapper .section-title {
  color: #ffff;
  text-align: center;
  margin-bottom: 60px;
}

.service-wrapper .section-title h2 {
  font-size: 30px;
  text-align: center;
  font-family: Raleway;
  font-weight: 900;
  font-style: normal;
  margin-bottom: 20px;
}

.service-wrapper .section-title p {
  color: #c6c6c6;
}

.service-about-section .section-about-title {
  margin-bottom: 70px;
}

.service-about-section .section-about-title h3 {
  font-size: 30px;
  color: #444444;
  text-align: left;
  font-family: Raleway;
  font-weight: 900;
  font-style: normal;
}

.service-card .image {
  margin-bottom: 35px;
}

.service-card .image img {
  width: 100%;
}

.service-card h4 {
  font-size: 14px;
  color: #444444;
  text-align: left;
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.service-card p {
  line-height: 1.65;
  padding-bottom: 10px;
}

.service-widget {
  display: flex;
}

.service-widget .icon {
  color: #4fc1f0;
  font-size: 34.4px;
  max-width: 64px;
  width: 100%;
  text-align: center;
  height: 64px;
}

.service-widget .content h5 {
  font-size: 13px;
  color: #c6c6c6;
  text-align: left;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1.35;
  margin-bottom: 5px;
}

.service-widget .content p {
  color: #999;
  line-height: 1.65;
}

.service-widget.black-color .content h5 {
  color: #444;
}

.service-widget.black-color .content p {
  color: #666;
}

.service-widget-2 {
  display: flex;
}

.service-widget-2 .icon {
  color: #4fc1f0;
  max-width: 64px;
  width: 100%;
  max-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 500px;
  border: 2px solid #4fc1f0;
}

.service-widget-2 .icon i {
  font-size: 30px;
}

.service-widget-2 .content h5 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #444444;
  text-align: left;
  font-family: Raleway;
  font-weight: 700;
  font-style: normal;
}

.service-widget-2 .content p {
  margin-bottom: 10px;
}

.service-description-area {
  padding: 80px 0;
  text-align: center;
}

.service-description-area h2 {
  font-size: 30px;
  color: #444444;
  text-align: center;
  font-family: Raleway;
  font-weight: 900;
  font-style: normal;
  line-height: 1.35;
  margin: 0 0 5px;
}

.service-description-area article {
  padding-top: 50px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 768px) {
  .service-description-area article {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 992px) {
  .service-description-area article {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .service-description-area article {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.service-description-area article p {
  padding-bottom: 10px;
  line-height: 1.65;
}

.btn.btn-service {
  width: 145px;
  border: 2px solid #000;
  border-radius: 0;
  font-size: 16px;
  /* padding-top: 10px; */
  height: 50px;
}

.btn.btn-service i {
  margin-left: 15px;
}

.btn.btn-service:hover {
  background: #1a1f2b;
  color: #ffff;
}

.pricing-card {
  text-align: center;
  background: #fff;
}

.pricing-card.box-shadow {
  box-shadow: 0 1px #fff inset, 0 1px 3px rgba(34, 25, 25, 0.4);
}

.pricing-card header {
  background: #3e3e3e;
  padding: 20px;
  color: #fff;
  transition: .4s;
}

.pricing-card header h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.pricing-card .card-body {
  padding: 0 20px 40px 20px;
}

.pricing-card .card-body .price {
  font-family: Rubik, Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-weight: bold;
  margin: 0;
  padding: 50px 0;
  text-transform: uppercase;
}

.pricing-card .card-body .price .month {
  font-size: 12px;
}

.pricing-card .card-body ul.pricing-list {
  padding-left: 15px;
}

.pricing-card .card-body ul.pricing-list li {
  border-bottom: 1px solid #ececec;
  list-style: none;
  padding: 15px 0;
}

.pricing-card .card-body ul.pricing-list li:first-child {
  border-top: 1px solid #ececec;
}

.pricing-card .card-body .card-btn {
  margin: 40px 0 0;
}

.pricing-card .card-body .card-btn .btn {
  height: 50px;
  font-size: 14px;
  padding: 0 30px;
  width: auto;
}

.pricing-card:hover header {
  background: #56a700;
}

.portfolio-single {
  position: relative;
}

.portfolio-single img {
  width: 100%;
}

.portfolio-single:hover .hover-content {
  opacity: 1;
}

.portfolio-single .hover-content {
  text-align: center;
  text-align: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  opacity: -1;
  transition: .4s;
  padding-top: 30px;
}

@media (min-width: 576px) {
  .portfolio-single .hover-content {
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .portfolio-single .hover-content {
    padding-top: 40px;
  }
}

@media (min-width: 1200px) {
  .portfolio-single .hover-content {
    padding-top: 60px;
  }
}

.portfolio-single .hover-content .hover-item-icon {
  margin-bottom: 5px;
}

.portfolio-single .hover-content .hover-item-icon i {
  background: #1a1f2b;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  height: 80px;
  width: 80px;
}

@media (min-width: 576px) {
  .portfolio-single .hover-content .hover-item-icon i {
    font-size: 40px;
    height: 115px;
    width: 115px;
  }
}

@media (min-width: 992px) {
  .portfolio-single .hover-content .hover-item-icon i {
    font-size: 30px;
    width: 70px;
    height: 70px;
  }
}

@media (min-width: 1200px) {
  .portfolio-single .hover-content .hover-item-icon i {
    font-size: 40px;
    height: 115px;
    width: 115px;
  }
}

.portfolio-single .hover-content .hover-item-content h4 {
  font-weight: 500;
  line-height: 1.35;
  margin: 0 0 5px;
  color: #1a1f2b;
}

.portfolio-single .hover-content .hover-item-content P {
  margin-bottom: 10px;
}

/* Theme Color Variation */
/*
====> theme-color-2 Styles
*/
.petmark-theme-2 .bg-primary {
  background-color: #00b1bf !important;
}

.petmark-theme-2 .sticky-color {
  background: rgba(0, 177, 191, 0.95);
}

.petmark-theme-2 .btn-outlined--white:hover,
.petmark-theme-2 .category-widget .search-submit,
.petmark-theme-2 .btn-outlined:hover,
.petmark-theme-2 .pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link.active::after,
.petmark-theme-2 .pm-product .onsale-badge,
.petmark-theme-2 .home-slider ul.slick-dots li.slick-active button,
.petmark-theme-2 .home-slider ul.slick-dots li:hover button,
.petmark-theme-2 #scrollUp:hover {
  background-color: #00b1bf;
}

.petmark-theme-2 .category-widget .search-submit:hover {
  background: rgba(0, 177, 191, 0.8);
}

.petmark-theme-2 .btn-outlined--white:hover,
.petmark-theme-2 #scrollUp:hover {
  border-color: #00b1bf;
}

.petmark-theme-2 .btn-outlined:hover {
  border-color: #00b1bf !important;
}

.petmark-theme-2 .cart-widget.white-color .cart-count-badge,
.petmark-theme-2 .call-widget i {
  background-color: #ff7c00;
}

.petmark-theme-2 .policy-block-single .icon,
.petmark-theme-2 .cart-widget-wrapper .cart-product .product-details--title:hover,
.petmark-theme-2 .cart-widget-wrapper .cart-product .cart-cross:hover,
.petmark-theme-2 .mainmenu li a:hover,
.petmark-theme-2 .mainmenu li.menu-item-has-children ul > li > ul > li:hover > a,
.petmark-theme-2 .pm-tab-nav.nav-tabs .nav-link:hover,
.petmark-theme-2 .category-block-single h3:hover a,
.petmark-theme-2 .pm-product .hover-conents .product-btns a:hover,
.petmark-theme-2 .petmark-slick-slider button.slick-arrow:hover:before,
.petmark-theme-2 .mainmenu li:hover:after,
.petmark-theme-2 .footer-copyright a,
.petmark-theme-2 .footer-list li a:hover,
.petmark-theme-2 .social-icons a:hover,
.petmark-theme-2 .input-box-with-icon button:hover,
.petmark-theme-2 .pm-product .content h3 a:hover,
.petmark-theme-2 .category-nav__menu__item a:hover,
.petmark-theme-2 .category-nav__menu__item.has-children:hover:after,
.petmark-theme-2 .mainmenu li:hover > a {
  color: #00b1bf;
}

.petmark-theme-2 .text-primary,
.petmark-theme-2 .mainmenu .sub-menu > li:hover:after {
  color: #00b1bf !important;
}

.petmark-theme-2 .cart-widget.white-color .cart-count-badge,
.petmark-theme-2 .main-navigation.white-color .mainmenu > li > a:hover,
.petmark-theme-2 .sticky-navigation {
  color: #fff;
}

@media (min-width: 992px) {
  .petmark-theme-2 .sticky-navigation .mainmenu > li:hover:after,
  .petmark-theme-2 .mainmenu > li:hover > a {
    color: #fff !important;
  }
}

.petmark-theme-2 .mainmenu li.menu-item-has-children ul > li.menu-item-has-children:after {
  color: #666666;
}

/*
====> theme-color-3 Styles
*/
.petmark-theme-3 .bg-primary {
  background-color: #56a700 !important;
}

.petmark-theme-3 .sticky-color {
  background: rgba(86, 167, 0, 0.95);
}

.petmark-theme-3 .btn-outlined--white:hover,
.petmark-theme-3 .btn-outlined:hover,
.petmark-theme-3 .pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link.active::after,
.petmark-theme-3 .pm-product .onsale-badge,
.petmark-theme-3 #scrollUp:hover {
  background-color: #56a700;
}

.petmark-theme-3 .category-widget .search-submit:hover {
  background: rgba(255, 124, 0, 0.8);
}

.petmark-theme-3 .btn-outlined--white:hover,
.petmark-theme-3 #scrollUp:hover {
  border-color: #56a700;
}

.petmark-theme-3 .btn-outlined:hover {
  border-color: #56a700 !important;
}

.petmark-theme-3 .cart-widget.white-color .cart-count-badge,
.petmark-theme-3 .call-widget i,
.petmark-theme-3 .category-widget .search-submit {
  background-color: #ff7c00;
}

.petmark-theme-3 .policy-block-single .icon,
.petmark-theme-3 .cart-widget-wrapper .cart-product .product-details--title:hover,
.petmark-theme-3 .cart-widget-wrapper .cart-product .cart-cross:hover,
.petmark-theme-3 .mainmenu li a:hover,
.petmark-theme-3 .mainmenu li.menu-item-has-children ul > li ul > li:hover > a,
.petmark-theme-3 .pm-tab-nav.nav-tabs .nav-link:hover,
.petmark-theme-3 .category-block-single h3:hover a,
.petmark-theme-3 .pm-product .hover-conents .product-btns a:hover,
.petmark-theme-3 .petmark-slick-slider button.slick-arrow:hover:before,
.petmark-theme-3 .mainmenu li:hover > a,
.petmark-theme-3 .mainmenu li:hover:after,
.petmark-theme-3 .footer-copyright a,
.petmark-theme-3 .footer-list li a:hover,
.petmark-theme-3 .social-icons a:hover,
.petmark-theme-3 .input-box-with-icon button:hover,
.petmark-theme-3 .pm-product .content h3 a:hover {
  color: #56a700;
}

.petmark-theme-3 .sticky-navigation.white-color .mainmenu li.menu-item-has-children:after {
  color: #555555;
}

.petmark-theme-3 .text-primary,
.petmark-theme-3 .mainmenu li.menu-item-has-children ul > li > ul > li:hover > a {
  color: #56a700 !important;
}

.petmark-theme-3 .cart-widget.white-color .cart-count-badge,
.petmark-theme-3 .main-navigation.white-color .mainmenu > li > a:hover,
.petmark-theme-3 .sticky-navigation .mainmenu > li:hover > a {
  color: #fff;
}

.petmark-theme-3 .sticky-menu .sticky-has-child:after {
  color: #fff !important;
}

.petmark-theme-3 .sticky-navigation .main-menu > li.menu-item-has-children:hover:after {
  color: #fff !important;
}

/*
====> theme-color-4 Styles
*/
.petmark-theme-4 .bg-primary {
  background-color: #128ced !important;
}

.petmark-theme-4 .category-widget .search-submit:hover {
  background: rgba(18, 140, 237, 0.95);
}

.petmark-theme-4 .btn-outlined--white:hover,
.petmark-theme-4 .btn-outlined:hover,
.petmark-theme-4 .pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link.active::after,
.petmark-theme-4 .pm-product .onsale-badge,
.petmark-theme-4 .category-widget .search-submit,
.petmark-theme-4 .btn-outlined--primary:hover,
.petmark-theme-4 .home-slider ul.slick-dots li.slick-active button,
.petmark-theme-4 .home-slider ul.slick-dots li:hover button,
.petmark-theme-4 .block-title:after,
.petmark-theme-4 #scrollUp:hover {
  background-color: #128ced;
}

.petmark-theme-4 .btn-outlined--white:hover,
.petmark-theme-4 .pm-tab-nav.nav-tabs .nav-link.active,
.petmark-theme-4 a.gallary-item:hover,
.petmark-theme-4 #scrollUp:hover {
  border-color: #128ced;
}

.petmark-theme-4 .text-primary,
.petmark-theme-4 .mainmenu .sub-menu > li:hover:after {
  color: #128ced !important;
}

.petmark-theme-4 .btn-outlined:hover,
.petmark-theme-4 .btn-outlined--primary {
  border-color: #128ced !important;
}

.petmark-theme-4 .cart-widget.white-color .cart-count-badge,
.petmark-theme-4 .cart-widget .cart-icon .cart-count-badge {
  background-color: #f8d81c;
}

.petmark-theme-4 .cart-widget .cart-icon .cart-count-badge {
  color: #1a1f2b;
}

.petmark-theme-4 .cart-widget .cart-text .amount {
  color: #f8d81c;
}

.petmark-theme-4 .policy-block-single .icon,
.petmark-theme-4 .cart-widget-wrapper .cart-product .product-details--title:hover,
.petmark-theme-4 .cart-widget-wrapper .cart-product .cart-cross:hover,
.petmark-theme-4 .mainmenu li.menu-item-has-children ul > li > ul > li:hover > a,
.petmark-theme-4 .pm-tab-nav.nav-tabs .nav-link:hover,
.petmark-theme-4 .category-block-single h3:hover a,
.petmark-theme-4 .pm-product .hover-conents .product-btns a:hover,
.petmark-theme-4 .petmark-slick-slider button.slick-arrow:hover:before,
.petmark-theme-4 .footer-copyright a,
.petmark-theme-4 .footer-list li a:hover,
.petmark-theme-4 .social-icons a:hover,
.petmark-theme-4 .input-box-with-icon button:hover,
.petmark-theme-4 .pm-product .content h3 a:hover,
.petmark-theme-4 .header-links li:hover a,
.petmark-theme-4 .header-top-nav .nav-item:hover > a,
.petmark-theme-4 .home-content .slider-btn,
.petmark-theme-4 .sticky-header .mainmenu li:hover > a,
.petmark-theme-4 .pm-tab-nav.nav-tabs .nav-link.active,
.petmark-theme-4 .blog-post.card-style-list--2 .blog-date,
.petmark-theme-4 .pm-product-details .description-block .status i,
.petmark-theme-4 .mainmenu li.menu-item-has-children ul > li > ul > li:hover > a,
.petmark-theme-4 .mainmenu li:hover > a {
  color: #128ced;
}

.petmark-theme-4 .pm-product-details .description-block .add-to-cart .btn-block .btn {
  color: #128ced;
}

.petmark-theme-4 .pm-product-details .description-block .add-to-cart .btn-block .btn:hover {
  color: #fff !important;
}

.petmark-theme-4 .btn .text-primary,
.petmark-theme-4 .sticky-header .mainmenu li:hover:after {
  color: #128ced !important;
}

.petmark-theme-4 .cart-widget.white-color .cart-count-badge,
.petmark-theme-4 .mainmenu > li:hover > a {
  color: #fff;
}
