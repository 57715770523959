/*
====> theme-color-3 Styles
*/

$white-color: #fff;
.petmark-theme-3 {
    $primary-color: #56a700;
    $orange-color: #ff7c00;
    .bg-primary {
        background-color: $primary-color !important;
    }
    .sticky-color {
        background: rgba($primary-color, .95)
    }
    .btn-outlined--white:hover,
    .btn-outlined:hover,
    .pm-tab-nav.tab-nav-style-2.nav-tabs .nav-link.active::after,
    .pm-product .onsale-badge,
    #scrollUp:hover {
        background-color: $primary-color;
    }
    .category-widget .search-submit:hover {
        background: rgba($orange-color, .8)
    }
    .btn-outlined--white:hover,
    #scrollUp:hover {
        border-color: $primary-color;
    }
    .btn-outlined:hover {
        border-color: $primary-color !important;
    }
    .cart-widget.white-color .cart-count-badge,
    .call-widget i,
    .category-widget .search-submit {
        background-color: $orange-color;
    }
    .policy-block-single .icon,
    .cart-widget-wrapper .cart-product .product-details--title:hover,
    .cart-widget-wrapper .cart-product .cart-cross:hover,
    .mainmenu li a:hover,
    .mainmenu li.menu-item-has-children ul>li ul>li:hover>a, //   .mainmenu li.menu-item-has-children .megamenu>ul>li:hover>a,
    .pm-tab-nav.nav-tabs .nav-link:hover,
    .category-block-single h3:hover a,
    .pm-product .hover-conents .product-btns a:hover,
    .petmark-slick-slider button.slick-arrow:hover:before,
    .mainmenu li:hover>a,
    .mainmenu li:hover:after,
    .footer-copyright a,
    .footer-list li a:hover,
    .social-icons a:hover,
    .input-box-with-icon button:hover,
    .pm-product .content h3 a:hover,
        {
        color: $primary-color;
    }
    .sticky-navigation.white-color .mainmenu li.menu-item-has-children:after,
        {
        color: #555555;
    }
    .text-primary,
    .mainmenu li.menu-item-has-children ul>li>ul>li:hover>a {
        color: $primary-color !important;
    }
    .cart-widget.white-color .cart-count-badge,
    .main-navigation.white-color .mainmenu>li>a:hover,
    .sticky-navigation .mainmenu>li:hover>a {
        color: $white-color;
    }
    .sticky-menu .sticky-has-child:after {
        color: $white-color !important;
    }
    .sticky-navigation .main-menu>li.menu-item-has-children:hover:after,
        {
        color: $white-color !important;
    }
}