/* Policy Section */

.border-div {
    position: relative;
    &:before {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        background: $border-color;
        left: 0;
        top: 0;
        @include desktops {
            height: 100%;
            width: 1px;
        }
    }
}

.border-four-column {
    [class*="col-"]>.policy-block-single {
        &:before {
            content: "";
            width: 1px;
            position: absolute;
            background: $border-color;
            left: -15px;
            top: 10px;
            bottom: 10px;
        }
        &:after {
            background: #ececec;
            position: absolute;
            content: "";
            left: 10px;
            right: 10px;
            top: -1px;
            height: 1px;
        }
    }
    
}

.policy-block {
    overflow: hidden;
    border: 1px solid $border-color; // padding: 0 30px;
    @include desktops {
        padding: 0;
    }
    .policy-block-single {
        justify-content: center;
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &.policy-hr {
        .policy-block-single {
            padding-top: 30px;
            padding-bottom: 30px;
            position: relative;
            margin-left: 30px;
            margin-right: 30px;
            margin-top: 0;
            margin-bottom: 0;
            &:before {
                content: "";
                position: absolute;
                background: $border-color;
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 1px;
            }
        }
    }
    &.absoute-policy {
        @include desktops {
            position: absolute;
            width: calc(100% - 30px);
            top: 0;
            transform: translateY(-50%);
            z-index: 300;
            background: #fff;
        }
    }
}

.policy-block-single {
    display: flex;
    .icon {
        font-size: 36px; // margin-top: -4px;
        color: $primary-color;
        @include inline-flex(center, flex-start);
    }
    .text {
        margin-left: 10px;
        h3 {
            font-size: 16px;
            margin-bottom: 4px;
        }
        p {
            font-weight: 300;
            margin-bottom: 7px;
        }
    }
}