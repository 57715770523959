/* Modal */

.modal.modal-quick-view {
    display: block;
    opacity: 0;
    visibility: hidden;
    z-index: -9999;
    .modal-dialog {
        max-width: 100%;
        margin: 20% auto;
        @include tablet {
            max-width: 700px;
            overflow-x: scroll;
        }
        @include desktops {
            max-width: 900px;
            overflow: unset;
            margin: 10% auto;
        }
        @include large-desktops {
            max-width: 1100px;
            overflow: unset;
        }
    }
    &.show {
        opacity: 1;
        visibility: visible;
        z-index: 9999;
    }
    .pm-product-details {
        padding: 10px;
        @include mobile {
            padding: 20px;
        }
    }
    .pm-product-details .description-block .share-block-2 {
        display: block;
    }
    .add-to-cart {
        .btn-block {
            .btn {
                width: 135px;
                @include tablet {
                    width: 190px !important;
                }
            }
        }
    }
    #product-view-gallery {
        margin-top: 35px;
    }
}

// .social-btns-3{
//     a{
//     }
// }