/*=== MEDIA QUERY ===*/

@mixin mobile-xs {
  @media(min-width: 320px) {
    @content;
  }
}

@mixin mobile {
  @media(min-width: 480px) {
    @content;
  }
}

@mixin mobile-lg {
  @media(min-width: 576px) {
    @content;
  }
}

@mixin tablet {
  @media(min-width: 768px) {
    @content;
  }
}

@mixin desktops {
  @media(min-width: 992px) {
    @content;
  }
}

@mixin large-desktops {
  @media(min-width: 1200px) {
    @content;
  }
}

@mixin extra-large-desktops {
  @media(min-width: 1500px) {
    @content;
  }
}

/*=== MEDIA QUERY with max width===*/

@mixin mx-mobile-xs {
  @media(max-width: 479px) {
    @content;
  }
}

@mixin mx-mobile {
  @media (min-width: 480px) and (max-width: 575px) {
    @content;
  }
}

@mixin mx-mobile-lg {
  @media (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}

@mixin mx-tablet {
  @media(min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin mx-desktops {
  @media(min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin mx-large-desktops {
  @media(min-width: 1200px) and (max-width: 1500px) {
    @content;
  }
}

@mixin mx-extra-large-desktops {
  @media(min-width: 1500px) {
    @content;
  }
}
