/* Blog Page */

.blog-post {
    .blog-content {
        header {
            margin-bottom: 20px;
        }
        .post-category {
            margin-bottom: 10px;
            i {
                margin-right: 5px;
            }
            a {
                &:hover {
                    color: $primary-color;
                }
            }
        }
        .blog-title {
            display: inline-block;
            font-size: 25px;
            margin-bottom: 16px;
            padding: 0;
            a {
                color: $black-color-2;
                transition: .4s;
                &:hover {
                    color: $primary-color;
                }
            }
        }
        .post-meta {
            font-size: 13.76px;
            .post-separator {
                margin: 0 10px;
            }
            span {
                font-size: 13px;
                i {
                    margin-right: 5px;
                }
            }
        }
        article {
            p {
                margin: 0;
                padding-bottom: 20px;
                line-height: 1.65;
            }
        }
        footer {
            margin-top: 20px;
            align-items: center;
            flex-wrap: wrap;
            @include mobile {
                display: flex;
            }
            div {
                flex: 50%;
                display: block;
                margin-bottom: 15px;
                @include mobile{
                    display: static;
                    margin-bottom: 0;
                }
            }
        }
    }
    .blog-btn {
        .btn {
            color: $primary-color;
            @include mx-tablet {
                width: 130px;
            }
            &:hover {
                color: $white-color;
            }
        }
    }
    &.card-style-list {
        .blog-title {
            font-size: 30px;
        }
        &--2 {
            .blog-date {
                color: $primary-color;
                font-size: 22px;
                font-weight: 500;
                line-height: 1;
                margin-bottom: 15px;
                .day {
                    font-size: 44px;
                }
                .year:before {
                    content: '/';
                    display: inline;
                    margin-left: -2px;
                }
                span {
                    margin-right: 5px;
                }
            }
            .blog-title {
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 7px;
                a {
                    color: $primary-color;
                }
            }
            article p {
                padding-bottom: 10px;
            }
            .blog-btn {
                a {
                    &:hover {
                        color: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &.post-details {
        .blog-title {
            font-size: 30px;
        }
        .blog-content {
            margin-top: 30px;
            header {
                margin-bottom: 25px;
            }
            blockquote {
                background: #f6f6f6;
                border: 1px solid #ececec;
                border-left: 5px solid #56a700;
                font-size: 14px;
                font-style: italic;
                line-height: 26px;
                margin: 30px 0 30px 30px;
                padding: 30px 45px;
            }
        }
        .blog-meta {
            font-size: 13px;
            padding: 10px;
            color: #1a1f2b;
            font-size: 13px;
            padding: 15px 0; // text-transform: uppercase;
            border-bottom: 1px solid #ececec;
            border-top: 1px solid #ececec;
            margin-top: 10px;
            a {
                color: $primary-color;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.share-block {
    text-align: center;
    h3 {
        background: none;
        color: #8c8c8c;
        font-size: 12px;
        line-height: 26px;
        margin: 0 20px;
        text-transform: uppercase;
        width: auto;
    }
}

.social-links {
    display: flex;
    .single-social {
        color: $black-color-2;
        background: #f7f7f7;
        border-radius: 100%;
        color: #353535;
        display: inline-block;
        font-size: 12px;
        height: 26px;
        line-height: 26px;
        margin-right: 10px;
        text-align: center;
        width: 26px;
        &:hover {
            color: #fff;
            background: $primary-color;
        }
    }
}

.blog-posts-container {
    .blog-post {
        border-bottom: 1px solid #ececec;
        margin: 0 0 50px;
        padding: 0 0 50px 0;
        width: 100%;
        &:last-child {
            padding-bottom: 0;
            border: none;
        }
    }
}

.related-post-block {
    h3 {
        display: inline-block;
        font-size: 20px;
        text-transform: uppercase;
        color: $black-color-2;
    }
}

.related-post {
    .post-date {
        color: #999;
        display: block;
        font-style: italic;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
    }
    .post-title {
        font-size: 1.143em;
        color: $black-color-2;
        a {
            display: inline-block;
        }
        &:hover {
            a {
                color: $primary-color;
            }
        }
    }
}

.blog-image-gallery {
    padding: 5px;
    border: 1px solid #cecece;
    border-radius: 3px;
    position: relative;
    .slick-arrow {
        position: absolute;
        top: 50% !important;
        transform: translateY(-50%);
        display: block;
        width: 40px;
        height: 40px;
        margin-top: 0px;
        background: rgb(0, 0, 0) 0 0 url(../image/icon-logo/arrow-icon.png) no-repeat;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%; // opacity: 0;
        transition: all .2s;
        left: 60px;
        z-index: 9;
        opacity: 0;
        background-position: 10% 20%;
        &:before {
            opacity: 0;
        }
        &.slick-next {
            // background-position: -40px 0;
            left: 100%;
            margin-left: -60px;
            right: 0;
            transform: translate(-100%, -50%);
            background-position: 90% 20% !important;
        }
    }
    &:hover {
        .slick-arrow {
            opacity: 1;
            left: 10px;
            &.slick-next {
                left: 100%;
                margin-left: -10px;
            }
        }
    }
}

.blog-page-section {
    padding-top: 40px;
    padding-bottom: 40px;
    @include mobile-lg {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    @include desktops {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    &.with-sidebar {
        padding-top: 60px;
        padding-bottom: 60px;
        @include desktops {
            padding-top: 90px;
            padding-bottom: 90px;
        }
    }
}

/* Pagination */

.pagination-widget {
    text-align: right;
    p {
        color: inherit;
    }
}

.site-pagination {
    a {
        background: #fff;
        border: 1px solid #ececec;
        color: #1a1f2b;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        height: 32px;
        line-height: 30px;
        margin-right: 7px;
        min-width: 0;
        padding: 0;
        text-align: center;
        width: 32px;
        transition: all .2s ease;
        border-radius: 4px;
        &:hover {
            color: $primary-color;
        }
        &.active,
        &:hover {
            background: #56a700;
            border-color: #56a700;
            color: #fff;
        }
    }
}

.blog-page-section.with-sidebar {
    .single-block {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.replay-form-wrapper {
    h3 {
        font-size: 17px;
        margin: 20px 0;
        position: relative;
        text-transform: uppercase;
    }
    p {
        color: #999;
        font-size: 12px;
        padding-bottom: 10px;
    }
}

.blog-slider {
    .single-slide {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        img {
            width: 100%;
        }
    }
    .slick-slide:before {
        top: 20px;
        bottom: 10px;
    }
}