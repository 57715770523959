/* Menu Styles*/

.main-navigation {
    display: block;
    &.white-color {
        .mainmenu>li>a {
            color: $white-color;
        }
        .mainmenu {
            >li.menu-item-has-children {
                &:after {
                    color: $white-color;
                }
                &:hover {
                    &:after {
                        color: $white-color !important;
                    }
                }
            }
        }
    }
}

.sticky-navigation {
    &.white-color {
        .mainmenu>li>a {
            color: $white-color;
            &:hover {
                color: $white-color;
            }
        }
        .mainmenu li.menu-item-has-children {
            &:after {
                color: $white-color;
                &:hover {
                    color: $white-color;
                }
            }
        }
    }
}

@media (max-width: 991.04) {
    .main-navigation {
        display: none;
    }
}

.main-navigation-2 {
    padding-left: 2rem;
}

.mainmenu {
    &.left-align {
        text-align: left;
    }
    &.right-align {
        text-align: right;
    }
    &.center-align {
        text-align: right;
    }
}

@media (max-width: 1199px) {
    .mainmenu {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .mainmenu__item {
        display: inline-block;
        position: relative;
    }
    .mainmenu__item.menu-item-has-children:after {
        content: '\f078';
        right: 0;
    }
}

.mainmenu li {
    position: relative;
}

.mainmenu li.menu-item-has-children li a {
    font-weight: 400;
}

@media (min-width: 992px) {
    .mainmenu li.menu-item-has-children>ul {
        position: absolute;
        left: 0;
        top: 100%;
        background-color: #fff;
        z-index: 9;
        -webkit-transform-origin: center top 0;
        -moz-transform-origin: center top 0;
        -ms-transform-origin: center top 0;
        -o-transform-origin: center top 0;
        transform-origin: center top 0;
        -webkit-transform: perspective(600px) rotateX(-90deg);
        -moz-transform: perspective(600px) rotateX(-90deg);
        -ms-transform: perspective(600px) rotateX(-90deg);
        -o-transform: perspective(600px) rotateX(-90deg);
        transform: perspective(600px) rotateX(-90deg);
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        visibility: hidden;
        opacity: 0;
        text-align: left;
        -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        z-index: -1;
        pointer-events: none;
    }
}

@media (min-width: 992px) {
    .mainmenu li.menu-item-has-children>ul.megamenu {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: nowrap;
        -moz-flex-wrap: nowrap;
        -ms-flex-wrap: none;
        flex-wrap: nowrap;
        width: 40rem;
        left: 0;
        padding: 14px 25px;
    }
}

@media (min-width: 992px) {
    .mainmenu li.menu-item-has-children>ul.megamenu>li {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -moz-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 992px) {
    .mainmenu li.menu-item-has-children>ul.megamenu li {
        padding-right: 0;
    }
}

@media (max-width: 1199px) {
    .mainmenu li.menu-item-has-children>ul.megamenu li {
        padding-right: 1rem;
    }
}

@media (min-width: 992px) {
    .mainmenu li.menu-item-has-children>ul.megamenu li ul {
        position: relative;
        visibility: visible;
        opacity: 1;
        top: auto !important;
        left: auto;
        background: transparent;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        display: block;
        padding: 0 !important;
    }
}

.mainmenu li.menu-item-has-children>ul.megamenu li a {
    color: #666666;
    font-size: 14px;
    padding: 10px 10px;
    text-transform: capitalize;
    &:hover {
        @include desktops {
            color: $primary-color;
        }
    }
}

.mainmenu li.menu-item-has-children>ul.megamenu>li>a {
    font-weight: 600;
    font-size: 15px;
    color: #666666;
    text-transform: uppercase; // margin-bottom: 1.2rem; 
}

.mainmenu li.menu-item-has-children>ul.megamenu>li>a:hover {
    color: #666666;
}

@media (min-width: 992px) {
    .mainmenu li.menu-item-has-children>ul.megamenu.three-column {
        min-width: 500px;
        left: 50%;
        margin-left: -35rem;
        border: 1px solid #ececec;
    }
    .mainmenu li.menu-item-has-children>ul.megamenu.three-column>li {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.3333%;
        -moz-box-flex: 0;
        -moz-flex: 0 0 33.3333%;
        -ms-flex: 0 0 33.3333%;
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }
}

@media (min-width: 992px) {
    .mainmenu li.menu-item-has-children>ul ul {
        left: 100%;
        top: 15rem !important;
        visibility: hidden;
        opacity: 0;
    }
}

@media (min-width:992px) {
    .mainmenu li.menu-item-has-children:after {
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        top: 50%;
        margin-top: -12px;
        color: $black-color-2;
    }
}

@media (max-width: 991.04) {
    .mainmenu li.menu-item-has-children:after {
        display: none;
    }
}

@media (min-width: 992px) {
    .mainmenu>li:hover>ul {
        visibility: visible;
        opacity: 1;
        top: 100%;
        z-index: 9999;
        -webkit-transform: perspective(600px) rotateX(0deg);
        -moz-transform: perspective(600px) rotateX(0deg);
        -ms-transform: perspective(600px) rotateX(0deg);
        -o-transform: perspective(600px) rotateX(0deg);
        transform: perspective(600px) rotateX(0deg);
        pointer-events: visible;
    }
}

.mainmenu li a {
    display: block;
    padding: 24px 25px;
    ;
    font-size: 14px;
    font-weight: 500;
    color: $black-color-2;
    text-transform: uppercase;
}

@media (min-width: 992px) {
    .mainmenu>li:first-child>a {
        // padding-left: 0; 
    }
}

.mainmenu .sub-menu {
    @include desktops {
        pointer-events: none;
        width: 195px;
        padding: 10px 0;
        white-space: -moz-nowrap;
        white-space: nowrap;
    }
    @include large-desktops {
        width: 220px;
    }
    &.left-align {
        @include desktops {
            left: -100% !important;
        }
    }
}

@media (min-width: 992px) {
    .mainmenu .sub-menu a {
        color: #666666;
        font-size: 14px;
        padding: 8px 30px;
        display: block;
        text-transform: capitalize;
    }
}

@media (min-width: 992px) {
    .mainmenu .sub-menu li.menu-item-has-children:after {
        content: '\f105';
        right: 2rem;
        color: #666666;
    }
}

@media (min-width: 992px) {
    .mainmenu .sub-menu li ul {
        -webkit-transform: perspective(600px) rotateX(0deg);
        -moz-transform: perspective(600px) rotateX(0deg);
        -ms-transform: perspective(600px) rotateX(0deg);
        -o-transform: perspective(600px) rotateX(0deg);
        transform: perspective(600px) rotateX(0deg);
    }
}

@media (min-width: 992px) {
    .mainmenu .sub-menu li:hover ul {
        visibility: visible;
        opacity: 1;
        pointer-events: visible;
        top: 0 !important;
    }
}

.mainmenu--2 {
    text-align: left;
}

.mainmenu--2 .mainmenu__item.active a {
    color: #40576c;
}

.mainmenu--2 .mainmenu__item.menu-item-has-children.active:after {
    color: #40576c;
}

.mainmenu--2 .mainmenu__item:hover>ul {
    top: 6.9rem;
}

.mainmenu--2>li>a {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.mainmenu--3 {
    text-align: left;
}

.mainmenu--3 li.menu-item-has-children:after {
    color: #333333;
}

.mainmenu--3 li.menu-item-has-children>ul.megamenu.three-column {
    left: auto;
    right: 0;
    margin-left: 0;
}

.mainmenu--3 li a {
    color: #333333;
}

/***********************
  Mobile Menu
  ************************/

.mobile-menu {
    flex: 1 0 100%; // background: $primary-color;
    padding: 8px 0;
}

.mobile-absolute-menu {
    position: absolute;
    right: 0;
    margin-top: 60px;
    z-index: 300;
    @include tablet {
        margin-top: 0px;
    }
}

.mobile-menu .mean-bar {
    position: relative;
    background-color: transparent; // margin-top: 3rem; 
    z-index: 98 !important;
}

.mobile-menu .mean-bar:before {
    position: absolute; // content: "Menu";
    left: 2rem;
    top: 15px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
}

.mobile-menu .mean-bar .meanmenu-reveal {
    position: absolute; // padding: 19px 13px 11px;
    padding: 10px 13px 7px 9px;
    border: 1px solid #afadad;
    left: 15px !important;
    margin-top: 10px;
    @include tablet {
        right: 15px !important;
        margin-top: 0px;
        left: auto !important;
    }
}

.mobile-menu .mean-bar .meanmenu-reveal span {
    position: relative;
}

.mobile-menu .mean-bar .meanmenu-reveal span.menu-bar {
    height: 2px;
    width: 26px;
    background-color: #afadad;
    display: block;
    margin: 8px 0;
}

.mobile-menu .mean-bar .meanmenu-reveal span:before,
.mobile-menu .mean-bar .meanmenu-reveal span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #afadad;
}

.mobile-menu .mean-bar .meanmenu-reveal span:before {
    top: -8px;
}

.mobile-menu .mean-bar .meanmenu-reveal span:after {
    bottom: -8px;
}

.mobile-menu .mean-bar .meanmenu-reveal span.menu-close {
    height: 2px;
    width: 26px;
    background-color: transparent;
    display: block;
    margin: 8px 0;
}

.mobile-menu .mean-bar .meanmenu-reveal span.menu-close:before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.mobile-menu .mean-bar .meanmenu-reveal span.menu-close:after {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.mobile-menu .mean-bar .mean-nav {
    background-color: #ffffff;
    margin-top: 48px;
    margin-top: 65px;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    @include mx-tablet {
        margin-top: 48px;
    }
}

.mobile-menu .mean-bar .mean-nav>ul {
    overflow-x: hidden;
    max-height: 400px;
    position: absolute;
    background: #fff;
    right: 0;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 47.94em) {
    .mobile-menu .mean-bar .mean-nav>ul {
        max-height: 180px;
        overflow-y: auto;
    }
}

.mobile-menu .mean-bar .mean-nav>ul li {
    position: relative;
    display: block;
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
}

.mobile-menu .mean-bar .mean-nav>ul li a {
    font-size: 13px;
    display: block;
    color: #444444;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 44px;
    position: relative;
    border-top: 1px solid #eeeeee;
    padding: 0 40px 0 20px;
    width: 100%;
}

.mobile-menu .mean-bar .mean-nav>ul li ul {
    position: static;
    background-color: rgba(0, 0, 0, 0.03);
    margin: 0;
    padding: 0 !important;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    display: none;
    float: left;
    width: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 1;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.mobile-menu .mean-bar .mean-nav>ul li ul.megamenu {
    width: 100%;
    left: 0;
    margin-left: 0;
}

.mobile-menu .mean-bar .mean-nav>ul li ul.megamenu li {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 0;
}

.mobile-menu .mean-bar .mean-nav>ul li ul.megamenu li a {
    font-size: 14px;
    margin-bottom: 0;
    padding: 0 40px 0 20px;
    opacity: 1;
}

.mobile-menu .mean-bar .mean-nav ul li a.mean-expand {
    position: absolute;
    right: -1px;
    top: 0;
    font-size: 20px !important;
    color: #444444;
    line-height: 44px;
    height: 45px;
    width: 40px;
    text-align: center;
    padding: 0 !important;
}

.mobile-menu .mean-bar .mean-nav ul li a.mean-expand:hover {
    background: rgba(0, 0, 0, 0);
}

.mobile-menu--3 .mean-bar {
    margin-top: 0;
    margin-bottom: 3rem;
}

.menu-item-has-children {
    &:hover {
        &:after {
            color: $primary-color !important;
        }
    }
}

.mainmenu li>a {
    @media (min-width: 992px) {
        &:hover {
            color: $primary-color;
        }
    }
} //   sticky Header
.sticky-init {
    display: none;
    background: rgba(255, 255, 255, 0.95);
}

.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    margin: 0;
    -webkit-animation: fadeInDown 0.8s ease-in-out;
    -moz-animation: fadeInDown 0.8s ease-in-out;
    -ms-animation: fadeInDown 0.8s ease-in-out;
    -o-animation: fadeInDown 0.8s ease-in-out;
    animation: fadeInDown 0.8s ease-in-out;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
}

@media (max-width: 991.04) {
    .sticky-header {
        padding: 1rem 0;
    }
}

.sticky-header.fixed-header {
    display: block;
}

.sticky-header .sticky-logo {
    display: block;
    padding-top: 7px;
    padding-bottom: 7px;
}

.sticky-header .mainmenu {
    text-align: right;
    >.mainmenu__item {
        >a {
            padding: 29px 25px;
        }
    }
}

@media (min-width: 62em) {
    .sticky-header .mainmenu li.menu-item-has-children>ul.megamenu {
        left: auto !important;
        margin-left: 0 !important;
        right: 0;
    }
}

@media (min-width: 62em) {
    .sticky-header .mainmenu li:hover ul {
        top: 100%;
    }
}

.sticky-header .mainmenu__item>a {
    // padding: 2.2rem 1.8rem; 
}

@media (max-width: 991.04) {
    .sticky-header .sticky-navigation {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 100% !important;
        -moz-box-flex: 1;
        -moz-flex: 1 0 100% !important;
        -ms-flex: 1 0 100% !important;
        flex: 1 0 100% !important;
    }
}

@media (max-width: 991.04) {
    .sticky-header .sticky-navigation .sticky-menu.mainmenu {
        max-height: 40rem;
        text-align: left;
        overflow-y: auto;
        padding-top: 2rem;
        display: none;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item {
        display: block;
        position: relative;
        background: #fff;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item a {
        padding: 1.2rem 1.8rem;
        display: block;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item:first-child>a {
        padding-left: 1.8rem;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul {
        position: relative;
        visibility: visible;
        opacity: 1;
        left: 0;
        top: 0 !important;
        width: 100%;
        background: #f2f2f2;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.hidden-sub {
        display: none;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.megamenu {
        padding: 0;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.megamenu li {
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -moz-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        padding-right: 0;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.megamenu li .megamenu__heading {
        padding: 1.2rem 1.8rem;
        margin-bottom: 0;
        position: relative;
        font-size: 1.4rem;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul.megamenu li .megamenu__heading:after {
        position: absolute;
        content: "\f107";
        font-family: fontAwesome;
        right: 20px;
        top: 13px;
        color: #40576c;
        display: block;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul li:first-child {
        border-top: 1px solid #e5e5e5;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul li:not(:last-child) {
        border-bottom: 1px solid #e5e5e5;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul li a {
        padding-left: 2.5rem;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item ul li ul li a {
        padding-left: 3rem;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .mainmenu__item a {
        color: #40576c;
    }
    .sticky-header .sticky-navigation .sticky-menu.mainmenu .menu-item-has-children>a:after {
        position: absolute;
        content: "\f107";
        font-family: fontAwesome;
        right: 20px;
        top: 12px;
        color: #40576c;
        display: block;
    }
}

.sticky-header .sticky-navigation .sticky-mobile-menu {
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    right: 0;
    top: -4rem;
    cursor: pointer;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn {
    height: 2px;
    width: 100%;
    background: #fff;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:before,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:after {
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:before {
    top: -8px;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:after {
    top: 8px;
}

.sticky-header .sticky-navigation .sticky-mobile-menu.sticky-close .sticky-menu-btn {
    background-color: transparent;
}

.sticky-header .sticky-navigation .sticky-mobile-menu.sticky-close .sticky-menu-btn:before {
    top: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sticky-header .sticky-navigation .sticky-mobile-menu.sticky-close .sticky-menu-btn:after {
    top: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sticky-header .sticky-navigation .sticky-mobile-menu {
    content: "";
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:before,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:after {
    position: absolute;
    content: ""
}

.sticky-header .sticky-navigation,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn {
    position: relative;
}

.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:before,
.sticky-header .sticky-navigation .sticky-mobile-menu .sticky-menu-btn:after {
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.mainmenu li.menu-item-has-children:after {
    opacity: 0;
    @include desktops {
        opacity: 1;
        transition: .4s;
    }
}

.mainmenu li:hover {
    &:after {
        @include desktops {
            color: $primary-color;
        }
    }
    >a {
        @include desktops {
            color: $primary-color;
        }
    }
}

/*Header 2*/

.petmark-header-2 {
    .sticky-header {
        background: rgba(0, 177, 191, 0.95);
    }
    .mobile-absolute-menu {
        position: relative;
        top: 0;
        margin-top: 0;
        z-index: 302;
        @include mobile-lg {
            position: absolute;
            margin-top: 7px;
        }
        @include tablet {
            margin-top: 17px;
        }
    }
    .mobile-menu {
        padding: 0;
        @include tablet {
            padding: 8px 0;
        }
        .mean-bar {
            .meanmenu-reveal {
                border-color: #fff;
                left: auto !important;
                right: 0 !important;
                span {
                    color: #fff;
                    &:after,
                    &:before {
                        background: #fff;
                    }
                    &.menu-bar {
                        background-color: #fff;
                    }
                }
            }
            .mean-nav {
                margin-top: 64px;
            }
        }
        .mean-bar .mean-nav>ul {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
            @include tablet {
                // width: calc(100% - 30px);
                // margin-left: 15px;
                // margin-right: 15px;
            }
        }
    }
    .slide-down-wrapper {
        z-index: 201;
        @include desktops {
            z-index: unset;
        }
    }
}

/*Header 3*/

.petmark-header-3 {
    .mainmenu>li>a {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .mobile-absolute-menu {
        margin-top: 0;
    }
    .mobile-menu .mean-bar .mean-nav {
        margin-top: 85px;
        @include mobile-lg {
            margin-top: 65px;
        }
    }
    .mobile-menu .mean-bar .meanmenu-reveal {
        left: auto !important;
        right: 0px !important; // margin-top: 16px;
        margin-top: 25px;
        @include mobile-lg {
            margin-top: 15px;
        }
    }
    .mobile-menu .mean-bar .mean-nav>ul {
        @include mobile {
            margin: 0;
            width: 100%;
        }
    }
    .mainmenu li.menu-item-has-children>ul.megamenu.three-column {
        @include desktops {
            margin-left: -28.7rem;
        }
    }
}

/* Header 4 */

.petmark-header-4 {
    .mobile-menu .mean-bar .mean-nav {
        margin-top: 58px;
        @include tablet {
            margin-top: 49px;
        }
    }
    .mainmenu li.menu-item-has-children>ul.megamenu.three-column {
        @include desktops {
            margin-left: -25rem;
        }
        ul {
            >li {
                a {
                    font-weight: 500;
                    @include desktops {
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .mainmenu>li>a {
        display: block;
        padding: 19px 25px;
    }
    .mobile-absolute-menu {
        margin-top: -9.5px;
        @include tablet {
            margin-top: 0;
        }
    }
    .mobile-menu .mean-bar .meanmenu-reveal {
        border: 1px solid #fff;
        left: 30px !important;
        @include tablet {
            right: 30px !important;
        }
        span {
            &.menu-bar {
                background: #fff;
                &:after,
                &:before {
                    background: #fff;
                }
            }
            &.menu-close {
                &:after,
                &:before {
                    background: #fff;
                }
            }
        }
    }
}