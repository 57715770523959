/* Typography */

.title-font {
    font-family: 'Prata', serif;
}

.block-title {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        width: 60px;
        height: 2px;
        bottom: 0;
        left: 0;
        background: $primary-color;
    }
    h2 {
        display: block;
        font-size: 16px;
        padding-bottom: 11px;
        position: relative;
        text-transform: uppercase;
        margin-top: -5px;
        @include tablet {
            font-size: 18px;
            padding-bottom: 15px;
        }
    }
    &-2 {
        border-bottom: 1px solid #cecece;
        margin-bottom: 20px;
        h2 {
            background: #00b1bf;
            color: #fff;
            display: inline-block;
            height: 50px;
            line-height: 50px;
            padding: 0 15px;
            font-size: 14px;
            padding-bottom: 15px;
            text-transform: uppercase;
            -webkit-border-radius: 3px 3px 0 0;
            -moz-border-radius: 3px 3px 0 0;
            border-radius: 3px 3px 0 0;
            @include mobile {
                padding: 0 28px;
                font-size: 16px;
            }
        }
    }
    &-3 {
        border-bottom: 2px solid $primary-color;
        h2 {
            background: #56a700;
            color: #fff;
            display: inline-block;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
            padding: 0 28px;
            padding-bottom: 15px;
            text-transform: uppercase;
            -webkit-border-radius: 3px 3px 0 0;
            -moz-border-radius: 3px 3px 0 0;
            border-radius: 3px 3px 0 0;
        }
    }
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.text-italic {
    font-style: italic;
}

.font-weight-mid {
    font-weight: 500;
}

.page-section-title {
    h1 {
        text-transform: uppercase;
        line-height: 1.35;
        margin: 0 0 5px;
        color: $black-color-2;
    }
}